import React, {useEffect, useRef, useCallback, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";
import {
    getFirmOptions, 
    getUserRolIdData, 
    setUpdateUserId, 
    updateUser
} from "../../actions/user";
import Button from "react-bootstrap/Button";
import {getParams} from "../../utils/hooks/getParams";
import {useFormErrors} from "../../utils/hooks/useFormErrors";

const UserUpdateForm = () => {
    const dispatch = useDispatch();
    const {user} = useSelector(state => state);
    const {error, loading, update_user, user_rol_id_data, firm_options} = user || {};

    const defaultValues = {
        name: '',
        email: '',
        role_id: '',
        firm_id: '',
        password: ''
    };

    const formRef = useRef();
    const [inputs, setInputs] = useState(defaultValues);
    const [errors, handleError, resetForm] = useFormErrors(
        error,
        loading,
        () => dispatch({ type: 'CLEAR_ERRORS' })
    );

    // Load firm and role options when component mounts
    useEffect(() => {
        dispatch(getFirmOptions());
        dispatch(getUserRolIdData());
    }, [dispatch]);

    // Update form when update_user changes
    useEffect(() => {
        if (update_user) {
            setInputs({
                ...update_user,
                role_id: update_user.role?.id || '',
                firm_id: update_user.firm?.id || '',
                password: '' // Clear password field for security
            });
        } else {
            setInputs(defaultValues);
        }
    }, [update_user]);

    const handleChange = useCallback(e => {
        const {name, value} = e.target;
        setInputs(prev => ({ ...prev, [name]: value }));
    }, []);

    const closeModal = useCallback(() => {
        dispatch(setUpdateUserId(null));
        setInputs(defaultValues);
        resetForm();
    }, [dispatch, resetForm]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        // Create payload with only the required fields
        const formData = {
            firm_id: Number(inputs.firm_id),
            name: inputs.name,
            email: inputs.email,
            role_id: Number(inputs.role_id)
        };
        
        // Only include password if it was changed
        if (inputs.password) {
            formData.password = inputs.password;
        }

        try {
            await dispatch(updateUser(update_user.id, formData, getParams()));
            closeModal();
        } catch (err) {
            handleError(err.response?.data);
        }
    };

    return (
        <Modal show={!!update_user} onHide={closeModal}>
            <Modal.Header closeButton className="bg-light">
                <Modal.Title className="text-black">
                    {loading ? 'Yuklanmoqda...' : 'Foydalanuvchini tahrirlash'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-3 pb-4 mx-3">
                {loading ? (
                    <div className="text-center py-4">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Yuklanmoqda...</span>
                        </div>
                    </div>
                ) : (
                    <form onSubmit={handleSubmit} ref={formRef}>
                        {errors.general && (
                            <div className="alert alert-danger mb-3">
                                {errors.general}
                            </div>
                        )}
                        <div className="mb-3">
                            <label htmlFor="firm_id" className="form-label">Firma</label>
                            <select 
                                name="firm_id"
                                value={inputs.firm_id || ''}
                                id="firm_id"
                                className={`form-control ${errors.firm_id ? 'is-invalid' : ''}`}
                                onChange={handleChange}
                            >
                                <option value="">Tanlang</option>
                                {firm_options?.map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                            {errors.firm_id && (
                                <div className="invalid-feedback">{errors.firm_id}</div>
                            )}
                        </div>

                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">Ism</label>
                            <input
                                name="name"
                                id="name"
                                className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                type="text"
                                value={inputs.name || ''}
                                placeholder="Ismni kiriting"
                                onChange={handleChange}
                            />
                            {errors.name && (
                                <div className="invalid-feedback">{errors.name}</div>
                            )}
                        </div>

                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">Email</label>
                            <input
                                name="email"
                                id="email"
                                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                type="email"
                                value={inputs.email || ''}
                                placeholder="Email kiriting"
                                onChange={handleChange}
                            />
                            {errors.email && (
                                <div className="invalid-feedback">{errors.email}</div>
                            )}
                        </div>

                        <div className="mb-3">
                            <label htmlFor="password" className="form-label">Parol</label>
                            <input
                                name="password"
                                id="password"
                                className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                type="password"
                                value={inputs.password || ''}
                                placeholder="Yangi parol kiriting"
                                onChange={handleChange}
                            />
                            {errors.password && (
                                <div className="invalid-feedback">{errors.password}</div>
                            )}
                            <small className="form-text text-muted">
                                Bo'sh qoldirilsa, parol o'zgartirilmaydi
                            </small>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="role_id" className="form-label">Rol</label>
                            <select 
                                name="role_id"
                                id="role_id"
                                className={`form-control ${errors.role_id ? 'is-invalid' : ''}`}
                                value={inputs.role_id || ''}
                                onChange={handleChange}
                            >
                                <option value="">Tanlang</option>
                                {user_rol_id_data?.map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.title}
                                    </option>
                                ))}
                            </select>
                            {errors.role_id && (
                                <div className="invalid-feedback">{errors.role_id}</div>
                            )}
                        </div>

                        <div className="d-flex gap-2">
                            <Button 
                                className="w-50" 
                                variant="secondary" 
                                type="button" 
                                onClick={closeModal}
                            >
                                Bekor qilish
                            </Button>
                            <Button 
                                className="w-50" 
                                variant="primary" 
                                type="submit"
                                disabled={loading}
                            >
                                {loading ? 'Saqlanmoqda...' : 'Saqlash'}
                            </Button>
                        </div>
                    </form>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default UserUpdateForm;