import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getTopMaterialsChart } from '../../actions/dashboard';
import Chart from 'chart.js/auto';

const TopMaterialsChart = ({
    dashboard: { topMaterialsChart, loading },
    getTopMaterialsChart
}) => {
    const chartRef = React.useRef(null);
    const chartInstance = React.useRef(null);

    useEffect(() => {
        getTopMaterialsChart();
    }, [getTopMaterialsChart]);

    useEffect(() => {
        if (loading.topMaterialsChart || !topMaterialsChart) return;

        const ctx = chartRef.current.getContext('2d');

        // Destroy existing chart if it exists
        if (chartInstance.current) {
            chartInstance.current.destroy();
        }

        chartInstance.current = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: topMaterialsChart.materials.map(m => m.name),
                datasets: [{
                    label: 'Sotilgan materiallar',
                    data: topMaterialsChart.materials.map(m => m.quantity),
                    backgroundColor: '#dc3545',
                    borderRadius: 4
                }]
            },
            options: {
                indexAxis: 'y',
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: true,
                        position: 'top'
                    },
                    tooltip: {
                        callbacks: {
                            label: (context) => {
                                const material = topMaterialsChart.materials[context.dataIndex];
                                return `${material.quantity} ${material.unit} - ${material.total_price.toLocaleString('uz-UZ')} UZS`;
                            }
                        }
                    }
                },
                scales: {
                    x: {
                        beginAtZero: true,
                        ticks: {
                            callback: (value) => {
                                return value + ' dona';
                            }
                        }
                    }
                }
            }
        });

        // Cleanup
        return () => {
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }
        };
    }, [topMaterialsChart, loading.topMaterialsChart]);

    if (loading.topMaterialsChart) {
        return <div>Loading...</div>;
    }

    return (
        <div className="card flex-fill w-100">
            <div className="card-header">
                <h5 className="card-title mb-0">Top 5 Sotilgan Materiallar</h5>
            </div>
            <div className="card-body d-flex w-100">
                <div className="align-self-center chart chart-lg" style={{ width: '100%', height: '300px' }}>
                    <canvas ref={chartRef} />
                </div>
            </div>
        </div>
    );
};

TopMaterialsChart.propTypes = {
    dashboard: PropTypes.shape({
        topMaterialsChart: PropTypes.shape({
            materials: PropTypes.arrayOf(PropTypes.shape({
                name: PropTypes.string.isRequired,
                quantity: PropTypes.number.isRequired,
                unit: PropTypes.string.isRequired,
                total_price: PropTypes.number.isRequired
            })).isRequired
        }),
        loading: PropTypes.shape({
            topMaterialsChart: PropTypes.bool.isRequired
        }).isRequired
    }).isRequired,
    getTopMaterialsChart: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    dashboard: state.dashboard
});

export default connect(mapStateToProps, { getTopMaterialsChart })(TopMaterialsChart);
