import {
    ADD_MATERIAL_SALE,
    CLEAR_ERRORS, GET_CLIENT_OPTIONS, GET_MATERIAL_SALE_OPTIONS, GET_MATERIAL_SALES,
    GET_PRODUCT_CLIENT_AND_SELLER_ID_DATA,
    GET_SALE, GET_MATERIAL_SALE,
    REMOVE_DELETE_MATERIAL_SALE,
    REMOVE_UPDATE_SALE,
    SALE_ERROR, SET_DELETE_MATERIAL_SALE_ID,
    SET_LOADING, SET_UPDATE_MATERIAL_SALE_ID,
} from '../actions/types';

const initialState = {
    material_sales: [],
    material_sale: {},
    material_options: [],
    client_options: [],
    warehouse_options: [],
    client_ID_DATA: [],
    product_ID_DATA: [],
    seller_ID_DATA: [],
    special_client_materials: [],
    update_material_sale: null,
    delete_material_sale: null,
    loading: true,
    numberOfPages: 1,
    total_sales: null,
    error: null,
};

// eslint-disable-next-line

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {

        case SET_LOADING:
            return {
                ...state,
                loading: payload,
            };

        case GET_PRODUCT_CLIENT_AND_SELLER_ID_DATA:
            return {
                ...state,
                ...payload,
                loading: false,
            };

        case GET_SALE:
            return {
                ...state,
                sale: payload.data,
                error: null,
                loading: false,
            };

        case GET_MATERIAL_SALE:
            return {
                ...state,
                material_sale: payload.data,
                error: null,
                loading: false,
            };

        case GET_MATERIAL_SALE_OPTIONS:
            return {
                ...state,
                material_options: payload.data,
                error: null,
                loading: false,
            };

        case GET_CLIENT_OPTIONS:
            return {
                ...state,
                client_options: payload.data,
                error: null,
                loading: false,
            };

        case GET_MATERIAL_SALES:
            return {
                ...state,
                material_sales: payload.data,
                numberOfPages: payload.meta.last_page,
                currentPage: payload.meta.current_page,
                total_sales: payload.meta.total,
                error: null,
                loading: false,
            };

        case ADD_MATERIAL_SALE:
            return {
                ...state,
                error: null,
                loading: false,
            };

        case REMOVE_DELETE_MATERIAL_SALE:
            return {
                ...state,
                delete_material_sale: null,
            };

        case REMOVE_UPDATE_SALE:
            return {
                ...state,
                update_sale: null,
                error: null,
                loading: false,
            };

        case SET_DELETE_MATERIAL_SALE_ID:

            return {
                ...state,
                delete_material_sale: state.material_sales.find((item) => item.id === action.payload.saleID)
            };

        case SET_UPDATE_MATERIAL_SALE_ID:
            return {
                ...state,
                update_material_sale: action.payload
            };

        case SALE_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
                loading: false,
            };

        default:
            return state;
    }
}
