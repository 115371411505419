import React, {useEffect, useRef, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";

import Button from "react-bootstrap/Button";
import {
    setUpdateProductId,
    updateProduct,
    clearErrors,
    getUnitOptions,
    getMaterialOptions,
    getWarehouseOptions,
    getProduct
} from "../../actions/product";
import {Multiselect} from "multiselect-react-dropdown";
import Select from "react-select";
import {Accordion} from "react-bootstrap";
import {ArrowLeft, Save, X} from "react-feather";
import {getParams} from "../../utils/hooks/getParams";
import {Input} from "reactstrap";
import {isArray} from "chart.js/helpers";
import ButtonLoader from "../layout/ButtonLoader";

const ProductUpdateForm = () => {

    const dispatch = useDispatch();

    const {
        loading,
        error,
        products,
        update_product,
        warehouse_options,
        material_options,
        unit_options
    } = useSelector(state => state.product);
    const {
        firm_options
    } = useSelector(state => state.user);

    useEffect(() => {
        dispatch(getUnitOptions());
        dispatch(getWarehouseOptions());
    }, []);

    useEffect(() => {
        if (update_product?.id) {
            dispatch(getProduct(update_product.id));
            if (update_product.firm_id) {
                dispatch(getMaterialOptions({firm_id: update_product.firm_id}));
                dispatch(getWarehouseOptions({firm_id: update_product.firm_id}));
            }
        }
    }, [update_product]);

    const [selectedFirm, setSelectedFirm] = useState(null);
    const [selectedWarehouse, setSelectedWarehouse] = useState(null);
    const [selectedMaterialOptions, setSelectedMaterialOptions] = useState([]);
    const [materialOptions, setMaterialOptions] = useState([]);
    const [firmOptions, setFirmOptions] = useState([]);
    const [warehouseOptions, setWarehouseOptions] = useState([]);
    const [errors, setErrors] = useState({});
    const [inputs, setInputs] = useState({
        name: '',
        is_listed_in_report: true,
        is_listed_in_products_table: true,
        description: '',
        unit_id: '',
        warehouse_id: '',
        materials: []
    });

    useEffect(() => {
        if (firm_options?.length > 0) {
            const options = firm_options.map(item => ({
                value: item.id,
                label: item.name
            }));
            setFirmOptions(options);
        }
    }, [firm_options]);

    useEffect(() => {
        if (warehouse_options?.length > 0) {
            const options = warehouse_options.map(item => ({
                value: item.id,
                label: item.name
            }));
            setWarehouseOptions(options);
        }
    }, [warehouse_options]);

    useEffect(() => {
        if (update_product) {
            setErrors({});

            setInputs({
                name: update_product.name || '',
                is_listed_in_report: update_product.is_listed_in_report || true,
                is_listed_in_products_table: update_product.is_listed_in_products_table || true,
                description: update_product.description || '',
                unit_id: update_product.unit_id || '',
                warehouse_id: update_product.warehouse_id || '',
                firm_id: update_product.firm_id || '',
                materials: update_product.materials || []
            });

            // Set firm and warehouse
            if (update_product.firm) {
                setSelectedFirm({
                    value: update_product.firm.id,
                    label: update_product.firm.name
                });
            }
            
            if (update_product.warehouse) {
                setSelectedWarehouse({
                    value: update_product.warehouse.id,
                    label: update_product.warehouse.name
                });
            }

            // Handle materials
            if (update_product.materials && material_options.length > 0) {
                // Set selected materials in Tarkibi
                if (Array.isArray(update_product?.materials)) {
                    const uniqueMaterials = update_product.materials.filter(
                        (material, index, self) =>
                            index === self.findIndex(m => m.id === material.id) // Assume `id` is the unique identifier
                    );

                    setSelectedMaterialOptions(uniqueMaterials.map(material => ({
                        ...material,
                        unit_value: material.pivot?.unit_value || 0,
                        net_price: material.price || 0,
                    })));
                } else {
                    setSelectedMaterialOptions([]);
                }


                // Remove these materials from the multiselect options
                const selectedIds = update_product.materials.map(m => m.id);
                const availableMaterials = material_options.filter(item => !selectedIds.includes(item.id));
                setMaterialOptions(availableMaterials);
                setSelectedDynamicMaterialOptions([]); // Reset dynamic selection
            }
        }
    }, [update_product, material_options]);

    const [selectedDynamicMaterialOptions, setSelectedDynamicMaterialOptions] = useState([]);

    const formRef = useRef();

    const handleSelect = (selected, action) => {
        const {name} = action;
        if (name === 'firm_id') {
            setSelectedFirm(selected);
            setInputs(prev => ({...prev, firm_id: selected?.value || ''}));
            if (selected?.value) {
                dispatch(getMaterialOptions({firm_id: selected.value}));
                dispatch(getWarehouseOptions({firm_id: selected.value}));
            }
        } else if (name === 'warehouse_id') {
            setSelectedWarehouse(selected);
            setInputs(prev => ({...prev, warehouse_id: selected?.value || ''}));
        }
    };

    const handleChange = e => {
        setErrors({});
        e.target.oldValue = e.target.value;
        if (e.target.name === 'is_listed_in_report' || e.target.name === 'is_listed_in_products_table'){
            setInputs(prevState => ({...prevState, [e.target.name]: !inputs[e.target.name]}));
        }else{
            setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}));
        }
    };

    const onSelectMaterial = (selectedItems) => {
        setSelectedDynamicMaterialOptions(selectedItems);
    };

    const onRemoveMaterial = (selectedItems) => {
        setSelectedDynamicMaterialOptions(selectedItems);
    };

    const onClickMoveSelectedMaterials = () => {
        if (selectedDynamicMaterialOptions.length > 0) {
            // Add selected materials to Tarkibi
            setSelectedMaterialOptions(prev => [...prev, ...selectedDynamicMaterialOptions.map(item => ({
                ...item,
                unit_value: 0,
                net_price: item.price || 0
            }))]);

            // Remove selected materials from available options
            const selectedIds = selectedDynamicMaterialOptions.map(item => item.id);
            setMaterialOptions(prev => prev.filter(item => !selectedIds.includes(item.id)));
            setSelectedDynamicMaterialOptions([]); // Reset selection
        }
    };

    const onClose = () => {
        clearErrors();
        setInputs({
            name: '',
            is_listed_in_report: true,
            is_listed_in_products_table: true,
            description: '',
            unit_id: '',
            warehouse_id: '',
            materials: []
        });
        setErrors({});
        closeModal();
    };

    const onChangeInput = (e) => {
        let material_id = parseInt(e.target.dataset.id);
        const cleanItems = [];

        const items = selectedMaterialOptions.map((item) => {
            if (item.id === material_id) {
                item.unit_value = e.target.value;
            }
            cleanItems.push({
                id: item.id,
                unit_value: parseFloat(item.unit_value) || 0
            });
            return item;
        });
        setSelectedMaterialOptions(items);
        setErrors({});
        setInputs(prevState => ({...prevState, materials: cleanItems}));
    };

    const onClickRemoveFromSelectedMaterials = (id) => {
        const filteredSelectedMaterials = selectedMaterialOptions.filter(item => item.id !== Number(id));
        setSelectedMaterialOptions([...filteredSelectedMaterials]);
        let ids = filteredSelectedMaterials.map(({id}) => id);
        const filteredMaterials = materialOptions.filter((item) => !ids.includes(item.id));
        setMaterialOptions(filteredMaterials);
        setSelectedMaterialOptions([]);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const cleanItems = [];

        selectedMaterialOptions.map((item) => {
            cleanItems.push({
                id: item.material_id || item?.id,
                unit_value: parseFloat(item.unit_value)
            });
            return item;
        });
        const data = {
            name: inputs.name,
            is_listed_in_report: inputs.is_listed_in_report,
            is_listed_in_products_table: inputs.is_listed_in_products_table,
            warehouse_id: inputs.warehouse_id,
            unit_id: inputs.unit_id,
            description: inputs.description,
        };
        if (cleanItems.length > 0){
            data.materials = cleanItems;
        }
        const params = getParams(true)
        const is_updated = await dispatch(updateProduct(update_product.id, data, params));
        if (is_updated){
            onClose()
            setSelectedMaterialOptions([]);
        }
    };

    useEffect(() => {
        if (loading === false) {
            if (error) {
                const errorsItems = error?.errors;
                let errorObject = {};
                if (error?.errors) {
                    Object.keys(error?.errors).forEach((item) => {
                        if (item.includes('materials')) {
                            errorObject.unit_value = []
                            errorObject.unit_value[Number(item.replace(/[^0-9]/g, ''))] = errorsItems[item][0];
                        } else {
                            errorObject[item] = errorsItems[item][0];
                        }
                    });
                    setErrors(errorObject);
                }

            } else {
                setSelectedMaterialOptions([]);
                setSelectedMaterialOptions([]);
                setMaterialOptions([]);
                setMaterialOptions(material_options);
            }
        }
    }, [error, loading, products]);

    const closeModal = () => {
        dispatch(setUpdateProductId(null));
    };

    let condition = false;
    if (update_product !== null)
        condition = true;

    return (
        <div>
            <Modal  size={'lg'} show={condition} onHide={closeModal}>
                <Modal.Header closeButton className="bg-light">
                    <Modal.Title>Mahsulotni tahrirlash</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-3 pb-4 mx-3">


                    <form onSubmit={handleSubmit} ref={formRef}>

                        <div className="mb-3">
                            <label htmlFor="name">Mahsulot nomi</label>
                            <input
                                name="name"
                                className={'form-control'}
                                type="text"
                                value={inputs?.name || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                <span className="error">
                                    {errors?.name}
                                </span>
                            </div>
                        </div>


                        <div className="mb-3">
                            <label htmlFor="firm_id">Firma</label>
                            <Select
                                name={'firm_id'}
                                options={firmOptions}
                                value={selectedFirm}
                                onChange={handleSelect}
                                defaultValue={{label: 'Tanlang', value: ''}}
                            />
                            <div className="error">
                                {errors?.firm_id}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="firm_id">Sklad</label>
                            <Select
                                name={'warehouse_id'}
                                options={warehouseOptions}
                                value={selectedWarehouse}
                                onChange={handleSelect}
                                defaultValue={{label: 'Tanlang', value: ''}}
                            />
                            <div className="error">
                                {errors?.warehouse_id}
                            </div>
                        </div>


                        <div className="mb-3">
                            <div className="row materials__mover">
                                <div className="col-lg-5 col-md-12">
                                    <label className="form-label mb-2">Tarkibi</label>

                                    <div className="card p-2">
                                        <Accordion>
                                            {
                                                isArray(selectedMaterialOptions) && selectedMaterialOptions.length > 0 ?
                                                    selectedMaterialOptions?.map((item, index) => (
                                                        <Accordion.Item key={index} eventKey={item.id}>
                                                            <Accordion.Header>{item.name} ({item?.in_stock} {item?.unit?.symbol})</Accordion.Header>
                                                            <Accordion.Body>

                                                                <div
                                                                    className="input-group mb-1 justify-content-between">
                                                                    <label htmlFor="ID">Hozirgi tan narxi</label>
                                                                    <X className={'pointered-icon'}
                                                                       onClick={() => onClickRemoveFromSelectedMaterials(item.id)}
                                                                       color={'red'}
                                                                    />
                                                                </div>
                                                                <input
                                                                    className="form-control my-2"
                                                                    type="text"
                                                                    name="id"
                                                                    id="net_price"
                                                                    value={item?.net_price || 0}
                                                                    required
                                                                    disabled
                                                                />

                                                                <div className="input-group mb-3">
                                                                    <input
                                                                        className="form-control"
                                                                        placeholder="Miqdori"
                                                                        name='unit_value'
                                                                        type="number"
                                                                        step="0.000001"
                                                                        min="0"
                                                                        value={item.unit_value || ''}
                                                                        data-id={item.id}
                                                                        id={`unit_value${item.id}`}
                                                                        required
                                                                        onChange={(e) => {
                                                                            const value = e.target.value.replace(/[^0-9.]/g, '');
                                                                            // Ensure only one decimal point
                                                                            const parts = value.split('.');
                                                                            const sanitizedValue = parts.length > 2 
                                                                                ? `${parts[0]}.${parts.slice(1).join('')}`
                                                                                : value;
                                                                            onChangeInput({
                                                                                target: {
                                                                                    value: sanitizedValue,
                                                                                    dataset: {
                                                                                        id: item.id
                                                                                    }
                                                                                }
                                                                            });
                                                                        }}
                                                                    />
                                                                    <span className="input-group-text" id="unit_value">
                                                                        {item.unit_alternative?.symbol}
                                                                    </span>
                                                                </div>
                                                                <span className="error">
                                                                    {errors?.unit_value && errors?.unit_value[index] !== undefined ? errors?.unit_value[index] : ''}
                                                                </span>

                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    ))

                                                    :
                                                    <h6 className="text-secondary">
                                                        O'ng tomondan material tanlab qo'shing!
                                                    </h6>
                                            }
                                        </Accordion>
                                    </div>


                                </div>

                                <div className="col-md-12 justify-content-center col-lg-2 text-center">
                                    <br/>
                                    <br/>
                                    <button onClick={onClickMoveSelectedMaterials} type={'button'}
                                            className="btn-primary py-2 px-3">
                                        <ArrowLeft/>
                                    </button>
                                </div>

                                <div className="col-lg-5 col-md-12">

                                    <div className="mt-2">
                                        <label htmlFor="" className="mb-2">Materiallar</label>
                                        <Multiselect
                                            className="form-control mt-2"
                                            options={materialOptions}
                                            displayValue="name"
                                            onSelect={onSelectMaterial}
                                            onRemove={onRemoveMaterial}
                                            selectedValues={selectedDynamicMaterialOptions}
                                            placeholder="Materiallarni tanlang"
                                        />

                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Qisqa
                                ma'lumot </label>
                            <textarea className="form-control" value={inputs?.description}
                                      onChange={handleChange} name="description"
                                      id="description"
                                      rows="4"></textarea>
                            <span className="error">
                                {errors?.description}
                            </span>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="unit_id" className="form-label">
                                Mahsulot sarflash o'lchov birligi
                            </label>
                            <select className="form-control" value={inputs?.unit_id}
                                    onChange={handleChange} name="unit_id"
                                    id="unit_id">
                                <option defaultValue={''}>Tanlang</option>
                                {
                                    unit_options && unit_options.map((item, key) => (
                                        <option key={key}
                                                defaultValue={key === 0}
                                                value={item.id}>{item.name}</option>
                                    ))
                                }
                            </select>
                            <span className="error">{errors?.unit_id}</span>
                        </div>


                        <div className="mb-3 form-check">
                            <Input
                                type="checkbox"
                                id={'is_listed_in_report'}
                                value={inputs.is_listed_in_report}
                                checked={inputs.is_listed_in_report}
                                name={"is_listed_in_report"}
                                onChange={handleChange}
                            />
                            <label htmlFor="is_listed_in_report" className="form-label">
                                Otchet jadvalida ko'rsatilsin
                            </label>
                        </div>

                        <div className="mb-3 form-check">
                            <Input
                                type="checkbox"
                                id={'is_listed_in_products_table'}
                                value={inputs?.is_listed_in_products_table}
                                checked={inputs.is_listed_in_products_table}
                                name={"is_listed_in_products_table"}
                                onChange={handleChange}
                            />
                            <label htmlFor="is_listed_in_products_table" className="form-label pl-4 ">
                                Elektron Mahsulotlar jadvalida ko'rinsin
                            </label>
                        </div>


                        <div className="d-flex gap-2">
                            <Button className="w-50" variant="secondary" type="button" onClick={onClose}>Bekor
                                qilish</Button>
                            <Button className="w-50" variant="primary" type="submit">
                                <Save/> Saqlash
                                {loading && <ButtonLoader/>}
                            </Button>
                        </div>

                    </form>

                </Modal.Body>
            </Modal>
        </div>
    );
};

export default ProductUpdateForm;