import axios from 'axios';
import {clearAlerts, setAlert} from './alert';
import {
    ADD_SUPPLIER,
    CLEAR_ERRORS,
    GET_AVAILABLE_CLIENTS,
    GET_AVAILABLE_MANAGERS,
    GET_AVAILABLE_SUPPLIERS,
    GET_MATERIALS_WAREHOUSES_ID_DATA,
    GET_SUPPLIER, GET_SUPPLIER_MATERIAL_IMPORTS,
    GET_SUPPLIER_TRANSACTION_SUPPLIERS_DATA,
    GET_SUPPLIER_TRANSACTIONS,
    GET_SUPPLIERS,
    GET_UPDATE_SUPPLIER,
    REMOVE_DELETE_SUPPLIER,
    REMOVE_DELETE_SUPPLIER_MATERIAL,

    REMOVE_UPDATE_SUPPLIER,
    REMOVE_UPDATE_SUPPLIER_MATERIAL, SET_DELETE_MATERIAL_IMPORT_ID,
    SET_DELETE_SUPPLIER_ID,
    SET_DELETE_SUPPLIER_MATERIAL_ID,
    SET_LOADING,
    SET_UPDATE_SUPPLIER_MATERIAL_ID,
    SUPPLIER_ERROR,
    UNSET_LOADING,
} from './types';
import {request} from "../utils/service";

export const setLoading = () => (dispatch) => {
    dispatch({
        type: SET_LOADING,
        payload: true
    });
};
export const unsetLoading = () => (dispatch) => {
    dispatch({
        type: UNSET_LOADING,
        payload: false
    });
};
export const getSupplier = (supplierID) => async (dispatch) => {
    try {
        const res = await axios.get(`/suppliers/${supplierID}`);
        dispatch({
            type: GET_SUPPLIER,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getSuppliers = (page = 1, filters = {}) => async (dispatch) => {
    dispatch(setLoading());
    try {
        let queryParams = '';
        if (filters) {
            Object.keys(filters).map((key) => queryParams += `&${key}=${filters[key]}`);
        }
        const res = await axios.get(`/suppliers?page=${page}${queryParams}`);

        dispatch({
            type: GET_SUPPLIERS,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const addSupplier = (formData) => async (dispatch) => {
    dispatch(clearAlerts());
    dispatch(setLoading())
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.post('/suppliers/', formData, config);

        dispatch(getSuppliers());

        dispatch({
            type: ADD_SUPPLIER,
            payload: res.data,
        });

        dispatch(setAlert('Yetkazib beruvchi yaratildi !', 'success'));

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));

        if (err?.response?.data?.errors) {
            dispatch({
                type: SUPPLIER_ERROR,
                payload: err.response.data,
            });
        } else {
            dispatch(setAlert("Yetkazib beruvchi yaratilmadi !", 'danger'));
        }
    }
};

export const deleteSupplier = (deleteSupplierID, getPage) => async (dispatch) => {
    dispatch(clearAlerts());
    dispatch(setLoading())
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        await axios.delete(`/suppliers/${deleteSupplierID}`, config);

        dispatch({
            type: REMOVE_DELETE_SUPPLIER
        });

        dispatch(getSuppliers(getPage));

        dispatch(setAlert("Yetkazib beruvchi o'chirildi !", 'success'));

        dispatch(clearErrors());
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        dispatch({
            type: SUPPLIER_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const updateSupplier = (updateSupplierID, updateformData, filters = {}) => async (dispatch) => {
    dispatch(clearAlerts());
    dispatch(setLoading());
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        await axios.put(`/suppliers/${updateSupplierID}`, updateformData, config);

        dispatch({type: REMOVE_UPDATE_SUPPLIER});

        dispatch(getSuppliers(filters.page, filters));

        dispatch(setAlert('Yetkazib beruvchi tahrirlandi !', 'success'));
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        dispatch({
            type: SUPPLIER_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const setDeleteSupplierId = (supplierID) => async (dispatch) => {

    try {
        if (supplierID !== null) {
            dispatch({
                type: SET_DELETE_SUPPLIER_ID,
                payload: {
                    supplierID
                }
            });

        } else {
            dispatch({
                type: REMOVE_DELETE_SUPPLIER
            });
        }

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const setUpdateSupplierId = (supplierID) => async (dispatch) => {
    try {
        if (supplierID !== null) {
            const res = await axios.get(`/suppliers/${supplierID}`);
            dispatch({
                type: GET_UPDATE_SUPPLIER,
                payload: res.data,
            });
        } else dispatch({type: REMOVE_UPDATE_SUPPLIER});

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS,
    });
};


export const getAvailableManagers = () => async (dispatch) => {
    try {
        const res = await axios.get('/users/?is_available_manager=true');
        dispatch({
            type: GET_AVAILABLE_MANAGERS,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getAvailableClients = (current_client_id = null) => async (dispatch) => {
    try {
        let clientParam = ''
        if (current_client_id) {
            clientParam = `&client_id=${current_client_id}`
        }
        const res = await axios.get(`/clients/?is_available=true${clientParam}`);
        dispatch({
            type: GET_AVAILABLE_CLIENTS,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getAvailableSuppliers = (supplier_id = null) => async (dispatch) => {
    try {
        let supplierParam = '';
        if (supplier_id) {
            supplierParam = `&supplier_id=${supplier_id}`;
        }
        const res = await axios.get(`/suppliers/?is_available=true${supplierParam}`);
        dispatch({
            type: GET_AVAILABLE_SUPPLIERS,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getSupplierOptions = () => async (dispatch) => {
    try {
        const res = await axios.get('/suppliers?is_all=true');
        dispatch({
            type: GET_SUPPLIER_TRANSACTION_SUPPLIERS_DATA,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};
export const getSupplierTransactions = (supplier_id, filters = {}) => async (dispatch) => {
    dispatch(setLoading())
    try {
        let queryParams = '';
        if (filters) {
            Object.keys(filters).map((key) => queryParams += `&${key}=${filters[key]}`);
        }
        const res = await axios.get(`/supplier/${supplier_id}/transactions/?${queryParams}`);
        dispatch({
            type: GET_SUPPLIER_TRANSACTIONS,
            payload: res.data,
        });
        dispatch(unsetLoading())
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

// download report
export const downloadSingleSupplierTransactionReport = (id, filters = {}) => async (dispatch) => {
    dispatch(setLoading());
    dispatch(clearAlerts());
    let queryParams = `?`;
    if (filters) {
        Object.keys(filters).map((key, index) => index === 0 ? queryParams += `${key}=${filters[key]}` : queryParams += `&${key}=${filters[key]}`);
    }
    const res = await request(`/suppliers/${id}/generateTransactionsReport/${queryParams}`, {method: 'GET'}, dispatch);

    if (res?.hasOwnProperty('errors')) {
        const err = res.errors;
        dispatch(setAlert(err.response.statusText, 'danger'));
        dispatch(unsetLoading());
        return false;
    } else {
        dispatch(unsetLoading());
        return res?.link;
    }
};

// download report
export const downloadSingleSupplierMaterialsReport = (id, filters = {}) => async (dispatch) => {
    dispatch(setLoading());
    dispatch(clearAlerts());
    let queryParams = `?`;
    if (filters) {
        Object.keys(filters).map((key, index) => index === 0 ? queryParams += `${key}=${filters[key]}` : queryParams += `&${key}=${filters[key]}`);
    }
    const res = await request(`/suppliers/${id}/generateMaterialReport/${queryParams}`, {method: 'GET'}, dispatch);

    if (res?.hasOwnProperty('errors')) {
        const err = res.errors;
        dispatch(setAlert(err.response.statusText, 'danger'));
        dispatch(unsetLoading());
        return false;
    } else {
        dispatch(unsetLoading());
        return res?.link;
    }
};

export const getMaterialWarehouseIdData = () => async (dispatch) => {

    try {
        const res = await axios.get(`/materials?is_all=true`);
        const res2 = await axios.get(`/warehouses?is_all=true`);

        dispatch({
            type: GET_MATERIALS_WAREHOUSES_ID_DATA,
            payload: {
                material_ID_DATA: res.data.data,
                warehouse_ID_DATA: res2.data.data,
            },
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getSupplierMaterialImports = (supplier_ID, filters = {}) => async (dispatch) => {
    dispatch(setLoading());
    try {
        let queryParams = '';
        if (filters) {
            Object.keys(filters).map((key) => queryParams += `&${key}=${filters[key]}`);
        }
        const res = await axios.get(`/material-imports/?supplier_id=${supplier_ID}${queryParams}`);
        dispatch({
            type: GET_SUPPLIER_MATERIAL_IMPORTS,
            payload: res.data,
        });
        dispatch(unsetLoading());
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        dispatch({
            type: SUPPLIER_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const addForSupplierMaterial = (formData, supplier_ID, filters = {}) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        await axios.post(`/material-imports/`, formData, config);

        dispatch(setAlert('Material yaratildi !', 'success'));
        dispatch(getSupplierMaterialImports(supplier_ID, filters));

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));

        if (err?.response?.data?.errors) {
            dispatch({
                type: SUPPLIER_ERROR,
                payload: err.response.data,
            });
        } else {
            dispatch(setAlert("Material yaratilmadi !", 'danger'));
        }
    }
};

export const updateForSupplierMaterial = (update_supplier_material_ID, supplier_ID, updateformData) => async (dispatch) => {
    dispatch(clearAlerts());
    dispatch(setLoading())
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        await axios.patch(`/material-imports/${update_supplier_material_ID}`, updateformData, config);

        dispatch({type: REMOVE_UPDATE_SUPPLIER_MATERIAL});

        dispatch(getSupplierMaterialImports(supplier_ID));

        dispatch(setAlert('Material tahrirlandi !', 'success'));
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        if (err?.response?.data?.errors) {
            dispatch({
                type: SUPPLIER_ERROR,
                payload: err.response.data,
            });
        } else {
            dispatch(setAlert("Material tahrirlanmadi!", 'danger'));
        }
    }
};

// download report
// export const downloadSingleSupplierReport = (filters = {}) => async (dispatch) => {
//     filters.object = 'supplier';
//     dispatch(setLoading());
//     dispatch(clearAlerts());
//     let queryParams = `?`;
//     if (filters) {
//         Object.keys(filters).map((key, index) => index === 0 ? queryParams += `${key}=${filters[key]}` : queryParams += `&${key}=${filters[key]}`);
//     }
//     const res = await request(`/reports/${queryParams}`, {method: 'GET'}, dispatch);

//     if (res?.hasOwnProperty('errors')) {
//         const err = res.errors;
//         dispatch(setAlert(err.response.statusText, 'danger'));
//         dispatch(unsetLoading());
//         return false;
//     } else {
//         dispatch(unsetLoading());
//         return res?.link;
//     }
// };

export const setDeleteMaterialImport = (material_import_id) => async (dispatch) => {

    try {
        if (material_import_id !== null) {

            dispatch({
                type: SET_DELETE_MATERIAL_IMPORT_ID,
                payload: material_import_id
            })

        } else dispatch({type: SET_DELETE_MATERIAL_IMPORT_ID, payload: null});

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};


export const deleteForSupplierMaterial = (deleteMaterialID, supplier_ID) => async (dispatch) => {
    dispatch(setLoading())
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        await axios.delete(`/material-imports/${deleteMaterialID}`, config);

        dispatch({
            type: REMOVE_DELETE_SUPPLIER_MATERIAL
        });
        dispatch(setAlert("Material o'chirildi!", 'success'));

        dispatch(getSupplierMaterialImports(supplier_ID));

        dispatch(clearErrors())

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        if (err?.response?.data?.errors) {
            dispatch({
                type: SUPPLIER_ERROR,
                payload: err.response.data,
            });
        } else {
            dispatch(setAlert("Material o'chirilmadi!", 'danger'));
        }
    }
};

export const setUpdateSupplierMaterialId = (supplier_material_ID, materialItems) => async (dispatch) => {

    try {
        if (supplier_material_ID !== null) {

            let update_supplier_material = materialItems.find((item) => item.id === supplier_material_ID);

            dispatch({
                type: SET_UPDATE_SUPPLIER_MATERIAL_ID,
                payload: update_supplier_material
            });
        } else dispatch({type: REMOVE_UPDATE_SUPPLIER_MATERIAL});

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const setDeleteSupplierMaterialId = (supplier_material_ID, materialItems) => async (dispatch) => {

    try {
        if (supplier_material_ID !== null) {

            let delete_supplier_material = materialItems.find((item) => item.id === supplier_material_ID);

            dispatch({
                type: SET_DELETE_SUPPLIER_MATERIAL_ID,
                payload: delete_supplier_material
            });

        } else dispatch({type: SET_DELETE_SUPPLIER_MATERIAL_ID, payload: null});

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};


export const deleteMaterialImport = (material_import_id, filters = {}) => async (dispatch) => {

    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        await axios.delete(`/material-imports/${material_import_id}`, config);

        dispatch({
            type: SET_DELETE_MATERIAL_IMPORT_ID,
            payload: null
        });

        dispatch(setAlert("Material import o'chirildi!", 'success'));

        dispatch(getSupplierMaterialImports(filters.supplier_id, filters));

        dispatch(clearErrors())

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        if (err?.response?.data?.errors) {
            dispatch({
                type: SUPPLIER_ERROR,
                payload: err.response.data,
            });
        } else {
            dispatch(setAlert("Yetkazib berish o'chirilmadi!", 'danger'));
        }
    }
};


