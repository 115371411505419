import React, { useState, useEffect } from 'react';
import {
    Table,
    Space,
    Button,
    Modal,
    message,
    Popconfirm
} from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import RoleForm from './RoleForm';

const RolesList = () => {
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [editingRole, setEditingRole] = useState(null);

    const fetchRoles = async () => {
        setLoading(true);
        try {
            const response = await axios.get('/roles');
            setRoles(response.data.data || []);
        } catch (error) {
            message.error('Failed to fetch roles');
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchRoles();
    }, []);

    const handleDelete = async (roleId) => {
        try {
            await axios.delete(`/roles/${roleId}`);
            message.success('Role deleted successfully');
            fetchRoles();
        } catch (error) {
            message.error('Failed to delete role');
        }
    };

    const handleEdit = (role) => {
        setEditingRole(role);
        setModalVisible(true);
    };

    const handleCreate = () => {
        setEditingRole(null);
        setModalVisible(true);
    };

    const handleModalClose = () => {
        setModalVisible(false);
        setEditingRole(null);
    };

    const handleSave = async (values) => {
        try {
            if (editingRole) {
                await axios.put(`/roles/${editingRole.id}`, values);
                message.success('Role updated successfully');
            } else {
                await axios.post('/roles', values);
                message.success('Role created successfully');
            }
            handleModalClose();
            fetchRoles();
        } catch (error) {
            if (error.response?.data?.errors) {
                const errors = error.response.data.errors;
                Object.keys(errors).forEach(key => {
                    message.error(errors[key][0]);
                });
            } else {
                message.error('Failed to save role');
            }
        }
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Guard Name',
            dataIndex: 'guard_name',
            key: 'guard_name',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <Space>
                    <Button
                        type="primary"
                        icon={<EditOutlined />}
                        onClick={() => handleEdit(record)}
                    >
                        Edit
                    </Button>
                    <Popconfirm
                        title="Are you sure you want to delete this role?"
                        onConfirm={() => handleDelete(record.id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="primary" danger icon={<DeleteOutlined />}>
                            Delete
                        </Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <div>
            <div style={{ marginTop: 120 }}>
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={handleCreate}
                >
                    Create New Role
                </Button>
            </div>
            <Table
                columns={columns}
                dataSource={roles}
                rowKey="id"
                loading={loading}
            />
            <Modal
                title={editingRole ? 'Edit Role' : 'Create Role'}
                open={modalVisible}
                onCancel={handleModalClose}
                footer={null}
                width={1200}
            >
                <RoleForm
                    initialValues={editingRole}
                    onSave={handleSave}
                    onCancel={handleModalClose}
                />
            </Modal>
        </div>
    );
};

export default RolesList;
