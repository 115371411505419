import axios from 'axios';
import {clearAlerts, setAlert} from './alert';
import setAuthToken from '../utils/setAuthToken';
import {
    ADD_USER,
    CLEAR_ERRORS,
    GET_FILTERED_USERS,
    GET_STATISTICS,
    GET_USER,
    GET_USER_ACTIVITIES,
    GET_USER_ROL_ID_DATA,
    GET_USERS,
    REMOVE_DELETE_USER,
    REMOVE_UPDATE_USER,
    SET_DELETE_USER_ID,
    SET_SEARCH_QUERY,
    SET_UPDATE_USER_ID,
    USER_ERROR,
    USER_LOADING,
    GET_FIRM_ID_DATA,
    GET_INCOME_AND_EXPENSES
} from './types';

// Helper function to handle API errors
const handleApiError = (err, dispatch) => {
    const errorResponse = err.response?.data;
    
    dispatch({
        type: USER_ERROR,
        payload: errorResponse || { message: 'An error occurred' }
    });
};

export const getUsers = (page = 1, filters = {}) => async (dispatch) => {
    try {
        // Build query params
        const params = new URLSearchParams();
        params.append('page', page);
        
        if (filters && typeof filters === 'object') {
            Object.entries(filters).forEach(([key, value]) => {
                if (value !== null && value !== undefined) {
                    params.append(key, value);
                }
            });
        }

        const res = await axios.get(`/users?${params.toString()}`);

        if (!res.data || typeof res.data !== 'object') {
            throw new Error('Invalid response format from server');
        }

        dispatch({
            type: GET_USERS,
            payload: {
                data: Array.isArray(res.data.data) ? res.data.data : [],
                meta: {
                    current_page: res.data.meta?.current_page || 1,
                    last_page: res.data.meta?.last_page || 1,
                    total: res.data.meta?.total || 0
                }
            }
        });
    } catch (err) {
        handleApiError(err, dispatch);
    }
};

export const getFilteredUsers = (page = 1, filters = []) => async (dispatch) => {
    try {
        const params = new URLSearchParams();
        params.append('page', page);
        
        if (Array.isArray(filters)) {
            filters.forEach(({name, value}) => {
                if (value !== null && value !== undefined) {
                    params.append(name, value);
                }
            });
        }

        const res = await axios.get(`/users?${params.toString()}`);

        if (!res.data || typeof res.data !== 'object') {
            throw new Error('Invalid response format from server');
        }

        dispatch({
            type: GET_FILTERED_USERS,
            payload: {
                data: Array.isArray(res.data.data) ? res.data.data : [],
                meta: res.data.meta || {}
            }
        });
    } catch (err) {
        handleApiError(err, dispatch);
    }
};

export const getUserRolIdData = () => async (dispatch) => {
    try {
        const res = await axios.get('/auth/user/roles');
        
        if (!res.data || typeof res.data !== 'object') {
            throw new Error('Invalid response format from server');
        }

        dispatch({
            type: GET_USER_ROL_ID_DATA,
            payload: res.data
        });
    } catch (err) {
        handleApiError(err, dispatch);
    }
};

export const getUser = (userId) => async (dispatch) => {
    try {
        const res = await axios.get(`/users/${userId}`);
        
        if (res.data) {
            dispatch({
                type: GET_USER,
                payload: res.data
            });
        }
    } catch (err) {
        handleApiError(err, dispatch);
    }
};

export const getUserActivities = (page = 1, filters = {}) => async (dispatch) => {
    try {
        const params = new URLSearchParams();
        params.append('page', page);
        
        if (filters && typeof filters === 'object') {
            Object.entries(filters).forEach(([key, value]) => {
                if (value !== null && value !== undefined) {
                    params.append(key, value);
                }
            });
        }

        const res = await axios.get(`/user-activities?${params.toString()}`);

        if (!res.data || typeof res.data !== 'object') {
            throw new Error('Invalid response format from server');
        }

        dispatch({
            type: GET_USER_ACTIVITIES,
            payload: {
                data: Array.isArray(res.data.data) ? res.data.data : [],
                meta: res.data.meta || {}
            }
        });
    } catch (err) {
        handleApiError(err, dispatch);
    }
};

export const addUser = (formData, filters = {}) => async (dispatch) => {
    try {
        const res = await axios.post('/users', formData);

        if (!res.data || typeof res.data !== 'object') {
            throw new Error('Invalid response format from server');
        }

        dispatch({
            type: ADD_USER,
            payload: res.data
        });

        dispatch(setAlert('User added successfully', 'success'));
        dispatch(getUsers(1, filters));
    } catch (err) {
        handleApiError(err, dispatch);
    }
};

export const clearErrors = () => ({
    type: CLEAR_ERRORS
});

export const setDeleteUserId = (userID) => ({
    type: SET_DELETE_USER_ID,
    payload: userID
});

export const setUpdateUserId = (userId) => async (dispatch) => {
    try {
        if (userId) {
            dispatch({ type: USER_LOADING });
            // First fetch the user data
            const res = await axios.get(`/users/${userId}`);
            
            // Then set both the update ID and the user data
            dispatch({
                type: SET_UPDATE_USER_ID,
                payload: res.data
            });
        } else {
            // If no userId, just clear the update_user
            dispatch({
                type: SET_UPDATE_USER_ID,
                payload: null
            });
        }
    } catch (err) {
        handleApiError(err, dispatch);
    }
};

export const removeDeleteUser = () => ({
    type: REMOVE_DELETE_USER
});

export const removeUpdateUser = () => ({
    type: REMOVE_UPDATE_USER
});

export const getFirmOptions = () => async (dispatch) => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }
    try {
        const res = await axios.get(`/firms?is_all=true`);
        dispatch({
            type: GET_FIRM_ID_DATA,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getStatisticsData = (params = {}) => async (dispatch) => {
    try {
        const queryParams = new URLSearchParams();
        if (params.firm_id) {
            queryParams.append('firm_id', params.firm_id);
        }
        if (params.month) {
            queryParams.append('month', params.month);
        }
        if (params.year) {
            queryParams.append('year', params.year);
        }

        const res = await axios.get(`/statistics/numbers?${queryParams.toString()}`);

        dispatch({
            type: GET_STATISTICS,
            payload: res.data
        });

    } catch (err) {
        handleApiError(err, dispatch);
    }
};

export const getIncomeAndExpenses = (params = {}) => async (dispatch) => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }
    let queryParams = '?';
    if (Object.keys(params).length > 0) {
        Object.keys(params).map(item => queryParams += `${item}=${params[item]}&`);
    }
    try {
        const res = await axios.get(`/statistics/incomes-and-expenses${queryParams}`);
        dispatch({
            type: GET_INCOME_AND_EXPENSES,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const deleteUser = (deleteUserID, getPage) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.delete(`/users/${deleteUserID}`, config);

        dispatch({
            type: REMOVE_DELETE_USER
        });

        dispatch(getUsers(getPage));

        dispatch(setAlert("User deleted successfully", 'success'));
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const updateUser = (userId, formData, params = {}) => async dispatch => {
    try {
        dispatch({ type: USER_LOADING });
        const res = await axios.put(`/users/${userId}`, formData);

        if (res.data) {
            dispatch({
                type: SET_UPDATE_USER_ID,
                payload: null
            });
            
            // Refresh the users list
            dispatch(getUsers(params));
            return res.data;
        }
    } catch (err) {
        handleApiError(err, dispatch);
        throw err.response?.data || err;
    }
};

export const setSearchText = (searchQuery = '') => async (dispatch) => {

    try {
        dispatch({
            type: SET_SEARCH_QUERY,
            payload: searchQuery
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};
