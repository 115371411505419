import React, { useState, useEffect } from 'react';
import {
    Table,
    Space,
    Button,
    Modal,
    message,
    Popconfirm,
    Tag
} from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import PageForm from './PageForm';

const PagesList = () => {
    const [pages, setPages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [editingPage, setEditingPage] = useState(null);

    const fetchPages = async () => {
        setLoading(true);
        try {
            const response = await axios.get('/pages');
            setPages(response.data.data || []);
        } catch (error) {
            message.error('Failed to fetch pages');
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchPages();
    }, []);

    const handleDelete = async (pageId) => {
        try {
            await axios.delete(`/pages/${pageId}`);
            message.success('Page deleted successfully');
            fetchPages();
        } catch (error) {
            message.error('Failed to delete page');
        }
    };

    const handleEdit = (page) => {
        setEditingPage(page);
        setModalVisible(true);
    };

    const handleCreate = () => {
        setEditingPage(null);
        setModalVisible(true);
    };

    const handleModalClose = () => {
        setModalVisible(false);
        setEditingPage(null);
    };

    const handleSave = async (values) => {
        try {
            if (editingPage) {
                await axios.put(`/pages/${editingPage.id}`, values);
                message.success('Page updated successfully');
            } else {
                await axios.post('/pages', values);
                message.success('Page created successfully');
            }
            handleModalClose();
            fetchPages();
        } catch (error) {
            if (error.response?.data?.errors) {
                const errors = error.response.data.errors;
                Object.keys(errors).forEach(key => {
                    message.error(errors[key][0]);
                });
            } else {
                message.error('Failed to save page');
            }
        }
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: 'Display Name',
            dataIndex: 'display_name',
            key: 'display_name',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            ellipsis: true,
        },
        {
            title: 'Permissions',
            dataIndex: 'permissions',
            key: 'permissions',
            render: (permissions) => (
                <Space size={[0, 4]} wrap>
                    {permissions.map(permission => (
                        <Tag color="blue" key={permission.id}>
                            {permission.name}
                        </Tag>
                    ))}
                </Space>
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            width: 200,
            render: (_, record) => (
                <Space>
                    <Button
                        type="primary"
                        icon={<EditOutlined />}
                        onClick={() => handleEdit(record)}
                    >
                        Edit
                    </Button>
                    <Popconfirm
                        title="Are you sure you want to delete this page?"
                        onConfirm={() => handleDelete(record.id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="primary" danger icon={<DeleteOutlined />}>
                            Delete
                        </Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <div>
            <div style={{ marginBottom: 16 }}>
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={handleCreate}
                >
                    Create New Page
                </Button>
            </div>
            <Table
                columns={columns}
                dataSource={pages}
                rowKey="id"
                loading={loading}
                pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    showTotal: (total) => `Total ${total} items`,
                }}
            />
            <Modal
                title={editingPage ? 'Edit Page' : 'Create Page'}
                open={modalVisible}
                onCancel={handleModalClose}
                footer={null}
                width={600}
            >
                <PageForm
                    initialValues={editingPage}
                    onSave={handleSave}
                    onCancel={handleModalClose}
                />
            </Modal>
        </div>
    );
};

export default PagesList;
