import {
    ADD_MATERIAL,
    GET_MATERIAL,
    GET_MATERIAL_UNITS_DATA,
    GET_MATERIALS, GET_MATERIALS_OPTIONS, GET_SUPPLIER_OPTIONS, GET_WAREHOUSE_OPTIONS,
    MATERIAL_ERROR,
    MATERIAL_UNITS_DATA_ERROR,
    REMOVE_DELETE_MATERIAL,
    REMOVE_UPDATE_MATERIAL,
    SET_DELETE_MATERIAL_ID,
    SET_UPDATE_MATERILA_ID,
    GET_MATERIAL_TRANSACTIONS,
    CLEAR_ERRORS, CLEAR_MATERIAL_TRANSACTIONS,
    GET_MATERIAL_IMPORTS, GET_MATERIAL_SPENDS,
    SET_DELETE_MATERIAL_IMPORT_ID_ON_MATERIAL_PAGE,
    SET_LOADING
} from '../actions/types';

const initialState = {
    materials: [],
    material_imports: [],
    material_spends: [],
    suppliers: [],
    material: {},
    material_units: [],
    supplier_options: [],
    material_options: [],
    material_warehouses_options: [],
    material_unit_alternatives: [],
    update_material: null,
    delete_material: null,
    delete_material_import: null,
    loading: true,
    numberOfPages: 1,
    total_materials: null,
    error: null,
};

// eslint-disable-next-line

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {

        case SET_LOADING:

            return {
                ...state,
                loading: payload
            };
        case GET_MATERIAL_UNITS_DATA:

            return {
                ...state,
                ...payload,
                loading: false
            };

        case MATERIAL_UNITS_DATA_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };

        case GET_MATERIAL:
            return {
                ...state,
                material: payload.data,
                loading: false,
            };

        case GET_MATERIALS:
            return {
                ...state,
                materials: payload.data,
                numberOfPages: payload.last_page,
                currentPage: payload.current_page,
                total_materials: payload.total,
                error: null,
                loading: false,
            };
        case GET_MATERIAL_IMPORTS:
            return {
                ...state,
                material_imports: payload,
                error: null,
                loading: false,
            };
        case SET_DELETE_MATERIAL_IMPORT_ID_ON_MATERIAL_PAGE:
            let material_import;

            if ('data' in state.material_imports){
                material_import = state.material_imports.data.find(item => item.id === payload);
            }else{
                material_import = state.material_imports.find(item => item.id === payload);
            }
            if (!payload) {
                material_import = null;
            }
            return {
                ...state,
                delete_material_import: material_import,
                error: null,
                loading: false,
            };
        case GET_MATERIAL_SPENDS:

            return {
                ...state,
                material_spends: payload,
                error: null,
                loading: false,
            };
        case GET_MATERIALS_OPTIONS:

            return {
                ...state,
                material_options: payload.data,
                loading: false,
            };

        case CLEAR_MATERIAL_TRANSACTIONS:

            return {
                ...state,
                material_options: [],
                loading: false,
            };

        case GET_MATERIAL_TRANSACTIONS:
            const {dates, suppliers} = payload;

            return {
                ...state,
                dates: dates,
                suppliers: [...suppliers.data],
                numberOfPages: suppliers.last_page,
                current_page: suppliers.current_page,
                suppliers_data: payload,
                error: null,
                loading: false,
            };

        case GET_WAREHOUSE_OPTIONS:
            return {
                ...state,
                material_warehouses_options: payload.data,
                loading: false,
            };

        case GET_SUPPLIER_OPTIONS:
            return {
                ...state,
                supplier_options: payload.data,
                loading: false,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };

        case ADD_MATERIAL:
            return {
                ...state,
                materials: [payload],
                error: null,
                loading: false,
            };

        case REMOVE_DELETE_MATERIAL:
            return {
                ...state,
                delete_material: null,
            };

        case REMOVE_UPDATE_MATERIAL:
            return {
                ...state,
                update_material: null,
            };

        case SET_DELETE_MATERIAL_ID:

            return {
                ...state,
                delete_material: state.materials.find((item) => item.id === action.payload.materialID)
            };

        case SET_UPDATE_MATERILA_ID:
            return {
                ...state,
                update_material: state.materials.find((item) => item.id === action.payload.materialID)
            };

        case MATERIAL_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };

        default:
            return state;
    }
}


