import React, {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import MoneyFormat from "../../layout/MoneyFormat";
import {getForProductSales} from "../../../actions/product";
import CustomPagePagination from "../../layout/CustomPagePagination";

const ProductSales = () => {


    const {
        product,
        product_sales
    } = useSelector(state => state.product);

    const {data, meta } = product_sales;

    const dispatch = useDispatch();

    const {id} = product;
    useEffect(() => {
        if (id) {
            dispatch(getForProductSales(id, {}));
        }
    }, [getForProductSales, id]);


    const [from_date, setFromDate] = useState('');
    const [to_date, setToDate] = useState('');

    const onChangeFromDate = (event) => {
        setFromDate(state => event.target.value);
        const url = new URL(window.location);
        url.searchParams.set('from_date', event.target.value);
        url.searchParams.set('to_date', to_date);
        window.history.pushState({}, '', url);
        const params = {};
        params.to_date = to_date;
        params.from_date = event.target.value;
        dispatch(getForProductSales(id, params))
    };

    const onChangeToDate = (event) => {
        setToDate(state => event.target.value);
        const url = new URL(window.location);
        url.searchParams.set('to_date', event.target.value);
        url.searchParams.set('from_date', from_date);
        window.history.pushState({}, '', url);
        const params = {};
        params.to_date = event.target.value;
        params.from_date = from_date;
        dispatch(getForProductSales(id, params))
    };



    return (
        <>

            <div className="w-100 table-responsive px-2">

                <div className="row mb-3">
                    <div className="col-md-6"></div>
                    <div className="col-md-6">

                    </div>
                </div>

                <div className="mb-2 align-items-center d-flex
                                                        justify-content-between">
                    <div className="mb-3">
                        <label htmlFor="date1"
                               className="mb-2">dan</label>
                        <input onChange={onChangeFromDate}
                               className="form-control"
                               id="date1" type="date"/>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="date2"
                               className="mb-2">gacha</label>
                        <input onChange={onChangeToDate}
                               className="form-control"
                               id="date2" type="date"/>
                    </div>
                </div>

                <table className="table custom-table table-hover">
                    <thead className="table-dark">
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Sotuv ID</th>
                        <th scope="col">Sotuvchi</th>
                        <th scope="col">Mijoz</th>
                        <th scope="col">Miqdori</th>
                        <th scope="col">Narxi(so'm)</th>
                        <th scope="col">Summa(so'm)</th>
                        <th scope="col">Sana</th>
                        {/*<th scope="col">Tahrirlash</th>*/}
                    </tr>
                    </thead>
                    <tbody>
                    {
                        data?.map((item, index) => (
                            <tr key={index}>
                                <th>
                                    {index+1}
                                </th>
                                <th>
                                    <Link
                                        to={`/sales/${item?.id}`}>
                                        {item?.id}
                                    </Link>
                                </th>
                                <td>
                                    <Link
                                        to={`/users/${item?.seller?.id}`}>
                                        {item?.seller?.name}
                                    </Link>
                                </td>
                                <td>
                                    <Link
                                        to={`/clients/${item?.client?.id}`}>
                                        {item?.client?.name}
                                    </Link>
                                </td>
                                <td>
                                    {
                                        item?.items[0] ? (
                                            <MoneyFormat
                                                value={item?.items[0].quantity}
                                                thousandSeparator={true}/>
                                        ) : (

                                            <MoneyFormat value={0}/>
                                        )
                                    }

                                </td>
                                <td>
                                    {
                                        item?.items[0] ? (
                                            <MoneyFormat
                                                value={item?.items[0].price}
                                                thousandSeparator={true}/>
                                        ) : (
                                            <MoneyFormat
                                                value={0}
                                                thousandSeparator={true}/>
                                        )
                                    }
                                </td>
                                <td>
                                    {
                                        item?.items[0] ? (
                                            <MoneyFormat
                                                value={item?.items[0].quantity * item?.items[0].price}
                                                thousandSeparator={true}/>
                                        ) : (
                                            <MoneyFormat
                                                value={0}
                                                thousandSeparator={true}/>
                                        )
                                    }
                                </td>
                                <td>{item?.date}</td>
                            </tr>
                        ))
                    }

                    <tr key={"total_sales"}>
                        <th>
                            #
                        </th>
                        <th>
                            -
                        </th>
                        <td>
                            *
                        </td>
                        <td>
                            *
                        </td>
                        <td>{}
                            <MoneyFormat
                                value={data?.reduce((acc, cur) => acc + cur?.items[0].sold_quantity, 0)}
                                thousandSeparator={true}
                            />
                        </td>
                        <td>
                            -
                        </td>
                        <td>
                            <MoneyFormat
                                value={data?.reduce((acc, cur) => acc + cur?.items[0].sold_quantity * cur?.items[0].sold_price, 0) || 0}
                                thousandSeparator={true}
                            />
                        </td>
                        <td>
                            Jami
                        </td>
                    </tr>
                    </tbody>
                </table>

            </div>

            <CustomPagePagination
                path={'products'}
                numberOfPages={meta?.last_page}
                getPageItems={getForProductSales}
                current_page={meta?.current_page}
            />
        </>
    )
}

export default ProductSales;