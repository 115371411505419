import React, {useEffect, Fragment} from 'react';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Spinner from '../layout/Spinner';
import {loadUser} from '../../actions/auth';
import {showSidebar} from '../../actions/sidebar';
import {showNavbar} from '../../actions/navbar';
import {setGeneralStatisticsMonth} from '../../actions/dashboard';

import DashboardFirmFilter from "./DashboardFirmFilter";
import DashboardMonthFilter from "./DashboardMonthFilter";
import StatisticsCards from "./StatisticsCards";
import MonthlyExpensesChart from "./MonthlyExpensesChart";
import ClientTransactionsChart from './ClientTransactionsChart';
import SupplierTransactionsChart from './SupplierTransactionsChart';
import TopProductsChart from './TopProductsChart';
import TopMaterialsChart from './TopMaterialsChart';
import MonthlySalesChart from "./MonthlySalesChart";
import YearlyIncomeExpensesChart from "./YearlyIncomeExpensesChart";

const Dashboard = ({
    getCurrentUser,
    user, loading, isAuthenticated,
    role_name,
    statistics,
    showSidebar,
    showNavbar,
    dashboard: { generalStatistics },
    setGeneralStatisticsMonth
}) => {
    const [selectedFirm, setSelectedFirm] = React.useState(null);
    const [selectedMonth, setSelectedMonth] = React.useState({
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1
    });

    useEffect(() => {
        showSidebar();
        showNavbar();
    }, [showSidebar, showNavbar]);

    const history = useHistory();
    useEffect(() => {
        if (!loading && user !== null && (role_name === 'manager' || role_name === 'storekeeper')) {
            history.replace('/profile')
        }
    }, [history, role_name, loading, user]);

    return loading || user === null ? (
        <Spinner/>
    ) : (
        <Fragment>
            <div className="container-fluid p-0">
                {/* Statistics filtered by both firm and month */}
                <div className="mb-4">

                    <StatisticsCards 
                        statistics={statistics}
                    />
{/* 
                    <div className="row">
                        <div className="col-12 col-lg-12">
                            <IncomeAndExpensesChart selectedFirm={selectedFirm} selectedMonth={selectedMonth} />
                        </div>
                    </div> */}

                    <div className="row">
                        <div className="col-12 col-lg-12">
                            <YearlyIncomeExpensesChart selectedFirm={selectedFirm} />
                        </div>
                    </div>
                    
                    <div className="row mb-2">
                        <div className="col-12 col-lg-6">
                            <h5 className="mb-3">Firma va oylik statistika</h5>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <DashboardMonthFilter 
                                        selectedMonth={selectedMonth} 
                                        setSelectedMonth={setSelectedMonth} 
                                    />
                                </div>
                                <div className="col-12 col-lg-6">
                                    <DashboardFirmFilter 
                                        selectedFirm={selectedFirm} 
                                        setSelectedFirm={setSelectedFirm}
                                        firms={user.firms}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <MonthlyExpensesChart selectedFirm={selectedFirm} selectedMonth={selectedMonth} />
                        </div>
                        <div className="col-12 col-lg-6">
                            <MonthlySalesChart selectedFirm={selectedFirm} selectedMonth={selectedMonth} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <TopProductsChart selectedFirm={selectedFirm} selectedMonth={selectedMonth} />
                        </div>
                        <div className="col-12 col-lg-6">
                            <TopMaterialsChart selectedFirm={selectedFirm} selectedMonth={selectedMonth} />
                        </div>
                    </div>
                </div>

                {/* Statistics filtered by month only */}
                <div>
                    <div className="row">
                        <div className="col-12 col-lg-9">
                            <h5 className="mb-3">Umumiy statistika</h5>
                        </div>
                        <div className="col-12 col-lg-3">
                            <DashboardMonthFilter 
                                selectedMonth={generalStatistics.month} 
                                setSelectedMonth={setGeneralStatisticsMonth} 
                            />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-12 col-lg-6">
                            <ClientTransactionsChart selectedMonth={generalStatistics.month} />
                        </div>
                        <div className="col-12 col-lg-6">
                            <SupplierTransactionsChart selectedMonth={generalStatistics.month} />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

Dashboard.propTypes = {
    showSidebar: PropTypes.func.isRequired,
    showNavbar: PropTypes.func.isRequired,
    loadUser: PropTypes.func.isRequired,
    dashboard: PropTypes.object.isRequired,
    setGeneralStatisticsMonth: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    user: state.auth.user,
    loading: state.auth.loading,
    isAuthenticated: state.auth.isAuthenticated,
    role_name: state.auth.role_name,
    statistics: state.dashboard.statistics,
    dashboard: state.dashboard
});

export default connect(mapStateToProps, {
    loadUser, 
    showSidebar, 
    showNavbar,
    setGeneralStatisticsMonth
})(Dashboard);
