import axios from 'axios';
import {clearAlerts, setAlert} from './alert';
import {
    ADD_PRODUCT,
    CLEAR_ERRORS,
    GET_MATERIAL_OPTIONS,
    GET_PRODUCT,
    GET_PRODUCT_OPTIONS,
    GET_PRODUCT_PRODUCTIONS,
    GET_PRODUCT_SALES,
    GET_PRODUCT_WAREHOUSES_ON_PRODUCTS,
    GET_PRODUCTS,
    GET_UNIT_OPTIONS,
    GET_WAREHOUSE_OPTIONS,
    PRODUCT_ERROR,
    REMOVE_DELETE_PRODUCT,
    REMOVE_DELETE_PRODUCT_PRODUCTION,
    REMOVE_DELETE_PRODUCT_SALE,
    SET_SALE_ERROR,
    REMOVE_UPDATE_PRODUCT,
    REMOVE_UPDATE_PRODUCT_PRODUCTION,
    REMOVE_UPDATE_PRODUCT_SALE,
    SET_DELETE_PRODUCT_ID,
    SET_DELETE_PRODUCT_PRODUCTION_ID,
    SET_DELETE_PRODUCT_SALE_ID, SET_LOADING, SET_PRODUCTION_ERROR,
    SET_UPDATE_PRODUCT_ID,
    SET_UPDATE_PRODUCT_PRODUCTION_ID,
    SET_UPDATE_PRODUCT_SALE_ID,
} from './types';
import {request} from "../utils/service";
import {setLoading, unsetLoading} from "./products_transaction";

export const getUnitOptions = () => async (dispatch) => {
    try {
        const res = await axios.get('/units');
        dispatch({
            type: GET_UNIT_OPTIONS,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getProductOptions = (filters = {}) => async (dispatch) => {
    let queryParams = '';
    if (filters) {
        Object.keys(filters).map((key) => queryParams += `&${key}=${filters[key]}`);
    }
    try {
        const res = await axios.get(`/products?is_all=true${queryParams}`);
        dispatch({
            type: GET_PRODUCT_OPTIONS,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getProductWarehouses = () => async (dispatch) => {
    try {
        const res = await axios.get('/warehouses?type=product');
        dispatch({
            type: GET_PRODUCT_WAREHOUSES_ON_PRODUCTS,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getProduct = (productID) => async (dispatch) => {
    try {
        const res = await axios.get(`/products/${productID}`);
        dispatch({
            type: GET_PRODUCT,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getMaterialOptions = (filters = {}) => async (dispatch) => {

    let queryParams = '';
    if (filters) {
        Object.keys(filters).map((key) => queryParams += `&${key}=${filters[key]}`);
    }
    try {
        const res = await axios.get(`/materials?is_all=true${queryParams}`);
        dispatch({
            type: GET_MATERIAL_OPTIONS,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};


export const getWarehouseOptions = (filters = {}) => async (dispatch) => {
    try {
        let queryParams = '';
        if (filters) {
            Object.keys(filters).map((key) => queryParams += `&${key}=${filters[key]}`);
        }
        const res = await axios.get(`/warehouses?is_all=true&type=product${queryParams}`);

        dispatch({
            type: GET_WAREHOUSE_OPTIONS,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const addForProductProduction = (formData, product_ID) => async (dispatch) => {
    dispatch({type: SET_PRODUCTION_ERROR, payload: true});
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        await axios.post(`/productions/`, formData, config);


        dispatch(setAlert('Production yaratildi !', 'success'));
        dispatch(getForProductProductions(product_ID));

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));

        if (err?.response?.data?.errors) {
            dispatch({
                type: PRODUCT_ERROR,
                payload: err.response.data,
            });
        } else {
            dispatch(setAlert("Production yaratilmadi !", 'danger'));
        }
    }
};

export const updateForProductProduction = (update_product_production_ID, product_ID, updateformData) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        await axios.patch(`/productions/${update_product_production_ID}`, updateformData, config);

        dispatch({type: REMOVE_UPDATE_PRODUCT_PRODUCTION});

        dispatch(getForProductProductions(product_ID));

        dispatch(setAlert('Production tahrirlandi !', 'success'));
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        dispatch({
            type: PRODUCT_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const getProducts = (page = 1, filters = {}) => async (dispatch) => {
    dispatch({type: SET_LOADING, payload: true});
    try {
        let queryParams = '';
        if (filters) {
            Object.keys(filters).map((key) => queryParams += `&${key}=${filters[key]}`);
        }
        const res = await axios.get(`/products?page=${page}${queryParams}`);
        dispatch({
            type: GET_PRODUCTS,
            payload: res.data,
        });
        dispatch({type: SET_LOADING, payload: false});
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const addProduct = (formData, filters = {}) => async (dispatch) => {
    dispatch(clearAlerts());
    dispatch({type: SET_LOADING, payload: true});

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.post('/products/', formData, config);

        dispatch({
            type: ADD_PRODUCT,
            payload: res.data,
        });
        dispatch(setAlert('Mahsulot yaratildi !', 'success'));
        dispatch(getProducts(filters.payload, filters));
        dispatch(getProductOptions());
        dispatch({type: SET_LOADING, payload: false});
        return true;
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));

        if (err.response?.data?.errors) {
            dispatch({
                type: PRODUCT_ERROR,
                payload: err.response.data,
            });
        }
        return false;
    }
};

export const addProductProduction = (formData, filters) => async (dispatch) => {

    dispatch({type:SET_LOADING, payload: true});
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        await axios.post('/productions/', formData, config);
        dispatch(setAlert('Mahsulot ishlab chiqarish bajarildi !', 'success'));
        dispatch(getProducts(filters.page || 1, filters));
        return true;
    } catch (err) {
        dispatch({type: SET_PRODUCTION_ERROR, payload: err.response.data });
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        return false;
    }
};

export const deleteProduct = (deleteProductID, getPage) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        await axios.delete(`/products/${deleteProductID}`, config);

        dispatch({
            type: REMOVE_DELETE_PRODUCT
        });
        dispatch(setAlert("Mahsulot o'chirildi !", 'success'));

        dispatch(getProducts(getPage));
        dispatch(getProductOptions());
        dispatch(clearErrors())

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const deleteProduction = (deleteProductionID, filters = {}) => async (dispatch) => {


    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        await axios.delete(`/productions/${deleteProductionID}`, config);

        dispatch({
            type: REMOVE_DELETE_PRODUCT_PRODUCTION
        });
        dispatch(setAlert("Mahsulot ishlab chiqarish o'chirildi!", 'success'));

        dispatch(getForProductProductions(filters.product_id, filters));

        dispatch(clearErrors())

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        dispatch(setAlert("Mahsulot ishlab chiqarish o'chirilmadi!", 'danger'));
    }
};

export const getForProductProductions = (product_ID, filters = {}) => async (dispatch) => {
    let queryParams = '';
    if (filters) {
        Object.keys(filters).map((key) => queryParams += `&${key}=${filters[key]}`);
    }
    try {
        const res = await axios.get(`/productions/?product_id=${product_ID}${queryParams}`);
        dispatch({
            type: GET_PRODUCT_PRODUCTIONS,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const updateProduct = (updateProductID, updateformData, filters = {}) => async (dispatch) => {
    dispatch(clearAlerts());
    dispatch({type: SET_LOADING, payload: true});

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        await axios.put(`/products/${updateProductID}`, updateformData, config);
        dispatch(getProducts(filters.page, filters));
        dispatch(getProductOptions());
        dispatch(setAlert('Mahsulot tahrirlandi !', 'success'));
        dispatch({type: SET_LOADING, payload: true});
        return true;
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        dispatch({type: PRODUCT_ERROR, payload: err.response.data});
        return false;
    }
};

export const setDeleteProductId = (productID) => async (dispatch) => {

    try {
        if (productID !== null) {
            dispatch({
                type: SET_DELETE_PRODUCT_ID,
                payload: {
                    productID
                }
            });

        } else {
            dispatch({
                type: REMOVE_DELETE_PRODUCT
            });
        }

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const setUpdateProductId = (productID) => async (dispatch) => {

    try {
        if (productID !== null) {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            const res = await axios.get(`/products/${productID}`, config);

            dispatch({
                type: SET_UPDATE_PRODUCT_ID,
                payload: res.data
            });
        } else dispatch({type: REMOVE_UPDATE_PRODUCT});

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const setUpdateProductProductionId = (product_production_ID, productionItems) => async (dispatch) => {

    try {
        if (product_production_ID !== null) {

            let update_product_production = productionItems.find((item) => item.id === product_production_ID);

            dispatch({
                type: SET_UPDATE_PRODUCT_PRODUCTION_ID,
                payload: update_product_production
            });
        } else dispatch({type: REMOVE_UPDATE_PRODUCT_PRODUCTION});

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const setDeleteProductProductionId = (product_production_ID, productionItems) => async (dispatch) => {

    try {
        if (product_production_ID !== null) {

            let product_production = productionItems.find((item) => item.id === product_production_ID);

            dispatch({
                type: SET_DELETE_PRODUCT_PRODUCTION_ID,
                payload: product_production
            })

        } else dispatch({type: SET_DELETE_PRODUCT_PRODUCTION_ID, payload: null});

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS,
    });
};

export const getForProductSales = (product_ID, filters = {}) => async (dispatch) => {

    try {
        let queryParams = '';
        if (filters) {
            Object.keys(filters).map((key) => queryParams += `&${key}=${filters[key]}`);
        }
        const res = await axios.get(`/sales/?with_product_id=${product_ID}${queryParams}`);
        dispatch({
            type: GET_PRODUCT_SALES,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const addForProductSale = (formData, product_ID) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        await axios.post(`/sales/`, formData, config);

        /*dispatch({
            type: ADD_PRODUCT_PRODUCTION,
            payload: res.data,
        });*/

        dispatch(setAlert('Sotuv yaratildi !', 'success'));
        dispatch(getForProductSales(product_ID));

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));

        if (err?.response?.data?.errors) {
            dispatch({
                type: PRODUCT_ERROR,
                payload: err.response.data,
            });
        } else {
            dispatch(setAlert("Sotuv yaratilmadi !", 'danger'));
        }
    }
};

export const addProductSale = (formData) => async (dispatch) => {


    dispatch({type: SET_SALE_ERROR, payload: true});
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        await axios.post('/sales/', formData, config);

        dispatch(setAlert('Sotuv saqlandi !', 'success'));
        dispatch(getProducts());

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));

        if (err?.response?.data?.errors) {
            dispatch({
                type: PRODUCT_ERROR,
                payload: err.response.data,
            });
        } else {
            dispatch(setAlert("Sotuv saqlanmadi!", 'danger'));
        }
    }
};

export const updateForProductSale = (update_product_sale_ID, product_ID, updateformData) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        await axios.patch(`/sales/${update_product_sale_ID}`, updateformData, config);

        dispatch({type: REMOVE_UPDATE_PRODUCT_SALE});

        dispatch(getForProductSales(product_ID));

        dispatch(setAlert('Sotuv tahrirlandi !', 'success'));
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        if (err?.response?.data?.errors) {
            dispatch({
                type: PRODUCT_ERROR,
                payload: err.response.data,
            });
        } else {
            dispatch(setAlert("Sotuv tahrirlanmadi!", 'danger'));
        }
    }
};

export const deleteForProductSale = (deleteSaleID, product_id) => async (dispatch) => {

    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        await axios.delete(`/sales/${deleteSaleID}`, config);

        dispatch({
            type: REMOVE_DELETE_PRODUCT_SALE
        });
        dispatch(setAlert("Sotuv o'chirildi!", 'success'));

        dispatch(getForProductSales(product_id));

        dispatch(clearErrors())

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const setUpdateProductSaleId = (product_sale_ID, saleItems) => async (dispatch) => {

    try {
        if (product_sale_ID !== null) {

            let update_product_sale = saleItems.find((item) => item.id === product_sale_ID);

            dispatch({
                type: SET_UPDATE_PRODUCT_SALE_ID,
                payload: update_product_sale
            });
        } else dispatch({type: REMOVE_UPDATE_PRODUCT_SALE});

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const setDeleteProductSaleId = (product_sale_ID, saleItems) => async (dispatch) => {

    try {
        if (product_sale_ID !== null) {

            let product_production = saleItems.find((item) => item.id === product_sale_ID);

            dispatch({
                type: SET_DELETE_PRODUCT_SALE_ID,
                payload: product_production
            })

        } else dispatch({type: SET_DELETE_PRODUCT_SALE_ID, payload: null});

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};


// download report
export const downloadSingleProductReport = (id, filters = {}) => async (dispatch) => {
    dispatch(setLoading());
    dispatch(clearAlerts());
    let queryParams = `?`;
    if (filters) {
        Object.keys(filters).map((key, index) => index === 0 ? queryParams += `${key}=${filters[key]}` : queryParams += `&${key}=${filters[key]}`);
    }
    const res = await request(`/products/${id}/generateReport/${queryParams}`, {method: 'GET'}, dispatch);

    if (res?.hasOwnProperty('errors')) {
        const err = res.errors;
        dispatch(setAlert(err.response.statusText, 'danger'));
        dispatch(unsetLoading());
        return false;
    } else {
        dispatch(unsetLoading());
        return res?.link;
    }
};








