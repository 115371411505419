import {useState} from "react";
import {useDispatch} from "react-redux";
import {downloadSupplierReport} from "../../actions/suppliers_transaction";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const SupplierReport = () => {
    const dispatch = useDispatch();
    const [fileFormat, setFileFormat] = useState('pdf');
    const [toDate, setToDate] = useState(new Date());

    const onDownload = async (e) => {
        e.preventDefault();
        const data = {
            file_format: fileFormat,
            to_date: toDate ? toDate.toISOString().split('T')[0] : ''
        };
        const link = await dispatch(downloadSupplierReport(data));
        if (link !== false && link !== undefined)
            window.open(link);
    }

    return (
        <form className="row g-3">
            <div className="col-auto">
                <label htmlFor="file_format" className="visually-hidden">Fayl formati</label>
                <select className={'form-control form-select'}
                        value={fileFormat}
                        onChange={e => setFileFormat(e.target.value)}
                >
                    <option value="">Fayl formati</option>
                    <option value="pdf">PDF</option>
                    <option value="xlsx">XLSX</option>
                </select>
            </div>

            <div className="col-auto">
                <DatePicker
                    selected={toDate}
                    onChange={date => setToDate(date)}
                    className="form-control"
                    placeholderText="Sana"
                    dateFormat="dd.MM.yyyy"
                    isClearable
                />
            </div>

            <div className="col-auto">
                <button type="button" className="btn btn-primary mb-3" onClick={onDownload}>Yuklash</button>
            </div>
        </form>
    )
}

export default SupplierReport;