import React, {useEffect, useState} from "react";
import moment from "moment/moment";
import {useDispatch} from "react-redux";
import {getUserActivities} from "../../../actions/user";
import CustomPaginationWithManyPages from "../../layout/CustomPaginationWithManyPages";

const UserActivities = ({user, activities, numberOfPages, currentPage}) => {
    const dispatch = useDispatch();
    const [id, setId] = useState(null);
    const [search, setSearch] = useState('');

    useEffect(() => {
        if (user && user.hasOwnProperty('id')) {
            setId(user.id);
            dispatch(getUserActivities(1, {user_id: user.id}));
        }
    }, [user])


    const [from_date, setFromDate] = useState('');
    const [to_date, setToDate] = useState('');


    const onChangeSearch = (event) => {
        setSearch(state => event.target.value);
        const params = {};
        params.to_date = to_date;
        params.from_date = from_date;
        params.search = event.target.value;

        const url = new URL(window.location);
        url.searchParams.set('search', event.target.value);
        window.history.pushState({}, '', url);

        const custom_params = {...params, ...{user_id: user.id}};
        dispatch(getUserActivities(1, custom_params));
    };

    const onChangeFromDate = (event) => {
        setFromDate(state => event.target.value);
        const params = {};
        params.to_date = to_date;
        params.from_date = event.target.value;
        const custom_params = {...params, ...{user_id: user.id}};

        const url = new URL(window.location);
        url.searchParams.set('from_date', event.target.value);
        window.history.pushState({}, '', url);

        dispatch(getUserActivities(1, custom_params));

    };

    const onChangeToDate = (event) => {
        setToDate(state => event.target.value);
        const params = {};
        params.to_date = event.target.value;
        params.from_date = from_date;
        const custom_params = {...params, ...{user_id: user.id}};

        const url = new URL(window.location);
        url.searchParams.set('to_date', event.target.value);
        window.history.pushState({}, '', url);

        dispatch(getUserActivities(1, custom_params));
    };

    return (
        <div className="card mb-3">
            <div className="card-header py-2">
                <div className="row">
                    <div className="col-md-6">
                        <h5 className="card-title mb-0">
                            <u> {user?.name} </u> ning
                            faolliklari
                        </h5>
                    </div>
                    <div className="col-md-6">
                        <input type="search" className="form-control" placeholder={'Qidiruv...'} value={search}
                               onChange={onChangeSearch}/>
                    </div>
                </div>
            </div>
            <div
                className="mb-2 align-items-center d-flex justify-content-between px-3">

                <div className="mb-3">
                    <label htmlFor="date1" className="mb-2">dan</label>
                    <input onChange={onChangeFromDate} className="form-control"
                           value={from_date}
                           id="date1" type="date"/>
                </div>

                <div className="mb-3">
                    <label htmlFor="date2" className="mb-2">gacha</label>
                    <input onChange={onChangeToDate} className="form-control"
                           value={to_date}
                           id="date2" type="date"/>
                </div>
            </div>
            <div className="card-body text-center">
                <ul className="activity-list list-unstyled">
                    {
                        activities && activities.map((item, index) => (

                            <li key={index} className="clearfix">
                                <div className="float-left">
                                    <a href="#"><img
                                        src="https://bootdey.com/img/Content/avatar/avatar6.png"
                                        alt=""
                                        className="img-fluid rounded-circle"/>
                                    </a>
                                </div>
                                <div className="act-content">
                                    <div className="font400">
                                        <b href="#"
                                           className="font600">{item?.user?.name}
                                        </b>
                                    </div>
                                    <span
                                        className="text-small">
                                        {moment(item?.created_at).format('MMMM Do YYYY, h:mm:ss')}
                                    </span>
                                    <p className={'text-muted well'}>
                                        {item?.message}
                                    </p>
                                </div>
                            </li>
                        ))
                    }
                </ul>
                {
                    id ?
                    <CustomPaginationWithManyPages
                            getPageItems={getUserActivities}
                            custom_params={{user_id: id}}
                            numberOfPages={numberOfPages}
                            current_page={currentPage}
                    /> : ""
                }
            </div>
        </div>
    )
}

export default UserActivities;