import {
    SHOW_SIDEBAR,
    HIDE_SIDEBAR,
    TOGGLE_SIDEBAR,
    SET_ACTIVE_MENU_ITEM, COLLAPSE_SIDEBAR, EXPAND_SIDEBAR
} from '../actions/types';
import {
    Archive,
    Briefcase,
    DollarSign,
    File,
    GitPullRequest,
    Hash,
    Package,
    Sidebar,
    Sliders,
    Speaker,
    Trello,
    TrendingDown,
    TrendingUp,
    Truck,
    UserCheck,
    Users,
    Shield,
    Key
} from 'react-feather';

const initalState = {
    isExpanded: true,
    isShown: true,
    loading: true,
    menu: [
        {
            name: "Dashboard",
            route: "/dashboard",
            icon_tag: <Sliders color="rgba(233, 236, 239, 0.5)" size={18}/>,
            is_active: false,
            is_visible: true,
            is_section: false,
            roles: ['superadmin', 'director', 'accountant']
        },
        {
            name: "Xodimlar & Firmalar",
            route: "/dashboard",
            icon: null,
            is_active: false,
            is_visible: true,
            is_section: true,
            roles: ['superadmin', 'director', 'manager', 'accountant']
        },
        {
            name: "Firmalar",
            icon_tag: <Briefcase color="rgba(233, 236, 239, 0.5)" size={18}/>,
            is_active: false,
            route: "/firms",
            is_visible: true,
            is_section: false,
            roles: ['superadmin', 'director', 'accountant', ]
        },
        {
            name: "Xodimlar",
            icon_tag: <UserCheck color="rgba(233, 236, 239, 0.5)" size={18}/>,
            is_active: false,
            route: "/employees",
            is_visible: true,
            is_section: false,
            roles: ['superadmin', 'director', 'manager', 'accountant']
        },
        {
            name: "Xodimlar ish haqi",
            icon_tag: <DollarSign color="rgba(233, 236, 239, 0.5)" size={18}/>,
            is_active: false,
            route: "/employee-calculations",
            is_visible: true,
            is_section: false,
            roles: ['superadmin', 'director', 'accountant', 'manager']
        },
        {
            name: "Tizim va foydalanuvchilar",
            icon: null,
            is_active: false,
            route: "/system-users",
            is_visible: true,
            is_section: true,
            roles: ['superadmin', 'director', 'manager', 'storekeeper', 'accountant']
        },
        {
            name: "Foydalanuvchilar",
            icon_tag: <Users color="rgba(233, 236, 239, 0.5)" size={18}/>,
            is_active: false,
            route: "/users",
            is_visible: true,
            is_section: false,
            roles: ['superadmin', 'director', 'accountant']
        },
        {
            name: "Pages",
            icon_tag: <File color="rgba(233, 236, 239, 0.5)" size={18}/>,
            is_active: false,
            route: "/pages",
            is_visible: true,
            is_section: false,
            roles: ['superadmin']
        },
        {
            name: "Rollar",
            icon_tag: <Shield color="rgba(233, 236, 239, 0.5)" size={18}/>,
            is_active: false,
            route: "/roles",
            is_visible: true,
            is_section: false,
            roles: ['superadmin', 'director']
        },
        {
            name: "Ruxsatlar",
            icon_tag: <Key color="rgba(233, 236, 239, 0.5)" size={18}/>,
            is_active: false,
            route: "/permissions",
            is_visible: true,
            is_section: false,
            roles: ['superadmin']
        },
        {
            name: "Ishlab chiqarish & Skladlar",
            icon: null,
            is_active: false,
            route: "/producing-product",
            is_visible: true,
            is_section: true,
            roles: ['superadmin', 'director', 'manager', 'storekeeper', 'accountant']
        },
        {
            name: "Yetkazuvchilar",
            icon_tag: <Sidebar color="rgba(233, 236, 239, 0.5)" size={18}/>,
            is_active: false,
            route: "/suppliers",
            is_visible: true,
            is_section: false,
            roles: ['superadmin', 'director', 'manager', 'accountant']
        },
        {
            name: "Skladlar",
            icon_tag: <Archive color="rgba(233, 236, 239, 0.5)" size={18}/>,
            is_active: false,
            route: "/warehouses",
            is_visible: true,
            is_section: false,
            roles: ['superadmin', 'director', 'accountant', 'manager', 'storekeeper']
        },
        {
            name: "Materiallar",
            icon_tag: <File color="rgba(233, 236, 239, 0.5)" size={18}/>,
            is_active: false,
            route: "/materials",
            is_visible: true,
            is_section: false,
            roles: ['superadmin', 'director', 'manager', 'storekeeper', 'accountant']
        },
        {
            name: "Mahsulotlar",
            icon_tag: <Package color="rgba(233, 236, 239, 0.5)" size={18}/>,
            is_active: false,
            route: "/products",
            is_visible: true,
            is_section: false,
            roles: ['superadmin', 'director', 'manager', 'storekeeper', 'accountant']
        },
        {
            name: "Ishlab chiqarishlar",
            icon_tag: <Trello color="rgba(233, 236, 239, 0.5)" size={18}/>,
            is_active: false,
            route: "/productions",
            is_visible: true,
            is_section: false,
            roles: ['superadmin', 'director', 'manager', 'storekeeper', 'accountant']
        },

        {
            name: "O'lchov birliklari",
            icon_tag: <Hash color="rgba(233, 236, 239, 0.5)" size={18}/>,
            is_active: false,
            route: "/units",
            is_visible: true,
            is_section: false,
            roles: ['superadmin', 'director', 'storekeeper', 'accountant']
        },
        {
            name: "Tranzaksiyalar",
            icon: null,
            is_active: false,
            route: "/producing-product",
            is_visible: true,
            is_section: true,
            roles: ['superadmin', 'director', 'manager']
        },
        {
            name: "Material importi(поставщик)",
            icon_tag: <GitPullRequest color="rgba(233, 236, 239, 0.5)" size={18}/>,
            is_active: false,
            route: "/supplier-material-calculations",
            is_visible: true,
            is_section: false,
            roles: ['superadmin', 'director', 'manager', 'accountant']
        },
        {
            name: "Material sarfi(продукт)",
            icon_tag: <GitPullRequest color="rgba(233, 236, 239, 0.5)" size={18}/>,
            is_active: false,
            route: "/product-material-calculations",
            is_visible: true,
            is_section: false,
            roles: ['superadmin', 'director', 'manager', 'accountant']
        },
        {
            name: "Material hisob-kitobi",
            icon_tag: <GitPullRequest color="rgba(233, 236, 239, 0.5)" size={18}/>,
            is_active: false,
            route: "/material-calculations",
            is_visible: true,
            is_section: false,
            roles: ['superadmin', 'director', 'manager', 'accountant']
        },
        {
            name: "Mahsulot hisob-kitobi",
            icon_tag: <GitPullRequest color="rgba(233, 236, 239, 0.5)" size={18}/>,
            is_active: false,
            route: "/product-calculations",
            is_visible: true,
            is_section: false,
            roles: ['superadmin', 'director', 'manager', 'accountant']
        },
        {
            name: "Yetkazuvchi transaksiyalar",
            icon_tag: <DollarSign color="rgba(233, 236, 239, 0.5)" size={18}/>,
            is_active: false,
            route: "/supplier-calculations",
            is_visible: true,
            is_section: false,
            roles: ['superadmin', 'director', 'manager', 'accountant']
        },
        {
            name: "Klient transaksiyalar",
            icon_tag: <DollarSign color="rgba(233, 236, 239, 0.5)" size={18}/>,
            is_active: false,
            route: "/client-calculations",
            is_visible: true,
            is_section: false,
            roles: ['superadmin', 'director', 'manager', 'accountant']
        },
        {
            name: "Zapchastlar & Transport",
            icon: null,
            is_active: false,
            route: "/suppliers",
            is_visible: true,
            is_section: true,
            roles: ['superadmin', 'director', 'manager', 'accountant']
        },

        {
            name: "Transportlar",
            icon_tag: <Truck color="rgba(233, 236, 239, 0.5)" size={18}/>,
            is_active: false,
            route: "/transports",
            is_visible: true,
            is_section: false,
            roles: ['superadmin', 'director', 'manager', 'accountant']
        },

        {
            name: "Zapchastlar",
            icon_tag: <Speaker color="rgba(233, 236, 239, 0.5)" size={18}/>,
            is_active: false,
            route: "/spare-parts",
            is_visible: true,
            is_section: false,
            roles: ['superadmin', 'director', 'manager', 'accountant']
        },
        {
            name: "Savdo va Harajatlar",
            icon: null,
            is_active: false,
            route: "/sales",
            is_visible: true,
            is_section: true,
            roles: ['superadmin', 'director', 'manager', 'accountant']
        },
        {
            name: "Klientlar",
            icon_tag: <Sidebar color="rgba(233, 236, 239, 0.5)" size={18}/>,
            is_active: false,
            route: "/clients",
            is_visible: true,
            is_section: false,
            roles: ['superadmin', 'director', 'accountant', 'manager']
        },
        {
            name: "Sotuvlar",
            icon_tag: <TrendingUp color="rgba(233, 236, 239, 0.5)" size={18}/>,
            is_active: false,
            route: "/sales",
            is_visible: true,
            is_section: false,
            roles: ['superadmin', 'director', 'accountant', 'manager']
        },
        {
            name: "Harajatlar",
            icon_tag: <TrendingDown color="rgba(233, 236, 239, 0.5)" size={18}/>,
            is_active: false,
            route: "/expenses",
            is_visible: true,
            is_section: false,
            roles: ['superadmin', 'director', 'manager', 'accountant']
        },
        {
            name: "Harajat shablonlar",
            icon_tag: <Sidebar color="rgba(233, 236, 239, 0.5)" size={18}/>,
            is_active: false,
            route: "/expense-templates",
            is_visible: true,
            is_section: false,
            roles: ['superadmin', 'director', 'manager', 'accountant']
        },
    ],
};

export default function (state = initalState, action) {

    const {type} = action;

    switch (type) {

        case SHOW_SIDEBAR:
            return {
                ...state,
                isShown: true
            };
        case HIDE_SIDEBAR:
            return {
                ...state,
                isShown: false
            };
        case TOGGLE_SIDEBAR:
            return {
                ...state,
                isExpanded: !state.isExpanded
            };
        case EXPAND_SIDEBAR:
            return {
                ...state,
                isExpanded: true
            };
        case COLLAPSE_SIDEBAR:
            return {
                ...state,
                isExpanded: false
            };
        case SET_ACTIVE_MENU_ITEM:
            return {
                ...state,
                menu: state.menu.map((item) => {
                    item.is_active = !!(item.route === action.payload || action.payload.includes(item.route));
                    return item;
                })
            };
        default:
            return state;
    }
}
