import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import SpecialSaleCreateForm from './SpecialSaleCreateForm';
import SimpleSaleCreateForm from './SimpleSaleCreateForm';

const ProductSaleCreateForm = ({ onClose }) => {
    const [isSpecialSale, setIsSpecialSale] = useState(false);

    return (
        <div>
            <div className="mb-4">
                <Form.Check
                    inline
                    type="radio"
                    name="saleSubType"
                    id="simpleSale"
                    label="Oddiy sotuv"
                    checked={!isSpecialSale}
                    onChange={() => setIsSpecialSale(false)}
                />
                <Form.Check
                    inline
                    type="radio"
                    name="saleSubType"
                    id="specialSale"
                    label="Maxsus sotuv"
                    checked={isSpecialSale}
                    onChange={() => setIsSpecialSale(true)}
                />
            </div>
            {isSpecialSale ? (
                <SpecialSaleCreateForm onClose={onClose} />
            ) : (
                <SimpleSaleCreateForm onClose={onClose} />
            )}
        </div>
    );
};

export default ProductSaleCreateForm;
