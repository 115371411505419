import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDashboardStatistics } from '../../actions/dashboard';
import StatisticsBalansCards from './StatisticsBalansCards';
import StatisticsNumbersCards from './StatisticsNumbersCards';

const StatisticsCards = ({ 
    dashboard: { statistics, loading },
    getDashboardStatistics
}) => {
    const [selectedYear, setSelectedYear] = useState('all');

    const handleYearChange = useCallback((year) => {
        setSelectedYear(year);
        getDashboardStatistics({ year });
    }, [getDashboardStatistics]);

    React.useEffect(() => {
        getDashboardStatistics({ year: selectedYear });
    }, [getDashboardStatistics, selectedYear]);

    if (loading.statistics) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <StatisticsBalansCards
                statistics={statistics}
                selectedYear={selectedYear}
                onYearChange={handleYearChange}
            />
            <StatisticsNumbersCards statistics={statistics} />
        </div>
    );
};

StatisticsCards.propTypes = {
    dashboard: PropTypes.object.isRequired,
    getDashboardStatistics: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    dashboard: state.dashboard
});

export default connect(mapStateToProps, { getDashboardStatistics })(StatisticsCards);
