import {
    CLEAR_ERRORS, CLIENT_TRANSACTION_ERROR,
    GET_CLIENT_TRANSACTIONS,
    GET_CLIENTS_TRANSACTIONS,
    SET_LOADING,
    UNSET_LOADING,
    UPDATE_TRANSACTION_SUCCESS
} from '../actions/types';

const initialState = {
    dates: [],
    clients_transactions: [],
    clients_transaction: {},
    loading: false,
    numberOfPages: 1,
    total_clients: null,
    error: null,
    clients: [],
    client: {},
    client_transactions: [],
};

// eslint-disable-next-line

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            }
        case UNSET_LOADING:
            return {
                ...state,
                loading: false,
            }


        case UPDATE_TRANSACTION_SUCCESS:
            return {
                ...state,
                update_transaction_id: true,
                error: null,
                loading: false,
            };

        case GET_CLIENTS_TRANSACTIONS:
            const {dates, clients} = payload;
            return {
                ...state,
                dates: dates,
                client_transactions: clients.data,
                numberOfPages: clients.last_page,
                current_page: clients.current_page,
                suppliers_data: payload,
                error: null,
                loading: false,
            };

        case GET_CLIENT_TRANSACTIONS:
            const {id} = payload;
            return {
                ...state,
                client_transactions: state.client_transactions.map(item => item.id === id ? payload : item),
                error: null,
                loading: false,
            };

        case CLIENT_TRANSACTION_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
                loading: false,
            };

        default:
            return state;
    }
}
