import React, { useEffect, useState } from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { connect, useDispatch } from "react-redux";
import { setUpdateSupplierId, updateSupplier } from "../../actions/supplier";
import PropTypes from "prop-types";
import { getParams } from "../../utils/hooks/getParams";

const SupplierUpdateForm = ({ auth, supplier: { update_supplier, currentPage } }) => {
    const dispatch = useDispatch();
    const [inputs, setInputs] = useState({
        name: '',
        contractor: '',
        phone: '',
        inn: '',
        bank_account: ''
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (update_supplier) {
            setInputs({
                name: update_supplier.name || '',
                contractor: update_supplier.contractor || '',
                phone: update_supplier.phone || '',
                inn: update_supplier.inn || '',
                bank_account: update_supplier.bank_account || ''
            });
        }
    }, [update_supplier]);

    const handleChange = e => {
        const { name, value } = e.target;
        setErrors(prev => ({ ...prev, [name]: '' }));
        setInputs(prev => ({ ...prev, [name]: value }));
    };

    const closeModal = () => {
        dispatch(setUpdateSupplierId(null));
        setInputs({
            name: '',
            contractor: '',
            phone: '',
            inn: '',
            bank_account: ''
        });
        setErrors({});
    };

    const validateForm = () => {
        const validationErrors = {};
        
        if (!inputs.name) {
            validationErrors.name = "Nomini kiriting";
        }

        return validationErrors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validateForm();

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        const formData = {
            name: inputs.name,
            contractor: inputs.contractor || '',
            phone: inputs.phone || '',
            inn: inputs.inn || '',
            bank_account: inputs.bank_account || ''
        };

        dispatch(updateSupplier(update_supplier.id, formData, getParams()));
        closeModal();
    };

    return (
        <Modal show={update_supplier !== null} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Yetkazib beruvchini tahrirlash</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-3 pb-4 mx-3">
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label className="form-label">Nomi <span className="text-danger">*</span></label>
                        <input
                            name="name"
                            className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                            type="text"
                            value={inputs.name}
                            onChange={handleChange}
                            placeholder="Nomini kiriting"
                        />
                        {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Tashkilot xodimi</label>
                        <input
                            name="contractor"
                            className="form-control"
                            type="text"
                            value={inputs.contractor}
                            onChange={handleChange}
                            placeholder="Tashkilot xodimini kiriting"
                        />
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Telefon</label>
                        <input
                            name="phone"
                            className="form-control"
                            type="text"
                            value={inputs.phone}
                            onChange={handleChange}
                            placeholder="Telefon raqamini kiriting"
                        />
                    </div>

                    <div className="mb-3">
                        <label className="form-label">INN</label>
                        <input
                            name="inn"
                            className="form-control"
                            type="text"
                            value={inputs.inn}
                            onChange={handleChange}
                            placeholder="INN ni kiriting"
                        />
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Bank hisob raqami</label>
                        <input
                            name="bank_account"
                            className="form-control"
                            type="text"
                            value={inputs.bank_account}
                            onChange={handleChange}
                            placeholder="Bank hisob raqamini kiriting"
                        />
                    </div>

                    <div className="d-flex gap-2">
                        <Button variant="secondary" onClick={closeModal} className="w-50">
                            Bekor qilish
                        </Button>
                        <Button variant="primary" type="submit" className="w-50">
                            Saqlash
                        </Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

SupplierUpdateForm.propTypes = {
    auth: PropTypes.object.isRequired,
    supplier: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    supplier: state.supplier,
    auth: state.auth,
});

export default connect(mapStateToProps, {
    setUpdateSupplierId,
    updateSupplier
})(SupplierUpdateForm);