import axios from 'axios';
import {clearAlerts, setAlert} from './alert';
import {
    ADD_CLIENT,
    CLEAR_ERRORS,
    CLIENT_ERROR,
    GET_CLIENT,
    GET_CLIENT_SALES,
    GET_CLIENTS,
    REMOVE_DELETE_CLIENT,
    REMOVE_UPDATE_CLIENT,
    SET_DELETE_CLIENT_ID,
    SET_UPDATE_CLIENT_ID,
    SET_LOADING,
    UNSET_LOADING,
    GET_CLIENT_TRANSACTIONS,
    SUPPLIER_ERROR
} from './types';
import {request} from "../utils/service";

export const setLoading = () => (dispatch) => {
    dispatch({
        type: SET_LOADING,
        payload: true,
    });
};
export const unsetLoading = () => (dispatch) => {
    dispatch({
        type: UNSET_LOADING,
        payload: false,
    });
};

export const getClients = (page = 1, filters = {}) => async (dispatch) => {
    dispatch(setLoading());
    try {

        let queryParams = '';
        if (filters) {
            Object.keys(filters).map((key) => queryParams += `&${key}=${filters[key]}`);
        }
        const res = await axios.get(`/clients?page=${page}${queryParams}`);

        dispatch({
            type: GET_CLIENTS,
            payload: res.data,
        });
        dispatch(unsetLoading());
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getClient = (clientID) => async (dispatch) => {
    dispatch(setLoading());
    try {
        const res = await axios.get(`/clients/${clientID}`);
        dispatch({
            type: GET_CLIENT,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};


export const addClient = (formData) => async (dispatch) => {
    dispatch(clearAlerts());
    dispatch(setLoading());
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.post('/clients/', formData, config);

        dispatch(getClients());

        dispatch({
            type: ADD_CLIENT,
            payload: res.data,
        });

        dispatch(setAlert('Mijoz yaratildi !', 'success'));

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        if (err?.response?.data?.errors) {
            dispatch({
                type: CLIENT_ERROR,
                payload: err.response.data,
            });
        } else {
            dispatch(setAlert("Klient yaratilmadi!", 'danger'));
        }
    }
};

export const deleteClient = (deleteClientID, getPage) => async (dispatch) => {
    dispatch(clearAlerts());
    dispatch(setLoading());
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        await axios.delete(`/clients/${deleteClientID}`, config);

        dispatch({
            type: REMOVE_DELETE_CLIENT
        });

        dispatch(getClients(getPage));

        dispatch(setAlert("Mijoz o'chirildi !", 'success'));

        dispatch(clearErrors());
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        dispatch({
            type: CLIENT_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const updateClient = (updateClientID, updateformData, params) => async (dispatch) => {
    dispatch(clearAlerts());
    dispatch(setLoading());
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        await axios.put(`/clients/${updateClientID}`, updateformData, config);

        dispatch({type: REMOVE_UPDATE_CLIENT});

        dispatch(getClients(params.page, params));

        dispatch(setAlert('Mijoz tahrirlandi !', 'success'));
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        dispatch({
            type: CLIENT_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const setDeleteClientId = (clientID) => async (dispatch) => {

    try {
        if (clientID !== null) {
            dispatch({
                type: SET_DELETE_CLIENT_ID,
                payload: {
                    clientID
                }
            });

        } else {
            dispatch({
                type: REMOVE_DELETE_CLIENT
            });
        }

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const setUpdateClientId = (clientID = null) => async (dispatch) => {

    try {

        if (clientID !== null) {
            dispatch({
                type: SET_UPDATE_CLIENT_ID,
                payload: clientID
            });
        } else dispatch({type: REMOVE_UPDATE_CLIENT});

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getClientTransactions = (client_id, filters = {}) => async (dispatch) => {
    dispatch(setLoading())
    try {
        let queryParams = '';
        if (filters) {
            const validFilters = Object.entries(filters).filter(([_, value]) => value !== '');
            if (validFilters.length > 0) {
                queryParams = '?' + validFilters.map(([key, value]) => `${key}=${value}`).join('&');
            }
        }
        const res = await axios.get(`/client/${client_id}/transactions/${queryParams}`);
        dispatch({
            type: GET_CLIENT_TRANSACTIONS,
            payload: res.data,
        });
        dispatch(unsetLoading());
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const deleteClientTransaction = (transactionId, clientId, filters = {}) => async (dispatch) => {
    dispatch(clearAlerts());
    dispatch(setLoading());
    
    try {
        await axios.delete(`/client-transactions/${transactionId}`);
        dispatch(setAlert('Tranzaksiya muvaffaqiyatli o\'chirildi', 'success'));
        dispatch(getClientTransactions(clientId, filters));
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message || 'Tranzaksiyani o\'chirishda xatolik yuz berdi', 'danger'));
    }
    dispatch(unsetLoading());
};

export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS,
    });
};


export const getClientSales = (client_id, filters = {}) => async (dispatch) => {
    dispatch(setLoading());
    try {
        let queryParams = '';
        if (filters) {
            Object.keys(filters).map((key) => queryParams += `&${key}=${filters[key]}`);
        }
        const res = await axios.get(`/sales/?client_id=${client_id}${queryParams}`);
        dispatch({
            type: GET_CLIENT_SALES,
            payload: res.data,
        });
        dispatch(unsetLoading());
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        dispatch({
            type: SUPPLIER_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};


// download report
export const downloadSingleClientProductsReport = (client_id, filters = {}) => async (dispatch) => {
    dispatch(setLoading());
    dispatch(clearAlerts());
    let queryParams = `?`;
    if (filters) {
        Object.keys(filters).map((key, index) => index === 0 ? queryParams += `${key}=${filters[key]}` : queryParams += `&${key}=${filters[key]}`);
    }
    const res = await request(`/clients/${client_id}/generateProductReport${queryParams}`, {method: 'GET'}, dispatch);

    if (res?.hasOwnProperty('errors')) {
        const err = res.errors;
        dispatch(setAlert(err.response.statusText, 'danger'));
        dispatch(unsetLoading());
        return false;
    } else {
        dispatch(unsetLoading());
        return res?.link;
    }
};



// download report
export const downloadSingleClientTransactionReport = (id, filters = {}) => async (dispatch) => {
    dispatch(setLoading());
    dispatch(clearAlerts());
    let queryParams = `?`;
    if (filters) {
        Object.keys(filters).map((key, index) => index === 0 ? queryParams += `${key}=${filters[key]}` : queryParams += `&${key}=${filters[key]}`);
    }
    const res = await request(`/clients/${id}/generateTransactionsReport/${queryParams}`, {method: 'GET'}, dispatch);

    if (res?.hasOwnProperty('errors')) {
        const err = res.errors;
        dispatch(setAlert(err.response.statusText, 'danger'));
        dispatch(unsetLoading());
        return false;
    } else {
        dispatch(unsetLoading());
        return res?.link;
    }
};
