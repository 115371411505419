import React, { useState, useEffect } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import ProductSaleUpdateForm from './ProductSaleUpdateForm';
import MaterialSaleUpdateForm from './material-sales/MaterialSaleUpdateForm';

const SaleUpdateForm = ({ show, handleClose }) => {
    const { sale } = useSelector(state => state.sale);
    const [selectedType, setSelectedType] = useState('product');

    useEffect(() => {
        if (sale) {
            setSelectedType(sale.type || 'product');
        }
    }, [sale]);

    const handleTypeSelect = (type) => {
        setSelectedType(type);
    };

    if (!show || !sale) return null;

    return (
        <Modal show={show} onHide={handleClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>
                    {selectedType === 'product' ? 'Mahsulot sotuvini tahrirlash' : 'Material sotuvini tahrirlash'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mb-4 d-flex justify-content-center">
                    <Form.Check
                        inline
                        type="radio"
                        name="saleType"
                        id="productSale"
                        label="Mahsulot sotuvi"
                        checked={selectedType === 'product'}
                        onChange={() => handleTypeSelect('product')}
                    />
                    <Form.Check
                        inline
                        type="radio"
                        name="saleType"
                        id="materialSale"
                        label="Material sotuvi"
                        checked={selectedType === 'material'}
                        onChange={() => handleTypeSelect('material')}
                    />
                </div>
                {selectedType === 'product' ? (
                    <ProductSaleUpdateForm show={show} handleClose={handleClose} />
                ) : (
                    <MaterialSaleUpdateForm show={show} handleClose={handleClose} />
                )}
            </Modal.Body>
        </Modal>
    );
};

export default SaleUpdateForm;