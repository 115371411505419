import axios from 'axios';
import {clearAlerts, setAlert} from './alert';
import {
    CLEAR_ERRORS, CLIENT_TRANSACTION_ERROR,
    GET_CLIENT_TRANSACTIONS,
    GET_CLIENTS_TRANSACTIONS,
    SET_LOADING,
    UNSET_LOADING
} from './types';
import {request} from "../utils/service";

export const setLoading = () => (dispatch) => {
    dispatch({
        type: SET_LOADING,
    });
};
export const unsetLoading = () => (dispatch) => {
    dispatch({
        type: UNSET_LOADING,
    });
};

export const updateTransaction = (transaction) => async (dispatch) => {
    dispatch(clearAlerts());
    dispatch(setLoading());
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        await axios.put(`/client-transactions/${transaction.id}`, JSON.stringify(transaction), config);
        dispatch(setAlert("Transaksiya tahrirlandi!", 'success'));
        return true;
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        dispatch(unsetLoading());
        return false;
    }
};

export const getClientsTransactions = (page = 1, filters = {}) => async (dispatch) => {
    dispatch(setLoading());
    dispatch(clearAlerts());
    let queryParams = '';
    if (filters) {
        Object.keys(filters).map((key) => queryParams += `&${key}=${filters[key]}`);
    }
    const res = await request(`/client-transactions?page=${page}${queryParams}`, {method: 'GET'}, dispatch);

    if (res?.hasOwnProperty('errors')) {
        const err = res.errors;
        dispatch(setAlert(err.response.statusText, 'danger'));

    } else {
        dispatch({
            type: GET_CLIENTS_TRANSACTIONS,
            payload: res
        });
        if (res?.clients?.data?.length === 0) {
            dispatch(setAlert("Qidiruv bo'yicha hech narsa topilmadi!", 'warning'));
        }
    }
};

// getRowData after update in table
export const getClientTransactions = (client_id, filters = {}) => async (dispatch) => {
    dispatch(setLoading());
    dispatch(clearAlerts());
    let queryParams = '?';
    if (filters) {
        Object.keys(filters).map((key, index) => index === 0 ? queryParams += `${key}=${filters[key]}` : queryParams += `&${key}=${filters[key]}`);
    }
    const res = await request(`/single-client-transactions/${client_id}${queryParams}`, {method: 'GET'}, dispatch);

    if (res.hasOwnProperty('errors')) {
        const err = res.errors;
        dispatch(setAlert(err.response.statusText, 'danger'));
        dispatch(unsetLoading());
        return res;
    } else {
        dispatch({
            type: GET_CLIENT_TRANSACTIONS,
            payload: res
        });
    }
};


export const addOrUpdateClientTransaction = (formData, filters = {}) => async (dispatch) => {
    dispatch(clearAlerts());
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        formData._method = 'PUT';
        await axios.post('/add-or-update-client-transaction/', formData, config);
        dispatch(setAlert('Tranzaksiya amalga oshirildi!', 'success'));
        dispatch(getClientTransactions(formData.client_id, filters));
        return true;
    } catch (err) {
        dispatch(setAlert("Mijoz balansiga tranzaksiya amalga oshirilmadi!", 'danger'));
        dispatch({
            type: CLIENT_TRANSACTION_ERROR,
            payload: err.response.data,
        });
    }
};


// download report
export const downloadClientReport = (id, filters = {}) => async (dispatch) => {
    dispatch(setLoading());
    dispatch(clearAlerts());
    let queryParams = '?';
    if (filters) {
        Object.keys(filters).map((key, index) => index === 0 ? queryParams += `${key}=${filters[key]}` : queryParams += `&${key}=${filters[key]}`);
    }
    const res = await request(`/clients/${id}/generateTransactionsReport/${queryParams}`, {method: 'GET'}, dispatch);
    if (res?.hasOwnProperty('errors')) {
        const err = res.errors;
        dispatch(setAlert(err.response.statusText, 'danger'));
        dispatch(unsetLoading());
        return false;
    } else {
        dispatch(unsetLoading());
        return res?.link;
    }
};

// download all clients report
export const downloadClientsReport = (filters = {}) => async (dispatch) => {
    dispatch(setLoading());
    dispatch(clearAlerts());
    let queryParams = '?';
    if (filters) {
        Object.keys(filters).map((key, index) => index === 0 ? queryParams += `${key}=${filters[key]}` : queryParams += `&${key}=${filters[key]}`);
    }
    const res = await request(`/client-calculations/generateReport${queryParams}`, {method: 'GET'}, dispatch);
    if (res?.hasOwnProperty('errors')) {
        const err = res.errors;
        dispatch(setAlert(err.response.statusText, 'danger'));
        dispatch(unsetLoading());
        return false;
    } else {
        dispatch(unsetLoading());
        return res?.link;
    }
};

export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS,
    });
};
