import React, {Fragment, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Button from 'react-bootstrap/Button';
import {
    addMaterialImport,
    getMaterialOptions,
    getSupplierOptions,
    clearErrors,
    getWarehouseOptions
} from "../../actions/material";
import {getFirmOptions} from "../../actions/user";
import Select from 'react-select';
import {Input, Label} from "reactstrap";
import { Form} from "react-bootstrap";
import {getParams, getSelectedOption} from "../../utils/hooks/getParams";
import moment from "moment";
import ButtonLoader from "../layout/ButtonLoader";
import { useFormErrors } from "../../utils/hooks/useFormErrors";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../src/styles/datepicker.css";

const MaterialImportForm = ({
                                loading,
                                error,
                                supplier_options,
                                material_options,
                                firm_options,
                                warehouse_options,
                                clearErrors,
                                addMaterialImport,
                                getSupplierOptions,
                                getMaterialOptions,
                                getWarehouseOptions
                            }) => {

    const defaultValues = {
        supplier_id: '',
        material_id: '',
        firm_id: '',
        warehouse_id: '',
        quantity: 0,
        price: 0,
        date: '',
        is_for_special_sale: false,
    };

    const [errors, inputs, setInputs, handleError, resetForm] = useFormErrors(
        error,
        loading,
        clearErrors,
        defaultValues
    );

    useEffect(() => {
        getFirmOptions();
        getSupplierOptions();
    }, [getFirmOptions, getSupplierOptions]);

    const [supplierOptions, setSupplierOptions] = useState([]);
    const [materialOptions, setMaterialOptions] = useState([]);
    const [firmOptions, setFirmOptions] = useState([]);
    const [warehouseOptions, setWarehouseOptions] = useState([]);

    const formRef = useRef();

    useEffect(() => {
        let options = [];
        supplier_options?.forEach((item) => options.push({
            label: item?.name,
            value: item?.id
        }));
        setSupplierOptions(options);
    }, [supplier_options])

    useEffect(() => {

        let options = [];
        firm_options.forEach((item) => options.push({
            label: item.name,
            value: item.id
        }));
        setFirmOptions(options);
    }, [firm_options]);

    useEffect(() => {
        let options = [];
        material_options.forEach((item) => options.push({
            label: item.name,
            value: item.id
        }));
        setMaterialOptions(options);
    }, [material_options]);

    useEffect(() => {
        let options = [];
        warehouse_options?.forEach((item) => options.push({
            label: item.name,
            value: item.id
        }));
        setWarehouseOptions(options);
    }, [warehouse_options]);


    const handleChange = e => {
        if (e.target.name === 'quantity' && parseFloat(e.target.value) < 0) {
            e.target.value = Number(e.target.value) * -1;
        }
        if (e.target.name === 'price' && parseFloat(e.target.value) < 0) {
            e.target.value = Number(e.target.value) * -1;
        }
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}));
    }

    const handleDateChange = (date, name) => {
        const formattedDate = moment(date).format('YYYY-MM-DD');
        handleChange({ target: { name, value: formattedDate } });
    };

    const handleSelect = (data, type) => {
        if (type.name === 'firm_id') {
            getWarehouseOptions({firm_id: data.value});
        }

        if (type.name === 'warehouse_id') {
            getMaterialOptions({warehouse_id: data.value, firm_id: inputs.firm_id});
        }
        setInputs(prevState => ({...prevState, [type.name]: data.value}))
    };

    const onBoolTooggle = (field) => {
        setInputs(prevState => ({...prevState, [field]: !prevState[field]}))
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const params = getParams(true);
        const formData = {
            ...inputs,
            quantity: Number(inputs.quantity),
            price: Number(inputs.price)
        };
        await addMaterialImport(formData, params);
    };


    useEffect(() => {
        if (error) {
            handleError(error);
        } else if (loading === false && !error) {
            clearErrors();
            resetForm();
        }
    }, [error, loading]);

    return (
        <Fragment>

            <div className="col-12">
                {loading}
                <Form onSubmit={handleSubmit} ref={formRef}>
                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <Label className="d-block">
                                Yatkazuvchi(поставщик)
                            </Label>
                            <Select
                                name={'supplier_id'}
                                options={supplierOptions}
                                value={getSelectedOption(inputs?.supplier_id, supplierOptions)}
                                onChange={handleSelect}
                                defaultValue={{label: 'Tanlang', value: ''}}
                            />
                            <div className="error">
                                {errors?.supplier_id}
                            </div>
                        </div>

                        <div className="col-md-6 mb-2">
                            <label htmlFor="date" className="d-block">Sana</label>
                            <DatePicker
                                name="date"
                                selected={inputs?.date ? moment(inputs.date, 'YYYY-MM-DD').toDate() : null}
                                onChange={(date) => handleDateChange(date, 'date')}
                                dateFormat="dd/MM/yyyy"
                                className="form-control"
                                placeholderText="DD/MM/YYYY"
                                wrapperClassName='w-100'
                                maxDate={new Date()}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                            />
                            <div className="error">
                                {errors?.date}
                            </div>
                        </div>
                        
                        <div className="col-md-6 mb-2">
                            <Label className="d-block">
                                Material Firma
                            </Label>
                            <Select
                                name={'firm_id'}
                                options={firmOptions}
                                onChange={handleSelect}
                                value={getSelectedOption(inputs?.firm_id, firmOptions)}
                                required
                                defaultValue={{label: 'Tanlang', value: ''}}
                            />
                        </div>

                        <div className="col-md-6 mb-2">
                            <Label className="d-block">
                                Ombor
                            </Label>
                            <Select
                                name={'warehouse_id'}
                                options={warehouseOptions}
                                onChange={handleSelect}
                                value={getSelectedOption(inputs?.warehouse_id, warehouseOptions)}
                                required
                                defaultValue={{label: 'Tanlang', value: ''}}
                            />
                            <div className="error">
                                {errors?.warehouse_id}
                            </div>
                        </div>

                        <div className="col-md-6 mb-2">
                            <label htmlFor="material_id" className="d-block">Material</label>
                            <Select
                                name={'material_id'}
                                options={materialOptions}
                                value={getSelectedOption(inputs?.material_id, materialOptions)}
                                onChange={handleSelect}
                                defaultValue={{label: 'Tanlang', value: ''}}
                            />
                            <div className="error">
                                {errors?.material_id}
                            </div>
                        </div>

                        <div className="col-md-6 mb-2">
                            <label htmlFor="quantity" className="d-block">Miqdori</label>
                            <Input
                                name="quantity"
                                value={inputs.quantity || ''}
                                type="number"
                                step="0.000001"
                                min="0"
                                placeholder={1000}
                                onChange={(e) => {
                                    const value = e.target.value.replace(/[^0-9.]/g, '');
                                    // Ensure only one decimal point
                                    const parts = value.split('.');
                                    const sanitizedValue = parts.length > 2 
                                        ? `${parts[0]}.${parts.slice(1).join('')}`
                                        : value;
                                    handleChange({
                                        target: {
                                            name: 'quantity',
                                            value: sanitizedValue
                                        }
                                    });
                                }}
                                onKeyPress={(e) => {
                                    if (!/[0-9.]/.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                                required
                            />
                            <div className="error">
                                {errors?.quantity}
                            </div>
                        </div>


                        {
                            !inputs.is_for_special_sale &&
                            <div className="col-md-6 mb-2" >
                                <label htmlFor="price" className="d-block">Narxi</label>
                                <Input
                                    name="price"
                                    value={inputs.price || ''}
                                    type="number"
                                    placeholder={1000}
                                    onChange={handleChange}
                                    required
                                />
                                <div className="error">
                                    {errors?.price}
                                </div>
                            </div>
                        }

                        <div className="col-12 mb-2">
                            <div className="form-check form-switch">
                                <input className="form-check-input"
                                    type="checkbox"
                                    id="flexSwitchCheckChecked"
                                    onChange={() => onBoolTooggle('is_for_special_sale')}
                                    checked={inputs.is_for_special_sale}
                                />
                                <label className="form-check-label" htmlFor="flexSwitchCheckChecked">
                                    Maxsus sotuv uchun
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="mt-3">
                        <Button
                            variant="primary"
                            type="submit"
                            disabled={loading}
                        >
                            {loading ? <ButtonLoader/> : "Saqlash"}
                        </Button>
                    </div>
                </Form>

            </div>
        </Fragment>
    );
};

MaterialImportForm.propTypes = {
    addMaterialImport: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
    getSupplierOptions: PropTypes.func.isRequired,
    getMaterialOptions: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    material: state.material,
    materials: state.material.materials,
    error: state.material.error,
    loading: state.material.loading,
    material_units: state.material.material_units,
    supplier_options: state.material.supplier_options,
    material_options: state.material.material_options,
    firm_options: state.user.firm_options,
    warehouse_options: state.material.material_warehouses_options,
});

export default connect(mapStateToProps, {
    addMaterialImport,
    clearErrors,
    getSupplierOptions,
    getMaterialOptions,
    getFirmOptions,
    getWarehouseOptions,
})(MaterialImportForm);
