import React, {Fragment, useState} from 'react';
import {useSelector} from 'react-redux';
import SaleDeleteForm from "./SaleDeleteForm";
import SaleUpdateForm from "./SaleUpdateForm";
import SaleItem from "./SaleItem";
import SaleCreateForm from "./SaleCreateForm";
import {getSales} from "../../actions/sale";
import FilterByFirm from "../filters/FilterByFirm";
import FilterByClient from "./FilterByClient";
import FilterByProduct from "./FilterByProduct";
import FilterIsSpecialSale from "../filters/FilterIsSpecialSale"; 
import SearchForm from "../layout/SearchForm";
import {Plus} from "react-feather";
import TableSpinner from "../layout/TableSpinner";
import CustomPaginationWithManyPages from "../layout/CustomPaginationWithManyPages";
import FilterByDateRange from "../layout/FilterByDateRange";
import FilterBySaleType from "../filters/FilterBySaleType";

const Sales = () => {

    const {sales, loading, numberOfPages, currentPage, total_sales} = useSelector(state => state.sale);

    const [showCreate, setShowCreate] = useState(false);

    const handleCreateClose = () => setShowCreate(false);
    const handleCreateShow = () => setShowCreate(true);

    const [showUpdate, setShowUpdate] = useState(false);

    const handleUpdateClose = () => setShowUpdate(false);
    const handleUpdateShow = () => {
        showLoader()
        setShowUpdate(true)
    };

    const [isLoading, setIsLoading] = useState(loading ?? false);

    const showLoader = () => setIsLoading(true)
    const hideLoader = () => setIsLoading(false)
    return (
        <Fragment>
            <div className="container-fluid p-0">

                <div className="row mb-2">
                    <div className="col-md-9 ">
                        <h1 className="h3 mb-3"><strong></strong> sotuv bo'limi ({total_sales}) </h1>
                    </div>

                    <div className="col-md-3 justify-content-end">
                        <div className="crud_actions text-end">
                            <button className="btn btn-primary" onClick={handleCreateShow}>
                                Sotuv <Plus/>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-3">
                        <FilterByDateRange getPageItems={getSales}/>
                    </div>
                    <div className="col-md-3">
                        <FilterByFirm getPageItems={getSales}/>
                    </div>
                    <div className="col-md-3">
                        <FilterByProduct getPageItems={getSales}/>
                    </div>

                    <div className="col-md-3">
                        <SearchForm getPageItems={getSales}/>
                    </div>

                    <div className="col-md-3">
                        <FilterByClient getPageItems={getSales}/>
                    </div>
                    <div className="col-md-3">
                        <FilterIsSpecialSale getPageItems={getSales}/>
                    </div>
                    <div className="col-md-3">
                        <FilterBySaleType getPageItems={getSales}/>
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-xl-12 col-xxl-12 d-flex">

                        <div className="card w-100 table-responsive">
                            {loading && (<TableSpinner/>)}
                            <table className="table custom-table table-hover">
                                <thead className="table-light">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Mahsulotlar</th>
                                    <th scope="col">Sotuvchi</th>
                                    <th scope="col">Client</th>
                                    <th scope="col">Summa</th>
                                    <th scope="col">Sana</th>
                                    <th scope="col">Amal vaqti</th>
                                    <th scope="col">Turi</th>
                                    <th scope="col">Maxsus sotuvmi?</th>
                                    <th scope="col">Amallar</th>
                                </tr>
                                </thead>
                                <tbody>
                                {sales.map((item, index) => (
                                    <SaleItem key={`sale${item.id}`} sale={item} number={index + 1} handleShow={handleUpdateShow}/>
                                ))}
                                </tbody>
                            </table>

                        </div>

                    </div>

                </div>

                <div className="row">
                    <div className="col-xl-12 col-xxl-12 d-flex">
                        <CustomPaginationWithManyPages
                            numberOfPages={numberOfPages}
                            getPageItems={getSales}
                            current_page={currentPage}
                        />
                    </div>
                </div>

            </div>


            <SaleCreateForm show={showCreate} handleShow={handleCreateShow} handleClose={handleCreateClose}/>

            <SaleDeleteForm/>

            <SaleUpdateForm show={showUpdate}
                            handleShow={handleUpdateShow}
                            handleClose={handleUpdateClose}
                            isLoading={isLoading} showLoader={showLoader} hideLoader={hideLoader}/>


        </Fragment>
    );
};

export default Sales;
