import React from 'react';
import {Link} from "react-router-dom";
import moment from "moment";
import MoneyFormat from "../layout/MoneyFormat";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faEye } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import {connect} from 'react-redux';
import {setDeleteSaleId, setUpdateSaleId, getSale} from "../../actions/sale";

const SaleItem = ({
    sale,
    auth,
    setDeleteSaleId,
    setUpdateSaleId,
    getSale,
    handleShow
}) => {
    const formatDate = (dateString) => {
        return moment(dateString).format('DD.MM.YYYY HH:mm');
    };

    const getSaleType = (type) => {
        switch(type) {
            case 'product':
                return 'Mahsulot';
            case 'material':
                return 'Material';
            default:
                return type;
        }
    };

    const {role_name} = auth?.user || {};

    const setDeleteSale = (id) => {
        setDeleteSaleId(id);
    };

    const setUpdateSale = async (id) => {
        await getSale(id);
        handleShow();
        setUpdateSaleId(id);
    };

    return (
        <tr>
            <td>{sale.id}</td>
            <td>
                {sale.items?.map((item, index) => (
                    <div key={index}>
                        {parseFloat(item.quantity)} X {item.sellable?.name}
                    </div>
                ))}
            </td>
            <td>{sale.seller?.name}</td>
            <td>
                <Link to={`/clients/${sale.client?.id}`}>
                    {sale.client?.name}
                </Link>
            </td>
            <td><MoneyFormat value={sale.sum}/></td>
            <td>{sale.date}</td>
            <td>{formatDate(sale.created_at)}</td>
            <td>{getSaleType(sale.type)}</td>
            <td>{sale.is_special_sale ? 'Ha' : 'Yoq'}</td>
            <td>
                <div className="d-flex gap-2">
                    <Link to={`/sales/${sale.id}`}>
                        <Button
                            variant="outline-secondary"
                            size="sm"
                            className="d-flex align-items-center justify-content-center"
                            style={{ width: '32px', height: '32px', padding: 0 }}
                        >
                            <FontAwesomeIcon icon={faEye} />
                        </Button>
                    </Link>
                    {
                        (role_name === 'superadmin' || role_name === 'director' || role_name === 'manager' || role_name === 'storekeeper') && (
                            <Button
                                variant="outline-primary"
                                size="sm"
                                className="d-flex align-items-center justify-content-center"
                                style={{ width: '32px', height: '32px', padding: 0 }}
                                onClick={() => setUpdateSale(sale.id)}
                            >
                                <FontAwesomeIcon icon={faEdit} />
                            </Button>
                        )
                    }
                    {
                        (role_name === 'superadmin' || role_name === 'director') && (
                            <Button
                                variant="outline-danger"
                                size="sm"
                                className="d-flex align-items-center justify-content-center"
                                style={{ width: '32px', height: '32px', padding: 0 }}
                                onClick={() => setDeleteSale(sale.id)}
                            >
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        )
                    }
                </div>
            </td>
        </tr>
    );
};

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {setDeleteSaleId, setUpdateSaleId, getSale})(SaleItem);
