import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import {
    updateSale,
    getClientOptions,
} from '../../../actions/sale';
import { getFirmOptions } from '../../../actions/user';
import { getMaterialOptions } from '../../../actions/material';
import { getWarehouseOptions } from '../../../actions/product';

const MaterialSaleUpdateForm = ({ onClose }) => {
    const dispatch = useDispatch();
    const { sale, user, product, material } = useSelector(state => state);
    const {
        client_options,
        error,
        loading,
        update_sale: initialSale,
        show
    } = sale;
    const { firm_options } = user;
    const { warehouse_options } = product;
    const { material_options } = material;

    const defaultValues = {
        is_special_sale: false,
        firm_id: '',
        client_id: '',
        warehouse_id: '',
        date: moment().format('YYYY-MM-DD'),
        transport_number: '',
        driver_name: '',
        description: '',
        items: []
    };

    const [inputs, setInputs] = useState(defaultValues);
    const [errors, setErrors] = useState({});
    const [firmOptions, setFirmOptions] = useState([]);
    const [warehouseOptions, setWarehouseOptions] = useState([]);
    const [materialOptions, setMaterialOptions] = useState([]);
    const [selectedMaterialOptions, setSelectedMaterialOptions] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);
    const [newMaterial, setNewMaterial] = useState(null);

    useEffect(() => {
        if (initialSale) {
            setInputs({
                firm_id: initialSale.firm_id,
                client_id: initialSale.client_id,
                warehouse_id: initialSale.warehouse_id,
                date: moment(initialSale.date, 'DD.MM.YYYY').format('YYYY-MM-DD'),
                transport_number: initialSale.transport_number,
                driver_name: initialSale.driver_name,
                description: initialSale.description,
                items: initialSale.items || []
            });

            if (initialSale.items) {
                const selectedMaterials = initialSale.items.map(item => ({
                    id: item.sellable_id,
                    name: item.name,
                    quantity: item.quantity,
                    price: item.price,
                    net_price: item.net_price,
                    in_stock: item.sellable?.in_stock || item.in_stock,
                    unit: item.unit,
                    unit_alternative: item.unit_alternative
                }));
                setSelectedMaterialOptions(selectedMaterials);
            }
        }
    }, [initialSale]);

    useEffect(() => {
        dispatch(getClientOptions());
        dispatch(getFirmOptions());
    }, []);

    useEffect(() => {
        if (inputs.firm_id) {
            dispatch(getWarehouseOptions({ firm_id: inputs.firm_id }));
        }
    }, [inputs.firm_id]);

    useEffect(() => {
        if (inputs.warehouse_id) {
            dispatch(getMaterialOptions({ 
                is_for_sale: true,
                warehouse_id: inputs.warehouse_id 
            }));
        }
    }, [inputs.warehouse_id]);

    useEffect(() => {
        let items = [];
        firm_options?.forEach((item) => items.push({
            label: item.name,
            value: item.id
        }));
        setFirmOptions(items);
    }, [firm_options]);

    useEffect(() => {
        let options = [];
        warehouse_options?.forEach((item) => options.push({
            label: item.name,
            value: item.id
        }));
        setWarehouseOptions(options);
    }, [warehouse_options]);

    useEffect(() => {
        let options = [];
        client_options?.forEach((item) => options.push({
            label: item.name,
            value: item.id
        }));
        setClientOptions(options);
    }, [client_options]);

    useEffect(() => {
        if (material_options?.length) {
            const options = material_options.map(material => ({
                id: material.id,
                name: material.name,
                net_price: material.net_price,
                in_stock: material.in_stock,
                unit: material.unit,
                unit_alternative: material.unit_alternative
            }));
            setMaterialOptions(options);

            // Update in_stock values for existing selected materials
            setSelectedMaterialOptions(prev => prev.map(selected => {
                const updatedMaterial = material_options.find(m => m.id === selected.id);
                if (updatedMaterial) {
                    return {
                        ...selected,
                        in_stock: updatedMaterial.in_stock
                    };
                }
                return selected;
            }));
        }
    }, [material_options]);

    const handleError = (errors) => {
        setErrors(errors);
    };

    const clearErrors = () => {
        setErrors({});
    };

    const handleAddMaterial = () => {
        if (newMaterial) {
            const materialToAdd = materialOptions.find(m => m.id === newMaterial.value);
            if (materialToAdd) {
                const exists = selectedMaterialOptions.some(m => m.id === materialToAdd.id);
                if (!exists) {
                    const newMaterialOption = {
                        id: materialToAdd.id,
                        name: materialToAdd.name,
                        quantity: 0,
                        price: materialToAdd.net_price,
                        net_price: materialToAdd.net_price,
                        in_stock: materialToAdd.in_stock,
                        unit: materialToAdd.unit,
                        unit_alternative: materialToAdd.unit_alternative
                    };
                    setSelectedMaterialOptions([...selectedMaterialOptions, newMaterialOption]);
                }
            }
            setNewMaterial(null);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const validationErrors = {};

        if (!inputs.firm_id) {
            validationErrors.firm_id = ["Firma tanlash majburiy"];
        }

        if (!inputs.client_id) {
            validationErrors.client_id = ["Mijoz tanlash majburiy"];
        }

        if (!inputs.warehouse_id) {
            validationErrors.warehouse_id = ["Ombor tanlash majburiy"];
        }

        if (!inputs.transport_number) {
            validationErrors.transport_number = ["Transport raqami majburiy"];
        }

        if (!inputs.driver_name) {
            validationErrors.driver_name = ["Haydovchi ismi majburiy"];
        }

        if (!selectedMaterialOptions.length) {
            validationErrors.items = ["Materiallar tanlash majburiy"];
        }

        if (Object.keys(validationErrors).length > 0) {
            handleError(validationErrors);
            return;
        }

        const data = {
            client_id: parseInt(inputs.client_id),
            firm_id: parseInt(inputs.firm_id),
            warehouse_id: parseInt(inputs.warehouse_id),
            date: inputs.date,
            transport_number: inputs.transport_number,
            driver_name: inputs.driver_name,
            description: inputs.description,
            comment: inputs.description,
            items: selectedMaterialOptions.map(item => ({
                sellable_type: 'App\\Models\\Material',
                sellable_id: parseInt(item.id),
                quantity: parseFloat(item.quantity),
                price: parseFloat(item.price)
            }))
        };

        try {
            const response = await dispatch(updateSale(initialSale.id, data));
            if (response) {
                onClose();
            }
        } catch (err) {
            if (err.errors) {
                handleError(err.errors);
            }
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="row g-3">
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Firma</label>
                        <Select
                            value={firmOptions.find(option => option.value === inputs.firm_id)}
                            onChange={(option) => setInputs({ ...inputs, firm_id: option.value })}
                            options={firmOptions}
                            placeholder="Firma tanlang"
                        />
                        {errors.firm_id && (
                            <div className="invalid-feedback d-block">{errors.firm_id[0]}</div>
                        )}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Ombor</label>
                        <Select
                            value={warehouseOptions.find(option => option.value === inputs.warehouse_id)}
                            onChange={(option) => setInputs({ ...inputs, warehouse_id: option.value })}
                            options={warehouseOptions}
                            placeholder="Ombor tanlang"
                        />
                        {errors.warehouse_id && (
                            <div className="invalid-feedback d-block">{errors.warehouse_id[0]}</div>
                        )}
                    </div>
                </div>
            </div>

            <div className="row g-3 mt-2">
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Mijoz</label>
                        <Select
                            value={clientOptions.find(option => option.value === inputs.client_id)}
                            onChange={(option) => setInputs({ ...inputs, client_id: option.value })}
                            options={clientOptions}
                            placeholder="Mijoz tanlang"
                        />
                        {errors.client_id && (
                            <div className="invalid-feedback d-block">{errors.client_id[0]}</div>
                        )}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Sana</label>
                        <input
                            type="date"
                            className="form-control"
                            value={inputs.date}
                            max={moment().format('YYYY-MM-DD')}
                            onChange={(e) => setInputs({ ...inputs, date: e.target.value })}
                        />
                    </div>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-12">
                    <div className="form-group">
                        <label>Material qo'shish</label>
                        <div className="d-flex gap-2">
                            <div className="flex-grow-1">
                                <Select
                                    value={newMaterial}
                                    onChange={setNewMaterial}
                                    options={materialOptions.map(m => ({
                                        value: m.id,
                                        label: m.name
                                    }))}
                                    placeholder="Material tanlang"
                                />
                            </div>
                            <Button 
                                variant="primary" 
                                type="button" 
                                onClick={handleAddMaterial}
                                disabled={!newMaterial}
                            >
                                Qo'shish
                            </Button>
                        </div>
                        {errors.items && (
                            <div className="invalid-feedback d-block">{errors.items[0]}</div>
                        )}
                    </div>
                </div>
            </div>

            {selectedMaterialOptions.length > 0 && (
                <div className="mt-3">
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Material nomi</th>
                                    <th>Qoldiq</th>
                                    <th>Tan narxi</th>
                                    <th>Miqdori</th>
                                    <th>Narxi</th>
                                    <th>Jami</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedMaterialOptions.map((material, index) => (
                                    <tr key={material.id}>
                                        <td>{material.name}</td>
                                        <td>{material.in_stock?.toLocaleString('uz-UZ', { maximumFractionDigits: 2 })}</td>
                                        <td>{material.net_price?.toLocaleString('uz-UZ')}</td>
                                        <td>
                                            <input
                                                type="number"
                                                className="form-control form-control-sm"
                                                value={material.quantity || ''}
                                                onChange={(e) => {
                                                    const newMaterials = [...selectedMaterialOptions];
                                                    newMaterials[index] = {
                                                        ...newMaterials[index],
                                                        quantity: parseFloat(e.target.value) || ''
                                                    };
                                                    setSelectedMaterialOptions(newMaterials);
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                className="form-control form-control-sm"
                                                value={material.price || ''}
                                                onChange={(e) => {
                                                    const newMaterials = [...selectedMaterialOptions];
                                                    newMaterials[index] = {
                                                        ...newMaterials[index],
                                                        price: parseFloat(e.target.value) || ''
                                                    };
                                                    setSelectedMaterialOptions(newMaterials);
                                                }}
                                            />
                                        </td>
                                        <td>
                                            {material.quantity && material.price
                                                ? Math.floor(material.quantity * material.price).toLocaleString('uz-UZ')
                                                : '0'}
                                        </td>
                                        <td>
                                            <button
                                                type="button"
                                                className="btn btn-danger btn-sm"
                                                onClick={() => {
                                                    const newMaterials = selectedMaterialOptions.filter((_, i) => i !== index);
                                                    setSelectedMaterialOptions(newMaterials);
                                                }}
                                            >
                                                ×
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}

            <div className="row g-3 mt-3">
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Transport raqami</label>
                        <input
                            type="text"
                            className="form-control"
                            value={inputs.transport_number}
                            onChange={(e) => setInputs({ ...inputs, transport_number: e.target.value })}
                        />
                        {errors.transport_number && (
                            <div className="invalid-feedback d-block">{errors.transport_number[0]}</div>
                        )}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Haydovchi</label>
                        <input
                            type="text"
                            className="form-control"
                            value={inputs.driver_name}
                            onChange={(e) => setInputs({ ...inputs, driver_name: e.target.value })}
                        />
                        {errors.driver_name && (
                            <div className="invalid-feedback d-block">{errors.driver_name[0]}</div>
                        )}
                    </div>
                </div>
            </div>

            <div className="form-group mt-3">
                <label>Izoh</label>
                <textarea
                    className="form-control"
                    value={inputs.description}
                    onChange={(e) => setInputs({ ...inputs, description: e.target.value })}
                    rows="3"
                />
            </div>

            <div className="mt-4 d-flex justify-content-end gap-2">
                <Button variant="secondary" onClick={onClose}>Bekor qilish</Button>
                <Button type="submit" variant="primary">Saqlash</Button>
            </div>
        </form>
    );
};

export default MaterialSaleUpdateForm;