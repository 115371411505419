import React from 'react';
import PropTypes from 'prop-types';
import { Briefcase, Users, Truck, DollarSign } from 'react-feather';

const StatisticsBalansCards = ({ statistics, selectedYear, onYearChange }) => {
    // Format number to show in millions with proper suffix
    const formatNumber = (num) => {
        const absNum = Math.abs(num);
        if (absNum >= 1000000) {
            return (num / 1000000).toFixed(1) + ' mln';
        } else if (absNum >= 1000) {
            return (num / 1000).toFixed(1) + ' ming';
        }
        return num.toString();
    };

    // Get years from API data or use empty array as fallback
    const years = statistics?.data?.years || [];

    const cards = [
        {
            title: 'Umumiy balans',
            value: formatNumber(statistics?.data?.balance || 0),
            icon: Briefcase,
            color: statistics?.data?.balance >= 0 ? 'success' : 'danger',
            prefix: '',
            suffix: ''
        },
        {
            title: 'Harajatlar',
            value: formatNumber(statistics?.data?.expenses || 0),
            icon: DollarSign,
            color: 'danger',
            prefix: '',
            suffix: ''
        },
        {
            title: 'Mijozlar balansi',
            value: (
                <div>
                    <div className="h5 mb-0">{formatNumber(statistics?.data?.client?.balance || 0)}</div>
                    <div className="small text-muted mt-1" style={{ fontSize: '0.75rem', opacity: 0.8 }}>
                        <span className="text-success me-1">+{formatNumber(Number(statistics?.data?.client?.incomes) || 0)}</span>
                        <span className="opacity-50">/</span>
                        <span className="text-danger ms-1">-{formatNumber(Number(statistics?.data?.client?.expenses) || 0)}</span>
                    </div>
                </div>
            ),
            icon: Users,
            color: statistics?.data?.client?.balance >= 0 ? 'success' : 'danger',
            prefix: '',
            suffix: ''
        },
        {
            title: 'Yetkazuvchilar balansi',
            value: (
                <div>
                    <div className="h5 mb-0">{formatNumber(statistics?.data?.supplier?.balance || 0)}</div>
                    <div className="small text-muted mt-1" style={{ fontSize: '0.75rem', opacity: 0.8 }}>
                        <span className="text-success me-1">+{formatNumber(Number(statistics?.data?.supplier?.incomes) || 0)}</span>
                        <span className="opacity-50">/</span>
                        <span className="text-danger ms-1">-{formatNumber(Number(statistics?.data?.supplier?.expenses) || 0)}</span>
                    </div>
                </div>
            ),
            icon: Truck,
            color: statistics?.data?.supplier?.balance >= 0 ? 'success' : 'danger',
            prefix: '',
            suffix: ''
        }
    ];

    return (
        <>
            <div className="row mb-3 justify-content-end">
                <div className="col-auto">
                    <select 
                        className="form-select"
                        value={selectedYear}
                        onChange={(e) => onYearChange(e.target.value)}
                    >
                        <option value="all">Barcha yillar</option>
                        {years.map(year => (
                            <option key={year} value={year}>{year}</option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="row">
                {cards.map((card, index) => (
                    <div key={index} className="col-12 col-sm-6 col-xl-3 mb-4">
                        <div className={`card border-${card.color}`}>
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div className="flex-shrink-0">
                                        <card.icon className={`feather feather-${card.icon} text-${card.color}`} />
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                        <h5 className="card-title mb-0">{card.title}</h5>
                                        <h3 className="mt-2 mb-0">
                                            {card.prefix}{card.value}{card.suffix}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

StatisticsBalansCards.propTypes = {
    statistics: PropTypes.object.isRequired,
    selectedYear: PropTypes.string.isRequired,
    onYearChange: PropTypes.func.isRequired
};

export default StatisticsBalansCards;
