import axios from 'axios';
import {
    GET_DASHBOARD_STATISTICS,
    GET_DASHBOARD_STATISTICS_ERROR,
    DASHBOARD_LOADING,
    GET_MONTHLY_EXPENSES,
    GET_MONTHLY_EXPENSES_ERROR,
    MONTHLY_EXPENSES_LOADING,
    GET_MONTHLY_SALES,
    GET_MONTHLY_SALES_ERROR,
    MONTHLY_SALES_LOADING,
    GET_CLIENT_TRANSACTIONS_CHART,
    GET_CLIENT_TRANSACTIONS_CHART_ERROR,
    CLIENT_TRANSACTIONS_CHART_LOADING,
    GET_SUPPLIER_TRANSACTIONS_CHART,
    GET_SUPPLIER_TRANSACTIONS_CHART_ERROR,
    SUPPLIER_TRANSACTIONS_CHART_LOADING,
    GET_TOP_PRODUCTS_CHART,
    GET_TOP_PRODUCTS_CHART_ERROR,
    TOP_PRODUCTS_CHART_LOADING,
    GET_TOP_MATERIALS_CHART,
    GET_TOP_MATERIALS_CHART_ERROR,
    TOP_MATERIALS_CHART_LOADING,
    SET_GENERAL_STATISTICS_MONTH,
    GET_GENERAL_CLIENT_TRANSACTIONS,
    GET_GENERAL_CLIENT_TRANSACTIONS_ERROR,
    GENERAL_CLIENT_TRANSACTIONS_LOADING,
    GET_GENERAL_SUPPLIER_TRANSACTIONS,
    GET_GENERAL_SUPPLIER_TRANSACTIONS_ERROR,
    GENERAL_SUPPLIER_TRANSACTIONS_LOADING
} from './types';

const formatMonthParam = (params) => {
    const currentDate = new Date();
    const defaultMonth = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}`;
    
    return params?.year && params?.month 
        ? `${params.year}-${String(params.month).padStart(2, '0')}`
        : defaultMonth;
};

// Get dashboard statistics
export const getDashboardStatistics = (params) => async dispatch => {
    try {
        dispatch({ type: DASHBOARD_LOADING });

        const res = await axios.get('/dashboard/statistics', { 
            params: {...params}
        });

        dispatch({
            type: GET_DASHBOARD_STATISTICS,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: GET_DASHBOARD_STATISTICS_ERROR,
            payload: { msg: err.response?.statusText, status: err.response?.status }
        });
    }
};

// Get monthly expenses data
export const getMonthlyExpenses = (params) => async dispatch => {
    try {
        dispatch({ type: MONTHLY_EXPENSES_LOADING });

        const res = await axios.get('/dashboard/monthly-expenses', { 
            params: {
                month: formatMonthParam(params),
                firm_id: params?.firm_id
            }
        });

        dispatch({
            type: GET_MONTHLY_EXPENSES,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: GET_MONTHLY_EXPENSES_ERROR,
            payload: { msg: err.response?.statusText, status: err.response?.status }
        });
    }
};

// Get monthly sales data
export const getMonthlySales = (params) => async dispatch => {
    try {
        dispatch({ type: MONTHLY_SALES_LOADING });

        const res = await axios.get('/dashboard/monthly-sales', { 
            params: {
                month: formatMonthParam(params),
                firm_id: params?.firm_id
            }
        });

        dispatch({
            type: GET_MONTHLY_SALES,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: GET_MONTHLY_SALES_ERROR,
            payload: { msg: err.response?.statusText, status: err.response?.status }
        });
    }
};

// Get client transactions data
export const getClientTransactionsChart = (params) => async dispatch => {
    try {
        dispatch({ type: CLIENT_TRANSACTIONS_CHART_LOADING });

        const res = await axios.get('/dashboard/client-transactions-chart', { 
            params: {
                month: formatMonthParam(params),
                firm_id: params?.firm_id
            }
        });

        dispatch({
            type: GET_CLIENT_TRANSACTIONS_CHART,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: GET_CLIENT_TRANSACTIONS_CHART_ERROR,
            payload: { msg: err.response?.statusText, status: err.response?.status }
        });
    }
};

// Get supplier transactions data
export const getSupplierTransactionsChart = (params) => async dispatch => {
    try {
        dispatch({ type: SUPPLIER_TRANSACTIONS_CHART_LOADING });

        const res = await axios.get('/dashboard/supplier-transactions-chart', { 
            params: {
                month: formatMonthParam(params),
                firm_id: params?.firm_id
            }
        });

        dispatch({
            type: GET_SUPPLIER_TRANSACTIONS_CHART,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: GET_SUPPLIER_TRANSACTIONS_CHART_ERROR,
            payload: { msg: err.response?.statusText, status: err.response?.status }
        });
    }
};

// Get top products data
export const getTopProductsChart = (params) => async dispatch => {
    try {
        dispatch({ type: TOP_PRODUCTS_CHART_LOADING });

        const res = await axios.get('/dashboard/top-products-chart', { 
            params: {
                month: formatMonthParam(params),
                firm_id: params?.firm_id
            }
        });

        dispatch({
            type: GET_TOP_PRODUCTS_CHART,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: GET_TOP_PRODUCTS_CHART_ERROR,
            payload: { msg: err.response?.statusText, status: err.response?.status }
        });
    }
};

// Get top materials data
export const getTopMaterialsChart = (params) => async dispatch => {
    try {
        dispatch({ type: TOP_MATERIALS_CHART_LOADING });

        const res = await axios.get('/dashboard/top-materials-chart', { 
            params: {
                month: formatMonthParam(params),
                firm_id: params?.firm_id
            }
        });

        dispatch({
            type: GET_TOP_MATERIALS_CHART,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: GET_TOP_MATERIALS_CHART_ERROR,
            payload: { msg: err.response?.statusText, status: err.response?.status }
        });
    }
};

// Set general statistics month
export const setGeneralStatisticsMonth = (month) => dispatch => {
    dispatch({
        type: SET_GENERAL_STATISTICS_MONTH,
        payload: month
    });
};

// Get general client transactions data
export const getGeneralClientTransactions = (month) => async dispatch => {
    try {
        dispatch({ type: GENERAL_CLIENT_TRANSACTIONS_LOADING });

        const res = await axios.get('/dashboard/client-transactions-chart', { 
            params: {
                month: formatMonthParam(month)
            }
        });

        dispatch({
            type: GET_GENERAL_CLIENT_TRANSACTIONS,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: GET_GENERAL_CLIENT_TRANSACTIONS_ERROR,
            payload: { msg: err.response?.statusText, status: err.response?.status }
        });
    }
};

// Get general supplier transactions data
export const getGeneralSupplierTransactions = (month) => async dispatch => {
    try {
        dispatch({ type: GENERAL_SUPPLIER_TRANSACTIONS_LOADING });

        const res = await axios.get('/dashboard/supplier-transactions-chart', { 
            params: {
                month: formatMonthParam(month)
            }
        });

        dispatch({
            type: GET_GENERAL_SUPPLIER_TRANSACTIONS,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: GET_GENERAL_SUPPLIER_TRANSACTIONS_ERROR,
            payload: { msg: err.response?.statusText, status: err.response?.status }
        });
    }
};
