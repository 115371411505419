import axios from 'axios';
import {clearAlerts, setAlert} from './alert';
import {
    ADD_FIRM, CLEAR_ERRORS,
    FIRM_ERROR,
    GET_AVAILABLE_MANAGER_USERS, GET_FIRM,
    GET_FIRMS,
    REMOVE_DELETE_FIRM,
    REMOVE_UPDATE_FIRM,
    SET_DELETE_FIRM_ID,
    SET_UPDATE_FIRM_ID
} from './types';

export const getAvailableManagerUsers = (filters = {}) => async (dispatch) => {
    try {
        const res = await axios.get('/users/?is_available_manager=true', {params: filters});
        dispatch({
            type: GET_AVAILABLE_MANAGER_USERS,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getFirm = (firmID) => async (dispatch) => {
    try {
        const res = await axios.get(`/firms/${firmID}`);
        dispatch({
            type: GET_FIRM,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getFirms = (page = 1, filters = {}) => async (dispatch) => {
    try {
        let queryParams = '';
        if (filters) {
            Object.keys(filters).map((key, index) => queryParams += `&${key}=${filters[key]}`);
        }
        const res = await axios.get(`/firms?page=${page}${queryParams}`);
        dispatch({
            type: GET_FIRMS,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};
export const addFirm = (formData) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.post('/firms/', formData, config);

        dispatch(getFirms());

        dispatch({
            type: ADD_FIRM,
            payload: res.data,
        });

        dispatch(setAlert('Firma yaratildi !', 'success'));

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));

        if (err?.response?.data?.errors) {
            dispatch({
                type: FIRM_ERROR,
                payload: err.response.data,
            });
        } else {
            dispatch(setAlert("Firma yaratilmadi !", 'danger'));
        }
    }
};

export const deleteFirm = (deleteFirmID, getPage) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.delete(`/firms/${deleteFirmID}`, config);

        dispatch({
            type: REMOVE_DELETE_FIRM
        });

        dispatch(getFirms(getPage));

        dispatch(setAlert("Firma o'chirildi !", 'success'));

        dispatch(clearErrors())

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const updateFirm = (updateFirmID, updateformData, getPage) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.put(`/firms/${updateFirmID}`, updateformData, config);

        dispatch({type: REMOVE_UPDATE_FIRM});

        dispatch(getFirms(getPage));

        dispatch(setAlert('Firma  tahrirlandi !', 'success'));
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const setDeleteFirmId = (firmID) => async (dispatch) => {

    try {
        if (firmID !== null) {
            dispatch({
                type: SET_DELETE_FIRM_ID,
                payload: firmID
            });

        } else {
            dispatch({
                type: REMOVE_DELETE_FIRM
            });
        }

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const setUpdateFirmId = (firmID) => async (dispatch) => {

    try {
        if (firmID !== null) {
            dispatch({
                type: SET_UPDATE_FIRM_ID,
                payload: {
                    firmID
                }
            });
        } else dispatch({type: REMOVE_UPDATE_FIRM});

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};


export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS,
    });
};

