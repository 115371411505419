import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getGeneralSupplierTransactions } from '../../actions/dashboard';
import Chart from 'chart.js/auto';

const SupplierTransactionsChart = ({
    dashboard: { generalStatistics },
    getGeneralSupplierTransactions,
    selectedMonth
}) => {
    const chartRef = React.useRef(null);
    const chartInstance = React.useRef(null);

    useEffect(() => {
        getGeneralSupplierTransactions(selectedMonth);
    }, [getGeneralSupplierTransactions, selectedMonth]);

    useEffect(() => {
        if (generalStatistics.loading.supplierTransactions || !generalStatistics.supplierTransactions?.credit) return;

        const ctx = chartRef.current.getContext('2d');

        // Destroy existing chart if it exists
        if (chartInstance.current) {
            chartInstance.current.destroy();
        }

        // Create labels for days of the month
        const daysInMonth = generalStatistics.supplierTransactions.meta.days;
        const labels = Array.from({ length: daysInMonth }, (_, i) => `${i + 1}-kun`);

        // Create new chart
        chartInstance.current = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: labels,
                datasets: [
                    {
                        label: 'Kirim',
                        data: generalStatistics.supplierTransactions.credit,
                        backgroundColor: '#47bac1',
                        borderRadius: 4
                    },
                    {
                        label: 'Chiqim',
                        data: generalStatistics.supplierTransactions.debit,
                        backgroundColor: '#fcb92c',
                        borderRadius: 4
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: true,
                        position: 'top'
                    },
                    tooltip: {
                        callbacks: {
                            label: (context) => {
                                const value = context.raw.toLocaleString('uz-UZ');
                                return `${value} UZS`;
                            }
                        }
                    }
                },
                scales: {
                    y: {
                        beginAtZero: true,
                        ticks: {
                            callback: (value) => {
                                return value.toLocaleString('uz-UZ') + ' UZS';
                            }
                        }
                    },
                    x: {
                        ticks: {
                            maxRotation: 45,
                            minRotation: 45
                        }
                    }
                }
            }
        });

        return () => {
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }
        };
    }, [generalStatistics.supplierTransactions, generalStatistics.loading.supplierTransactions]);

    if (generalStatistics.loading.supplierTransactions) {
        return <div>Loading...</div>;
    }

    const { total_credit, total_debit } = generalStatistics.supplierTransactions?.meta || {};

    return (
        <div className="card flex-fill w-100">
            <div className="card-header">
                <h5 className="card-title mb-0">Yetkazuvchilar bilan transaksiyalar</h5>
                {total_credit !== undefined && (
                    <div className="mt-2">
                        <div className="d-flex gap-4">
                            <div className="h6 mb-0">
                                <span className="text-muted">Kirim:</span>{' '}
                                <span className="text-success">{total_credit.toLocaleString('uz-UZ')} UZS</span>
                            </div>
                            <div className="h6 mb-0">
                                <span className="text-muted">Chiqim:</span>{' '}
                                <span className="text-danger">{total_debit.toLocaleString('uz-UZ')} UZS</span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="card-body d-flex w-100">
                <div className="align-self-center chart chart-lg" style={{ width: '100%', height: '300px' }}>
                    <canvas ref={chartRef} />
                </div>
            </div>
        </div>
    );
};

SupplierTransactionsChart.propTypes = {
    dashboard: PropTypes.shape({
        generalStatistics: PropTypes.shape({
            supplierTransactions: PropTypes.shape({
                credit: PropTypes.array,
                debit: PropTypes.array,
                meta: PropTypes.shape({
                    month: PropTypes.number,
                    year: PropTypes.number,
                    days: PropTypes.number,
                    total_credit: PropTypes.number,
                    total_debit: PropTypes.number
                })
            }),
            loading: PropTypes.shape({
                supplierTransactions: PropTypes.bool.isRequired
            }).isRequired
        }).isRequired
    }).isRequired,
    getGeneralSupplierTransactions: PropTypes.func.isRequired,
    selectedMonth: PropTypes.shape({
        year: PropTypes.number,
        month: PropTypes.number
    }).isRequired
};

const mapStateToProps = state => ({
    dashboard: state.dashboard
});

export default connect(mapStateToProps, { getGeneralSupplierTransactions })(SupplierTransactionsChart);
