import React, { useState, useEffect } from 'react';
import {
    Table,
    Space,
    Button,
    Modal,
    message,
    Popconfirm,
    Tag
} from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import PermissionForm from './PermissionForm';

const PermissionsList = () => {
    const [permissions, setPermissions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [editingPermission, setEditingPermission] = useState(null);

    const fetchPermissions = async () => {
        setLoading(true);
        try {
            const response = await axios.get('/permissions');
            setPermissions(response.data.data || []);
        } catch (error) {
            message.error('Failed to fetch permissions');
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchPermissions();
    }, []);

    const handleDelete = async (permissionId) => {
        try {
            await axios.delete(`/permissions/${permissionId}`);
            message.success('Permission deleted successfully');
            fetchPermissions();
        } catch (error) {
            message.error('Failed to delete permission');
        }
    };

    const handleEdit = (permission) => {
        setEditingPermission(permission);
        setModalVisible(true);
    };

    const handleCreate = () => {
        setEditingPermission(null);
        setModalVisible(true);
    };

    const handleModalClose = () => {
        setModalVisible(false);
        setEditingPermission(null);
    };

    const handleSave = async (values) => {
        try {
            if (editingPermission) {
                await axios.put(`/permissions/${editingPermission.id}`, values);
                message.success('Permission updated successfully');
            } else {
                await axios.post('/permissions', values);
                message.success('Permission created successfully');
            }
            handleModalClose();
            fetchPermissions();
        } catch (error) {
            message.error('Failed to save permission');
        }
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: 'Guard Name',
            dataIndex: 'guard_name',
            key: 'guard_name',
            width: 120,
        },
        {
            title: 'Pages',
            dataIndex: 'pages',
            key: 'pages',
            render: (pages) => (
                <>
                    {pages.map(page => (
                        <Tag color="blue" key={page.id} style={{ marginBottom: '4px' }}>
                            {page.display_name}
                        </Tag>
                    ))}
                </>
            ),
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (date) => new Date(date).toLocaleDateString(),
            width: 120,
        },
        {
            title: 'Actions',
            key: 'actions',
            width: 200,
            render: (_, record) => (
                <Space>
                    <Button
                        type="primary"
                        icon={<EditOutlined />}
                        onClick={() => handleEdit(record)}
                    >
                        Edit
                    </Button>
                    <Popconfirm
                        title="Are you sure you want to delete this permission?"
                        onConfirm={() => handleDelete(record.id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="primary" danger icon={<DeleteOutlined />}>
                            Delete
                        </Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <div>
            <div style={{ marginBottom: 16 }}>
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={handleCreate}
                >
                    Create New Permission
                </Button>
            </div>
            <Table
                columns={columns}
                dataSource={permissions}
                rowKey="id"
                loading={loading}
                pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    showTotal: (total) => `Total ${total} items`,
                }}
            />
            <Modal
                title={editingPermission ? 'Edit Permission' : 'Create Permission'}
                open={modalVisible}
                onCancel={handleModalClose}
                footer={null}
                width={600}
            >
                <PermissionForm
                    initialValues={editingPermission}
                    onSave={handleSave}
                    onCancel={handleModalClose}
                />
            </Modal>
        </div>
    );
};

export default PermissionsList;
