import React, { useEffect } from 'react';
import { Container, Row, Col, Card, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { getProduction } from '../../actions/production';
import PropTypes from 'prop-types';
import MoneyFormat from '../layout/MoneyFormat';
import moment from 'moment';
import Spinner from '../layout/Spinner';

const ProductionPage = ({ getProduction, production: { production, loading } }) => {
    const { id } = useParams();

    useEffect(() => {
        getProduction(id);
    }, [getProduction, id]);

    if (loading || !production?.data) {
        return <Spinner/>;
    }

    const data = production.data;

    return (
        <Container fluid>
            <Row className="mb-4">
                <Col>
                    <h2>Ishlab chiqarish ma'lumotlari</h2>
                </Col>
            </Row>

            <Card className="mb-4">
                <Card.Body>
                    <Row className="mb-3">
                        <Col md={6}>
                            <h5>Mahsulot</h5>
                            <p>{data?.product?.name}</p>
                        </Col>
                        <Col md={6}>
                            <h5>Firma</h5>
                            <p>{data?.product?.firm?.name}</p>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col md={6}>
                            <h5>Ombor</h5>
                            <p>{data?.warehouse?.name}</p>
                        </Col>
                        <Col md={6}>
                            <h5>Miqdori</h5>
                            <p>{data?.quantity}</p>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col md={6}>
                            <h5>Narxi</h5>
                            <p><MoneyFormat value={data?.net_price} decimal={2}/></p>
                        </Col>
                        <Col md={6}>
                            <h5>Sana</h5>
                            <p>{moment(data?.date).format('DD.MM.YYYY')}</p>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <h5>Yaratilgan sana</h5>
                            <p>{moment(data?.created_at).format('DD.MM.YYYY HH:mm')}</p>
                        </Col>
                        <Col md={6}>
                            <h5>O'zgartirilgan sana</h5>
                            <p>{moment(data?.updated_at).format('DD.MM.YYYY HH:mm')}</p>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            {data?.production_materials && data.production_materials.length > 0 ? (
                <Card>
                    <Card.Body>
                        <h4 className="mb-4">Ishlatilgan materiallar</h4>
                        <Table responsive striped bordered hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Material ID</th>
                                    <th>Narxi</th>
                                    <th>Miqdori</th>
                                    <th>Jami</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.production_materials.map((material, index) => (
                                    <tr key={material.id}>
                                        <td>{index + 1}</td>
                                        <td>{material.material_id}</td>
                                        <td><MoneyFormat value={material.price} decimal={0}/></td>
                                        <td>{Number(material.amount).toFixed(2)}</td>
                                        <td><MoneyFormat value={material.price * material.amount} decimal={0}/></td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            ) : (
                <Card>
                    <Card.Body>
                        <p className="text-center mb-0">Materiallar mavjud emas</p>
                    </Card.Body>
                </Card>
            )}
        </Container>
    );
};

ProductionPage.propTypes = {
    getProduction: PropTypes.func.isRequired,
    production: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    production: state.production
});

export default connect(mapStateToProps, { getProduction })(ProductionPage);
