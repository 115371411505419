import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { X, ArrowLeft } from 'react-feather';
import { Multiselect } from 'multiselect-react-dropdown';
import {
    addSale,
    getClientOptions
} from '../../../actions/sale';
import { getFirmOptions } from '../../../actions/user';
import { getWarehouseOptions } from '../../../actions/material';
import { getMaterialOptions } from '../../../actions/material';
import { getSelectedOption } from '../../../utils/hooks/getParams';
import './MaterialSaleCreateForm.css';

const MaterialSaleCreateForm = ({ onClose }) => {
    const dispatch = useDispatch();
    const { sale, user, product, material } = useSelector(state => state);
    const {
        client_options,
        error,
        loading,
        show
    } = sale;
    const { firm_options } = user;
    const { warehouse_options } = product;
    const { material_options } = material;

    const defaultValues = {
        is_special_sale: false,
        firm_id: '',
        client_id: '',
        warehouse_id: '',
        date: moment().format('YYYY-MM-DD'),
        transport_number: '',
        driver_name: '',
        description: '',
        items: []
    };

    const [inputs, setInputs] = useState(defaultValues);
    const [errors, setErrors] = useState({});
    const [firmOptions, setFirmOptions] = useState([]);
    const [warehouseOptions, setWarehouseOptions] = useState([]);
    const [materialOptions, setMaterialOptions] = useState(material_options);
    const [selectedDynamicMaterialOptions, setSelectedDynamicMaterialOptions] = useState([]);
    const [selectedMaterialOptions, setSelectedMaterialOptions] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);

    const handleError = (errors) => {
        setErrors(errors);
    };

    const clearErrors = () => {
        setErrors({});
    };

    const formRef = useRef();

    useEffect(() => {
        dispatch(getClientOptions());
        dispatch(getFirmOptions());
    }, []);

    useEffect(() => {
        if (show) {
            dispatch(getMaterialOptions({ is_for_sale: true }));
        }
    }, [show]);

    useEffect(() => {
        clearErrors();
    }, [sale]);

    useEffect(() => {
        let items = [];
        firm_options.forEach((item) => items.push({
            label: item.name,
            value: item.id
        }));
        setFirmOptions(items);
    }, [firm_options]);

    useEffect(() => {
        let options = [];
        warehouse_options?.forEach((item) => options.push({
            label: item.name,
            value: item.id
        }));
        setWarehouseOptions(options);
    }, [warehouse_options]);

    const handleCloseForm = () => {
        onClose();
    };

    const resetForm = () => {
        setInputs(defaultValues);
        setErrors({});
        setMaterialOptions([]);
        setSelectedMaterialOptions([]);
        setSelectedDynamicMaterialOptions([]);
    };

    useEffect(() => {
        if (material_options.length > 0) {
            const items = material_options.map((item) => ({
                id: item.id,
                name: item.name,
                quantity: 0,
                price: 0,
                net_price: item?.net_price,
                in_stock: item?.in_stock,
            }));
            setMaterialOptions(items);
        }
    }, [material_options]);

    useEffect(() => {
        let options = [];
        client_options?.forEach((item) => options.push({
            label: item.name,
            value: item.id
        }));
        setClientOptions(options);
    }, [client_options]);

    const onSelectMaterial = (data) => {
        setSelectedDynamicMaterialOptions(data);
    };

    const onRemoveMaterial = (data) => {
        setSelectedDynamicMaterialOptions(data);
    };

    const onClickMoveSelectedMaterials = () => {
        if (selectedDynamicMaterialOptions.length) {
            const inputMaterials = selectedDynamicMaterialOptions.map(item => ({
                id: item.id,
                name: item.name,
                quantity: 0,
                price: 0,
                net_price: item?.net_price,
                in_stock: item?.in_stock,
            }));

            // Update selected materials
            setSelectedMaterialOptions([...selectedMaterialOptions, ...inputMaterials]);
            
            // Remove selected items from available options
            const selectedIds = inputMaterials.map(item => item.id);
            const filteredMaterials = materialOptions.filter(item => !selectedIds.includes(item.id));
            setMaterialOptions(filteredMaterials);
            
            // Clear dynamic selection
            setSelectedDynamicMaterialOptions([]);
            
            // Update form inputs
            setInputs(state => ({ ...state, items: [...state.items, ...inputMaterials] }));
        }
    };

    const onRemoveSelectedMaterial = (id) => {
        // Remove from selected materials
        const updatedSelectedMaterials = selectedMaterialOptions.filter(item => item.id !== id);
        setSelectedMaterialOptions(updatedSelectedMaterials);

        // Add back to available options
        const removedMaterial = selectedMaterialOptions.find(item => item.id === id);
        if (removedMaterial) {
            setMaterialOptions([...materialOptions, removedMaterial]);
        }

        // Update form inputs
        const cleanItems = updatedSelectedMaterials.map(item => ({
            id: item.id,
            name: item.name,
            quantity: item?.quantity || 0,
            price: item?.price || 0,
            net_price: item?.net_price,
            in_stock: item?.in_stock,
        }));
        setInputs(state => ({ ...state, items: cleanItems }));
    };

    const handleSelect = (data, input) => {
        clearErrors();
        setInputs(state => ({
            ...state,
            [input.name]: data?.value || ''
        }));
        if (input.name === 'firm_id') {
            dispatch(getWarehouseOptions({ firm_id: data.value }));
            dispatch(getMaterialOptions({ is_for_sale: true, firm_id: data.value }));
        }
    };

    const handleChange = (event) => {
        clearErrors();
        const { name, value } = event.target;
        setInputs(state => ({
            ...state,
            [name]: value
        }));
    };

    const onChangeInput = (e) => {
        let material_id = parseInt(e.target.dataset.id);
        const cleanItems = [];
        const items = selectedMaterialOptions.map((item) => {
            if (item.id === material_id) {
                if (e.target.getAttribute('name') === 'quantity') {
                    if (e.target.value !== '') {
                        if (Number(e.target.value) > 0) {
                            if (Number(e.target.value) <= item?.in_stock && item?.in_stock !== 0) {
                                item[e.target.getAttribute('name')] = Number(e.target.value);
                            }
                        } else {
                            item[e.target.getAttribute('name')] = 1;
                        }
                    } else {
                        item[e.target.getAttribute('name')] = '';
                    }
                } else if (e.target.getAttribute('name') === 'price') {
                    item[e.target.getAttribute('name')] = Number(e.target.value) || 0;
                } else {
                    item[e.target.getAttribute('name')] = e.target.value;
                }
            }
            cleanItems.push({
                id: item.id,
                price: item?.price || 0,
                quantity: item?.quantity || 0,
            });
            return item;
        });
        setSelectedMaterialOptions(items);
        setInputs(state => ({ ...state, items: cleanItems }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const validationErrors = {};

        if (!inputs.firm_id) {
            validationErrors.firm_id = ["Firma tanlash majburiy"];
        }

        if (!inputs.client_id) {
            validationErrors.client_id = ["Mijoz tanlash majburiy"];
        }

        if (!inputs.warehouse_id) {
            validationErrors.warehouse_id = ["Ombor tanlash majburiy"];
        }

        if (!inputs.transport_number) {
            validationErrors.transport_number = ["Transport raqami majburiy"];
        }

        if (!inputs.driver_name) {
            validationErrors.driver_name = ["Haydovchi ismi majburiy"];
        }

        if (!inputs.items.length) {
            validationErrors.items = ["Materiallar tanlash majburiy"];
        } else {
            // Check material quantities
            const invalidQuantities = inputs.items.filter(item => {
                const material = materialOptions.find(m => m.id === item.id);
                return !item.quantity || item.quantity <= 0 || (material && item.quantity > material.in_stock);
            });

            if (invalidQuantities.length > 0) {
                validationErrors['items.0.quantity'] = ["Miqdor ombordagi qoldiqdan ko'p bo'lmasligi kerak"];
            }
        }

        if (Object.keys(validationErrors).length > 0) {
            handleError(validationErrors);
            return;
        }

        const data = {
            client_id: parseInt(inputs.client_id),
            firm_id: parseInt(inputs.firm_id),
            warehouse_id: parseInt(inputs.warehouse_id),
            date: inputs.date,
            transport_number: inputs.transport_number,
            driver_name: inputs.driver_name,
            description: inputs.description,
            comment: inputs.description, // Using description as comment since we don't have a separate field
            items: inputs.items.map(item => ({
                sellable_type: 'App\\Models\\Material',
                sellable_id: parseInt(item.id),
                quantity: parseFloat(item.quantity),
                price: parseFloat(item.price)
            }))
        };

        try {
            const response = await dispatch(addSale(data));
            if (response) {
                resetForm();
                handleCloseForm();
            }
        } catch (err) {
            if (err.errors) {
                handleError(err.errors);
                // If there's a quantity error, update the error in the table
                if (err.errors['items.0.quantity']) {
                    const materialErrors = { ...errors };
                    materialErrors.quantity = err.errors['items.0.quantity'][0];
                    setErrors(materialErrors);
                }
            }
        }
    };

    return (
        <form onSubmit={handleSubmit} ref={formRef}>
            <div className="row g-3">
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Firma</label>
                        <Select
                            value={getSelectedOption(inputs.firm_id, firmOptions)}
                            name="firm_id"
                            onChange={(data) => handleSelect(data, {name: 'firm_id'})}
                            options={firmOptions}
                            placeholder="Firmani tanlang"
                            className={`react-select-container ${errors.firm_id ? 'is-invalid' : ''}`}
                        />
                        {errors.firm_id && <div className="invalid-feedback">{errors.firm_id}</div>}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Ombor</label>
                        <Select
                            value={getSelectedOption(inputs.warehouse_id, warehouseOptions)}
                            name="warehouse_id"
                            onChange={(data) => handleSelect(data, {name: 'warehouse_id'})}
                            options={warehouseOptions}
                            placeholder="Omborni tanlang"
                            className={`react-select-container ${errors.warehouse_id ? 'is-invalid' : ''}`}
                        />
                        {errors.warehouse_id && <div className="invalid-feedback">{errors.warehouse_id}</div>}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Mijoz</label>
                        <Select
                            value={getSelectedOption(inputs.client_id, clientOptions)}
                            name="client_id"
                            onChange={(data) => handleSelect(data, {name: 'client_id'})}
                            options={clientOptions}
                            placeholder="Mijozni tanlang"
                            className={`react-select-container ${errors.client_id ? 'is-invalid' : ''}`}
                        />
                        {errors.client_id && <div className="invalid-feedback">{errors.client_id}</div>}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="date">Sana</label>
                        <input
                            type="date"
                            name="date"
                            className={`form-control ${errors.date ? 'is-invalid' : ''}`}
                            max={moment().format('YYYY-MM-DD')}
                            value={inputs?.date || ''}
                            onChange={handleChange}
                        />
                        {errors.date && <div className="invalid-feedback">{errors.date}</div>}
                    </div>
                </div>
            </div>

            <div className="mt-4">
                <div className="mb-3">
                    <div className="row g-3">
                        <div className="col-md-12">
                            <div className="form-group">
                                <Multiselect
                                    options={materialOptions}
                                    selectedValues={selectedDynamicMaterialOptions}
                                    onSelect={onSelectMaterial}
                                    onRemove={onRemoveMaterial}
                                    displayValue="name"
                                    placeholder="Materiallarni tanlang"
                                />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="d-flex justify-content-end">
                                <Button
                                    variant="primary"
                                    onClick={onClickMoveSelectedMaterials}
                                    disabled={selectedDynamicMaterialOptions.length === 0}
                                >
                                    <ArrowLeft size={18} /> Qo'shish
                                </Button>
                            </div>
                        </div>
                    </div>

                    {selectedMaterialOptions.length > 0 && (
                        <div className="mt-4">
                            <div className="table-responsive">
                                <table className="table table-bordered table-hover sales_table">
                                    <thead>
                                        <tr>
                                            <th>Material nomi</th>
                                            <th>Qoldiq</th>
                                            <th>Tan narxi</th>
                                            <th>Miqdori</th>
                                            <th>Narxi</th>
                                            <th>Jami</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedMaterialOptions.map((material) => (
                                            <tr key={material.id}>
                                                <td>{material.name}</td>
                                                <td>{material.in_stock} {material.unit?.symbol}</td>
                                                <td>{material.net_price}</td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        className={`form-control form-control-sm ${errors.quantity ? 'is-invalid' : ''}`}
                                                        name="quantity"
                                                        data-id={material.id}
                                                        value={material?.quantity || ''}
                                                        onChange={onChangeInput}
                                                    />
                                                    {errors.quantity && (
                                                        <div className="invalid-feedback">
                                                            {errors.quantity}
                                                        </div>
                                                    )}
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        className="form-control form-control-sm"
                                                        name="price"
                                                        data-id={material.id}
                                                        value={material?.price || ''}
                                                        onChange={onChangeInput}
                                                        min="0"
                                                    />
                                                </td>
                                                <td>
                                                    {material?.quantity && material?.price
                                                        ? Math.floor(material.quantity * material.price).toLocaleString('uz-UZ')
                                                        : '0'}
                                                </td>
                                                <td>
                                                    <Button
                                                        variant="danger"
                                                        size="sm"
                                                        onClick={() => onRemoveSelectedMaterial(material.id)}
                                                    >
                                                        <X size={14} />
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}
                    {errors.items && (
                        <div className="text-danger mt-2">
                            {errors.items}
                        </div>
                    )}
                </div>

                <div className="row g-3">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="transport_number">Transport raqami</label>
                            <input
                                type="text"
                                name="transport_number"
                                className={`form-control ${errors.transport_number ? 'is-invalid' : ''}`}
                                value={inputs?.transport_number || ''}
                                onChange={handleChange}
                                placeholder="Transport raqamini kiriting"
                            />
                            {errors.transport_number && <div className="invalid-feedback">{errors.transport_number}</div>}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="driver_name">Haydovchi</label>
                            <input
                                type="text"
                                name="driver_name"
                                className={`form-control ${errors.driver_name ? 'is-invalid' : ''}`}
                                value={inputs?.driver_name || ''}
                                onChange={handleChange}
                                placeholder="Haydovchi ismini kiriting"
                            />
                            {errors.driver_name && <div className="invalid-feedback">{errors.driver_name}</div>}
                        </div>
                    </div>
                </div>

                <div className="mb-3">
                    <label htmlFor="description">Izoh</label>
                    <textarea
                        name="description"
                        className={`form-control ${errors.description ? 'is-invalid' : ''}`}
                        value={inputs?.description || ''}
                        placeholder="Sotuv uchun izoh"
                        onChange={handleChange}
                        rows="3"
                    />
                    {errors.description && <div className="invalid-feedback">{errors.description}</div>}
                </div>

                <div className="d-flex justify-content-end gap-2">
                    <Button variant="secondary" onClick={handleCloseForm}>
                        Bekor qilish
                    </Button>
                    <Button variant="primary" type="submit" disabled={loading}>
                        {loading ? 'Saqlanmoqda...' : 'Saqlash'}
                    </Button>
                </div>
            </div>
        </form>
    );
};

export default MaterialSaleCreateForm;
