import {Link} from "react-router-dom";
import React, { useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {formatMoney} from "../../utils/hooks/getParams";
import {getEmployeeTransactions} from "../../actions/employees_transaction";
import MaterialDataCell from "./MaterialDataCell";

const MaterialDataRow = ({index, item, dates}) => {
    const dispatch = useDispatch();

    const [itemData, setItemData] = useState({
        transactions: []
    });
    const [rowData, setRowData] = useState([]);

    useEffect(() => {
        setItemData(item);
    }, [item])

    useEffect(() => {
        setRowData(itemData.transactions);
    }, [itemData]);

    const refreshRow = (id) => {
        dispatch(getEmployeeTransactions(id));
    }

    const getCellData = (date) => {
        const foundDateItem = rowData.find(item => item.date === date);
        if (foundDateItem){
            return {
                received_amount: foundDateItem.received_amount,
                spent_amount: foundDateItem.spent_amount,
                date: foundDateItem.date,
            }
        }
        return {
            received_amount: 0,
            spent_amount: 0,
            date,
        }
    }

    return (
        <tr>
            <th className="sticky-col">{index + 1}</th>
            <th className="sticky-col2">
                <Link to={`materials/${itemData.id}`}>
                    {itemData.name}
                </Link>
            </th>
            {
                dates?.map((date) => (
                    <MaterialDataCell
                        key={date}
                        object_id={item.id}
                        date={date}
                        cellData={getCellData(date)}
                        rowData={rowData}
                        refreshRow={refreshRow}
                    />))
            }
            <th style={{textAlign: "center", minWidth: "100px", right: "400px"}}
                title={formatMoney(itemData.total_current_month_received_amount, ',')}
            >
                {formatMoney(itemData.total_current_month_received_amount?.toFixed(2))}
            </th>
            <th style={{textAlign: "center", minWidth: "100px", right: "300px"}}
                title={formatMoney(itemData.total_current_month_spent_amount, ',')}
            >
                {formatMoney(itemData.total_current_month_spent_amount?.toFixed(2), ',')}
            </th>
            <th style={{textAlign: "center", minWidth: "100px", right: "200px"}}
                title={formatMoney(itemData.total_received_amount, ',')}
            >
                {formatMoney(itemData.total_received_amount?.toFixed(2))}
            </th>
            <th style={{textAlign: "center", minWidth: "100px", right: "100px"}}
                title={formatMoney(itemData.total_spent_amount, ',')}
            >
                {formatMoney(itemData.total_spent_amount?.toFixed(2))}
            </th>
            <th style={{textAlign: "center", minWidth: "100px", right: "0px"}}
                title={formatMoney(itemData.total_left_amount, ',')}
            >
                {formatMoney(itemData.total_left_amount?.toFixed(2))}
            </th>
        </tr>
    )
}

export default MaterialDataRow;