import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {addExpense, getExpenseTemplateIdData} from "../../actions/expense";
import Select from "react-select";
import {getUnitOptions} from "../../actions/product";
import {getFirms} from "../../actions/firms";

const ExpenseCreateForm = ({
                               expense: {expense_template_id_data, error, loading, expenses},
                               product: {unit_options},
                               firm: {firms},
                               addExpense,
                               getExpenseTemplateIdData,
                               getUnitOptions,
                               getFirms,
                               handleShow,
                               handleClose,
                               show
                           }) => {

    const [templateOptions, setTemplateOptions] = useState([]);
    const [unitOptions, setUnitOptions] = useState([]);
    const [firmOptions, setFirmOptions] = useState([]);
    const [selectedFirm, setSelectedFirm] = useState(null);
    const [inputs, setInputs] = useState({
        expense_template_id: '',
        unit_id: '',
        quantity: '',
        price: '',
        description: '',
        date: new Date().toISOString().split('T')[0]
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        setTemplateOptions([]);
        if (expense_template_id_data && expense_template_id_data.length > 0) {
            const options = expense_template_id_data.map(item => ({
                label: item.name,
                value: item.id
            }));
            setTemplateOptions(options);
        }
    }, [expense_template_id_data]);

    useEffect(() => {
        setUnitOptions([]);
        if (unit_options && unit_options.length > 0) {
            const options = unit_options.map(item => ({
                label: item.name,
                value: item.id
            }));
            setUnitOptions(options);
        }
    }, [unit_options]);

    useEffect(() => {
        setFirmOptions([]);
        if (firms && firms.length > 0) {
            const options = firms.map(firm => ({
                label: firm.name,
                value: firm.id
            }));
            setFirmOptions(options);
        }
    }, [firms]);

    useEffect(() => {
        getUnitOptions();
        getExpenseTemplateIdData();
        getFirms();
    }, [getUnitOptions, getExpenseTemplateIdData, getFirms]);

    const handleChange = e => {
        setErrors({});
        const {name, value} = e.target;
        setInputs(prevState => ({...prevState, [name]: value}));
    };

    const handleSelect = (selectedOption, actionMeta) => {
        setErrors({});
        setInputs(prevState => ({...prevState, [actionMeta.name]: selectedOption?.value || ''}));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        
        if (!inputs.expense_template_id) {
            setErrors(prev => ({...prev, expense_template_id: 'Harajat shablonini tanlang'}));
            return;
        }

        const data = {
            expense_template_id: inputs.expense_template_id,
            price: inputs.price,
            quantity: inputs.quantity,
            unit_id: inputs.unit_id,
            description: inputs.description,
            date: inputs.date,
            firm_id: selectedFirm?.value || ''
        };

        addExpense(data);
        handleClose();
        setInputs({
            expense_template_id: '',
            unit_id: '',
            quantity: '',
            price: '',
            description: '',
            date: new Date().toISOString().split('T')[0]
        });
        setSelectedFirm(null);
    };

    const onClose = () => {
        setErrors({});
        setInputs({
            expense_template_id: '',
            unit_id: '',
            quantity: '',
            price: '',
            description: '',
            date: new Date().toISOString().split('T')[0]
        });
        setSelectedFirm(null);
        handleClose();
    };

    return (
        <Fragment>
            <Modal show={show} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Harajat qo'shish</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label className="form-label">Firma</label>
                            <Select
                                value={selectedFirm}
                                onChange={setSelectedFirm}
                                options={firmOptions}
                                placeholder="Firma tanlang"
                                isClearable
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="expense_template_id">Harajat shabloni</label>
                            <Select
                                name="expense_template_id"
                                options={templateOptions}
                                onChange={handleSelect}
                                value={templateOptions.find(option => option.value === inputs.expense_template_id) || null}
                                placeholder="Tanlang"
                            />
                            <div className="error">
                                {errors?.expense_template_id}
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="price">Narxi</label>
                            <input
                                name="price"
                                className="form-control"
                                type="number"
                                value={inputs.price}
                                onChange={handleChange}
                                placeholder="Narxini kiriting"
                            />
                            <div className="error">
                                {errors?.price}
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="quantity">Miqdori</label>
                            <input
                                name="quantity"
                                className="form-control"
                                type="number"
                                value={inputs.quantity}
                                onChange={handleChange}
                                placeholder="Miqdorini kiriting"
                            />
                            <div className="error">
                                {errors?.quantity}
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="unit_id">O'lchov birligi</label>
                            <Select
                                name="unit_id"
                                options={unitOptions}
                                onChange={handleSelect}
                                value={unitOptions.find(option => option.value === inputs.unit_id) || null}
                                placeholder="Tanlang"
                            />
                            <div className="error">
                                {errors?.unit_id}
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="date">Sana</label>
                            <input
                                type="date"
                                className={`form-control ${errors.date ? 'is-invalid' : ''}`}
                                name="date"
                                value={inputs.date}
                                onChange={handleChange}
                            />
                            {errors.date && <div className="invalid-feedback">{errors.date}</div>}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="description">Tavsifi</label>
                            <textarea
                                name="description"
                                className="form-control"
                                value={inputs.description}
                                onChange={handleChange}
                                placeholder="Tavsifini kiriting"
                            />
                            <div className="error">
                                {errors?.description}
                            </div>
                        </div>
                        <div className="d-flex gap-2">
                            <Button variant="secondary" type="button" onClick={onClose} className="w-50">
                                Bekor qilish
                            </Button>
                            <Button variant="primary" type="submit" className="w-50">
                                Saqlash
                            </Button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </Fragment>
    );
};

ExpenseCreateForm.propTypes = {
    addExpense: PropTypes.func.isRequired,
    getExpenseTemplateIdData: PropTypes.func.isRequired,
    getUnitOptions: PropTypes.func.isRequired,
    getFirms: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleShow: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    expense: state.expense,
    product: state.product,
    firm: state.firm,
});

export default connect(mapStateToProps, {
    addExpense,
    getExpenseTemplateIdData,
    getUnitOptions,
    getFirms,
})(ExpenseCreateForm);
