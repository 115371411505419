import React, {Fragment, useEffect, useState} from "react";
import {formatMoney} from "../../utils/hooks/getParams";

const SupplierDataCell = ({index, cellData}) => {
    const [itemData, setDateData] = useState(cellData);
    useEffect(() => {
        setDateData(cellData);
    }, [cellData])

    return (
        <Fragment key={`td${index}`}>
            <td className={'cell received_amount'}>{formatMoney(itemData.received_amount)}</td>
            <td
                className={'cell paid_amount'}
            >
                {formatMoney(itemData.paid_amount)}
            </td>
        </Fragment>
    )
}

export default SupplierDataCell;