import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const months = [
    { value: '1', label: 'Yanvar' },
    { value: '2', label: 'Fevral' },
    { value: '3', label: 'Mart' },
    { value: '4', label: 'Aprel' },
    { value: '5', label: 'May' },
    { value: '6', label: 'Iyun' },
    { value: '7', label: 'Iyul' },
    { value: '8', label: 'Avgust' },
    { value: '9', label: 'Sentabr' },
    { value: '10', label: 'Oktabr' },
    { value: '11', label: 'Noyabr' },
    { value: '12', label: 'Dekabr' }
];

const DashboardMonthFilter = ({ selectedMonth, setSelectedMonth }) => {
    const date = selectedMonth ? 
        new Date(selectedMonth.year, selectedMonth.month - 1) : 
        new Date();

    const handleChange = (date) => {
        if (date) {
            setSelectedMonth({
                year: date.getFullYear(),
                month: date.getMonth() + 1
            });
        }
    };

    return (
        <DatePicker
            selected={date}
            onChange={handleChange}
            dateFormat="MMMM yyyy"
            showMonthYearPicker
            maxDate={new Date()}
            className="form-control"
            placeholderText="Select month"
        />
    );
};

DashboardMonthFilter.propTypes = {
    selectedMonth: PropTypes.shape({
        year: PropTypes.number.isRequired,
        month: PropTypes.number.isRequired
    }).isRequired,
    setSelectedMonth: PropTypes.func.isRequired
};

export default DashboardMonthFilter;
