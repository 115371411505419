import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import axios from 'axios';
import { toast } from 'react-toastify';

const YearlyIncomeExpensesChart = ({ selectedFirm }) => {
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: []
    });
    const [totals, setTotals] = useState({
        income: 0,
        expenses: 0,
        profit: 0
    });
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [availableYears, setAvailableYears] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const params = {
                    ...(selectedFirm && { firm_id: selectedFirm.id })
                };

                const response = await axios.get('/dashboard/incomes-expenses', { params });
                const data = response.data;

                // Get available years and set them
                const years = Object.keys(data.data).map(Number).sort((a, b) => b - a);
                setAvailableYears(years);

                // Create labels for all months
                const months = ['Yanvar', 'Fevral', 'Mart', 'April', 'May', 'Iyun', 'Iyul', 'Avgust', 'Sentabr', 'Oktabr', 'Noyabr', 'Dekabr'];
                
                // Get selected year data
                const yearData = data.data[selectedYear] || {};

                // Prepare monthly data
                const monthlyData = months.map((_, index) => {
                    const monthNum = index + 1;
                    const monthData = yearData[monthNum] || { income: 0, expenses: 0 };
                    return {
                        income: monthData.income,
                        expenses: monthData.expenses
                    };
                });

                // Format numbers to show in millions
                const formatNumber = (num) => (num / 1000000).toFixed(1);

                // Set totals from yearly total data
                const yearlyTotal = yearData.total || { income: 0, expenses: 0, net_profit: 0 };
                setTotals({
                    income: formatNumber(yearlyTotal.income),
                    expenses: formatNumber(yearlyTotal.expenses),
                    profit: formatNumber(yearlyTotal.net_profit)
                });

                setChartData({
                    labels: months,
                    datasets: [
                        {
                            label: 'Daromad',
                            data: monthlyData.map(d => formatNumber(d.income)),
                            fill: true,
                            backgroundColor: 'rgba(40, 167, 69, 0.1)',
                            borderColor: '#28a745',
                            tension: 0.4,
                            pointRadius: 3,
                            pointHoverRadius: 5,
                            pointBackgroundColor: '#28a745'
                        },
                        {
                            label: 'Xarajat',
                            data: monthlyData.map(d => formatNumber(d.expenses)),
                            fill: true,
                            backgroundColor: 'rgba(220, 53, 69, 0.1)',
                            borderColor: '#dc3545',
                            tension: 0.4,
                            pointRadius: 3,
                            pointHoverRadius: 5,
                            pointBackgroundColor: '#dc3545'
                        }
                    ]
                });
            } catch (err) {
                toast.error('Yillik daromad va xarajatlar ma\'lumotlarini yuklashda xatolik yuz berdi');
            }
        };

        fetchData();
    }, [selectedFirm, selectedYear]);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    font: {
                        size: 13
                    }
                }
            },
            tooltip: {
                backgroundColor: 'rgba(255, 255, 255, 0.95)',
                titleColor: '#333',
                titleFont: {
                    size: 13,
                    weight: 'normal'
                },
                bodyColor: '#333',
                bodyFont: {
                    size: 13
                },
                padding: 12,
                usePointStyle: true,
                borderColor: 'rgba(0, 0, 0, 0.1)',
                borderWidth: 1,
                callbacks: {
                    label: function(context) {
                        return `${context.dataset.label}: ${context.raw} mln`;
                    }
                }
            }
        },
        scales: {
            x: {
                grid: {
                    display: false
                },
                ticks: {
                    font: {
                        size: 12
                    },
                    color: '#666'
                }
            },
            y: {
                beginAtZero: true,
                grid: {
                    color: 'rgba(0, 0, 0, 0.05)',
                    drawBorder: false
                },
                ticks: {
                    font: {
                        size: 12
                    },
                    color: '#666',
                    padding: 10,
                    callback: function(value) {
                        return value + ' mln';
                    }
                }
            }
        },
        interaction: {
            intersect: false,
            mode: 'index'
        }
    };

    return (
        <div className="card">
            <div className="card-header bg-white" style={{ padding: '1.25rem' }}>
                <div className="d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">Yillik daromad va xarajatlar</h5>
                    <select 
                        className="form-select" 
                        style={{ width: 'auto' }}
                        value={selectedYear}
                        onChange={(e) => setSelectedYear(Number(e.target.value))}
                    >
                        {availableYears.map(year => (
                            <option key={year} value={year}>{year}</option>
                        ))}
                    </select>
                </div>
                <div className="row mt-3">
                    <div className="col-12 col-md-4 mb-2">
                        <div className="d-flex align-items-center">
                            <div style={{ width: '12px', height: '12px', backgroundColor: '#28a745', borderRadius: '50%', marginRight: '8px' }} />
                            <div>
                                <div className="text-muted" style={{ fontSize: '0.875rem' }}>Yillik daromad</div>
                                <div className="h5 mb-0">{totals.income} mln</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 mb-2">
                        <div className="d-flex align-items-center">
                            <div style={{ width: '12px', height: '12px', backgroundColor: '#dc3545', borderRadius: '50%', marginRight: '8px' }} />
                            <div>
                                <div className="text-muted" style={{ fontSize: '0.875rem' }}>Yillik xarajat</div>
                                <div className="h5 mb-0">{totals.expenses} mln</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 mb-2">
                        <div className="d-flex align-items-center">
                            <div style={{ width: '12px', height: '12px', backgroundColor: '#0d6efd', borderRadius: '50%', marginRight: '8px' }} />
                            <div>
                                <div className="text-muted" style={{ fontSize: '0.875rem' }}>Yillik sof foyda</div>
                                <div className="h5 mb-0">{totals.profit} mln</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-body" style={{ height: '500px', padding: '0 20px 20px 20px' }}>
                <Line data={chartData} options={options} />
            </div>
        </div>
    );
};

YearlyIncomeExpensesChart.propTypes = {
    selectedFirm: PropTypes.object
};

export default YearlyIncomeExpensesChart;
