import React, {useEffect, useState} from "react";
import CurrencyFormat from "react-currency-format";
import {useDispatch} from "react-redux";
import MoneyFormat from "../../layout/MoneyFormat";
import {getClientTransactions} from "../../../actions/client";
import TransactionDeleteModal from "./TransactionDeleteModal";

const ClientTransactions = ({client, transactionsItems}) => {
    const dispatch = useDispatch();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);

    const {id} = client;
    useEffect(() => {
        if (id) {
            dispatch(getClientTransactions(id, {}));
        }
    }, [getClientTransactions, id]);

    const [from_date, setFromDate] = useState('');
    const [to_date, setToDate] = useState('');

    const onChangeFromDate = (event) => {
        setFromDate(event.target.value);
        const params = {};
        params.to_date = to_date;
        params.from_date = event.target.value;
        dispatch(getClientTransactions(id, params))
    };

    const onChangeToDate = (event) => {
        setToDate(event.target.value);
        const params = {};
        params.to_date = event.target.value;
        params.from_date = from_date;
        dispatch(getClientTransactions(id, params))
    };

    const handleDeleteClick = (transaction) => {
        setSelectedTransaction(transaction);
        setShowDeleteModal(true);
    };

    return (
        <div className="w-100 table-responsive px-2">
            <div className="mb-2 align-items-center d-flex justify-content-between">
                <div className="mb-3">
                    <label htmlFor="date1" className="mb-2">dan</label>
                    <input onChange={onChangeFromDate} className="form-control"
                           id="date1" type="date"/>
                </div>

                <div className="mb-3">
                    <label htmlFor="date2" className="mb-2">gacha</label>
                    <input onChange={onChangeToDate} className="form-control"
                           id="date2" type="date"/>
                </div>
            </div>

            <table className="table custom-table table-hover">
                <thead className="table-dark">
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">ID</th>
                    <th scope="col">To'lov(so'm)</th>
                    <th scope="col">Sotuv(so'm)</th>
                    <th scope="col">Sana</th>
                    <th scope="col">Amallar</th>
                </tr>
                </thead>
                <tbody>
                {
                    transactionsItems?.map((item, index) => (
                        <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <th>{item.id}</th>
                            <td>
                                <CurrencyFormat
                                    value={item.received_amount}
                                    displayType={'text'}
                                    thousandSeparator={true}/>
                            </td>
                            <td>
                                <CurrencyFormat
                                    value={item.paid_amount}
                                    displayType={'text'}
                                    thousandSeparator={true}/>
                            </td>
                            <td>{item.date}</td>
                            <td>
                                <button
                                    onClick={() => handleDeleteClick(item)}
                                    className="btn btn-danger btn-sm"
                                >
                                    O'chirish
                                </button>
                            </td>
                        </tr>
                    ))
                }
                <tr key={"total_transactions"}>
                    <th>#</th>
                    <th>*</th>
                    <th>
                        {
                            transactionsItems && transactionsItems.length > 0 ? (
                                <MoneyFormat
                                    value={transactionsItems.reduce((acc, cur) => acc + Number(cur.received_amount), 0) || 0}
                                    thousandSeparator={true}
                                />
                            ) : ''
                        }
                    </th>
                    <th>
                        {
                            transactionsItems && transactionsItems.length > 0 ? (
                                <MoneyFormat
                                    value={transactionsItems.reduce((acc, cur) => acc + Number(cur.paid_amount), 0) || 0}
                                    thousandSeparator={true}
                                />
                            ) : ''
                        }
                    </th>
                    <th>Jami</th>
                    <th></th>
                </tr>
                </tbody>
            </table>

            <TransactionDeleteModal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                transaction={selectedTransaction}
                clientId={id}
                filters={{from_date, to_date}}
            />
        </div>
    )
}

export default ClientTransactions;