import React, {useEffect, useRef, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";
import Button from "react-bootstrap/Button";
import {clearErrors, getExpenseTemplateIdData, setUpdateExpenseId, updateExpense} from "../../actions/expense";
import Select from "react-select";
import {getUnitOptions} from "../../actions/product";
import {getSelectedOption} from "../../utils/hooks/getParams";

const ExpenseUpdateForm = () => {

    const dispatch = useDispatch();

    const {
        update_expense,
        currentPage,
        expense_template_id_data,
        error,
        loading,
        expenses
    } = useSelector(state => state.expense);
    const {unit_options} = useSelector(state => state.product);


    const [templateOptions, setTemplateOptions] = useState([]);
    const [unitOptions, setUnitOptions] = useState([]);

    useEffect(() => {
        let items = [];
        if (expense_template_id_data && expense_template_id_data.length > 0) {
            expense_template_id_data.forEach((item) => items.push({label: `${item.name}`, value: item.id}));
        }
        setTemplateOptions(items);
    }, [expense_template_id_data]);

    useEffect(() => {
        let items = [];
        if (unit_options && unit_options.length > 0) {
            unit_options.forEach((item) => items.push({label: `${item.name}`, value: item.id}));
        }
        setUnitOptions(items);
    }, [unit_options]);

    useEffect(() => {
        getUnitOptions();
        getExpenseTemplateIdData();
    }, [getUnitOptions, getExpenseTemplateIdData]);

    const formRef = useRef();

    const defaultValues = {
        expense_template_id: update_expense ? getSelectedOption(templateOptions, update_expense.expense_template_id) : '',
        unit_id: update_expense ? getSelectedOption(unitOptions, update_expense.unit_id) : '',
        quantity: update_expense ? update_expense.quantity : '',
        price: update_expense ? update_expense.price : '',
        description: update_expense ? update_expense.description : '',
        date: update_expense ? update_expense.date : new Date().toISOString().split('T')[0]
    };

    const [inputs, setInputs] = useState(defaultValues);
    const [errors, setErrors] = useState({});


    const handleChange = e => {
        setErrors({});
        e.target.oldValue = e.target.value;
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}));
    };

    const handleSelect = (data, type) => {
        setErrors({});
        setInputs(prevState => ({...prevState, [type.name]: data.value}))
    };

    useEffect(() => {
        if (error && loading === false) {
            const errorsItems = error?.errors;
            let errorObject = {};
            if (error && error?.errors) {
                Object.keys(error?.errors).forEach((item, index) => errorObject[item] = errorsItems[item][0]);
            }
            setErrors(errorObject);

        }
    }, [error]);

    useEffect(() => {
        clearErrors();
        setErrors({});
        setInputs(defaultValues);
    }, [expenses]);

    const onClose = () => {
        clearErrors();
        setInputs(defaultValues);
        setErrors({});
        closeModal();
    };


    useEffect(() => {
        if (update_expense) {
            // inputs.expense_template_id = update_expense?.expense_template_id
            setInputs(update_expense);
        }
    }, [update_expense]);

    const closeModal = () => {
        dispatch(setUpdateExpenseId(null));
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(updateExpense(update_expense.id, inputs, currentPage))
    };

    let condition = false;
    if (update_expense !== null)
        condition = true;
    return (
        <div>
            <Modal show={condition} onHide={closeModal}>
                <Modal.Header closeButton className="bg-light">
                    <Modal.Title>Harajatni tahrirlash</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-3 pb-4 mx-3">

                    <form onSubmit={handleSubmit} ref={formRef}>

                        <div className="mb-3">
                            <label htmlFor="expense_template_id">Harajat shabloni</label>
                            <Select
                                name={'expense_template_id'}
                                options={templateOptions}
                                onChange={handleSelect}
                                value={getSelectedOption(inputs.expense_template_id, templateOptions)}
                                defaultValue={{label: 'Tanlang', value: '-'}}
                            />
                            <div className="error">
                                {errors?.expense_template_id}
                            </div>

                        </div>
                        <div className="mb-3">
                            <label htmlFor="amount">Narxi</label>
                            <input
                                name="price"
                                className={'form-control'}
                                type="number"
                                value={inputs?.price || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.price}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="quantity">Miqdori</label>
                            <input
                                name="quantity"
                                className={'form-control'}
                                type="number"
                                value={inputs?.quantity || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.quantity}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="unit_id">O'lchov birligi</label>
                            <Select
                                name={'unit_id'}
                                options={unitOptions}
                                value={getSelectedOption(inputs.unit_id, unitOptions)}
                                onChange={handleSelect}
                                defaultValue={{label: 'Tanlang', value: '-'}}
                            />
                            <div className="error">
                                {errors?.unit_id}
                            </div>

                        </div>

                        <div className="mb-3">
                            <label htmlFor="date">Sana</label>
                            <input
                                type="date"
                                className={`form-control ${errors.date ? 'is-invalid' : ''}`}
                                name="date"
                                value={inputs.date}
                                onChange={handleChange}
                            />
                            {errors.date && <div className="invalid-feedback">{errors.date}</div>}
                        </div>

                        <div className="mb-3">
                            <label htmlFor="description">Tavsifi</label>
                            <textarea
                                name="description"
                                className={'form-control'}
                                type="text"
                                value={inputs?.description || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.description}
                            </div>
                        </div>


                        <div className="d-flex gap-2">
                            <Button className="w-50" variant="secondary" type="button" onClick={onClose}>Bekor
                                qilish</Button>
                            <Button className="w-50" variant="primary" type="submit">Saqlash</Button>
                        </div>

                    </form>

                </Modal.Body>
            </Modal>
        </div>
    );
};

export default ExpenseUpdateForm;