import React from 'react';
import PropTypes from 'prop-types';
import { Package, Users, ShoppingBag, Truck } from 'react-feather';

const StatisticsNumbersCards = ({ statistics }) => {
    const cards = [
        {
            title: 'Materiallar',
            value: statistics?.data?.materials || 0,
            icon: Package,
            color: 'primary',
            prefix: '',
            suffix: 'ta'
        },
        {
            title: 'Mahsulotlar',
            value: statistics?.data?.products || 0,
            icon: ShoppingBag,
            color: 'success',
            prefix: '',
            suffix: 'ta'
        },
        {
            title: 'Mijozlar',
            value: statistics?.data?.clients || 0,
            icon: Users,
            color: 'info',
            prefix: '',
            suffix: 'ta'
        },
        {
            title: 'Yetkazuvchilar',
            value: statistics?.data?.suppliers || 0,
            icon: Truck,
            color: 'warning',
            prefix: '',
            suffix: 'ta'
        }
    ];

    return (
        <div className="row">
            {cards.map((card, index) => (
                <div key={index} className="col-12 col-sm-6 col-xl-3 mb-4">
                    <div className={`card border-${card.color}`}>
                        <div className="card-body">
                            <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                    <card.icon className={`feather feather-${card.icon} text-${card.color}`} />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                    <h5 className="card-title mb-0">{card.title}</h5>
                                    <h3 className="mt-2 mb-0">
                                        {card.prefix}{card.value}{card.suffix}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

StatisticsNumbersCards.propTypes = {
    statistics: PropTypes.object.isRequired
};

export default StatisticsNumbersCards;
