import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { deleteSupplierTransaction } from '../../../actions/suppliers_transaction';

const TransactionDeleteModal = ({ show, onHide, transaction, supplierId, filters }) => {
    const dispatch = useDispatch();

    const handleDelete = () => {
        if (transaction?.id) {
            dispatch(deleteSupplierTransaction(transaction.id, supplierId, filters));
            onHide();
        }
    };

    return (
        <Modal show={show} onHide={onHide} data-testid="delete-transaction-modal">
            <Modal.Header closeButton className="bg-danger">
                <Modal.Title className="text-white">Tranzaksiyani o'chirish</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Haqiqatan ham bu tranzaksiyani o'chirmoqchimisiz?</p>
                <div className="transaction-details">
                    <p><strong>Sana:</strong> {transaction?.date}</p>
                    <p><strong>Olingan summa:</strong> {transaction?.received_amount} so'm</p>
                    <p><strong>To'langan summa:</strong> {transaction?.paid_amount} so'm</p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide} data-testid="delete-transaction-cancel">
                    Bekor qilish
                </Button>
                <Button variant="danger" onClick={handleDelete} data-testid="delete-transaction-confirm">
                    O'chirish
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default TransactionDeleteModal;
