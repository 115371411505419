import axios from 'axios';
import { clearAlerts, setAlert } from './alert';
import {
    CLEAR_ERRORS,
    GET_MATERIAL_OPTIONS,
    GET_PRODUCT_OPTIONS,
    GET_PRODUCT_WAREHOUSES_ON_PRODUCTS,
    GET_UNIT_OPTIONS,
    GET_WAREHOUSE_OPTIONS,
    PRODUCT_ERROR,
    REMOVE_DELETE_PRODUCT_PRODUCTION,
    REMOVE_UPDATE_PRODUCT,
    REMOVE_UPDATE_PRODUCT_PRODUCTION,
    SET_LOADING, SET_PRODUCTION_ERROR,
    SET_DELETE_PRODUCTION_ID,
    SET_UPDATE_PRODUCT_PRODUCTION_ID,
    GET_PRODUCTIONS, GET_PRODUCTION, REMOVE_DELETE_PRODUCTION, SET_UPDATE_PRODUCTION_ID,
} from './types';
export const getProductions = (page = 1, filters = {}) => async (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    try {
        let queryParams = '';
        if (filters) {
            Object.keys(filters).map((key) => queryParams += `&${key}=${filters[key]}`);
        }
        const res = await axios.get(`/productions?page=${page}${queryParams}`);
        dispatch({
            type: GET_PRODUCTIONS,
            payload: res.data,
        });
        dispatch({ type: SET_LOADING, payload: false });
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getUnitOptions = () => async (dispatch) => {
    try {
        const res = await axios.get('/units');
        dispatch({
            type: GET_UNIT_OPTIONS,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getProductOptions = (filters = {}) => async (dispatch) => {
    let queryParams = '';
    if (filters) {
        Object.keys(filters).map((key) => queryParams += `&${key}=${filters[key]}`);
    }
    try {
        const res = await axios.get(`/products?is_all=true${queryParams}`);
        dispatch({
            type: GET_PRODUCT_OPTIONS,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getProductWarehouses = () => async (dispatch) => {
    try {
        const res = await axios.get('/warehouses?type=product');
        dispatch({
            type: GET_PRODUCT_WAREHOUSES_ON_PRODUCTS,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getProduction = (productionId) => async (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    try {
        const res = await axios.get(`/productions/${productionId}`);
        dispatch({
            type: GET_PRODUCTION,
            payload: res.data,
        });
        dispatch({ type: SET_LOADING, payload: false });
    } catch (err) {
        console.error('Error fetching production:', err);
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        dispatch({ type: SET_LOADING, payload: false });
    }
};

export const getMaterialOptions = (filters = {}) => async (dispatch) => {

    let queryParams = '';
    if (filters) {
        Object.keys(filters).map((key) => queryParams += `&${key}=${filters[key]}`);
    }
    try {
        const res = await axios.get(`/materials?is_all=true${queryParams}`);
        dispatch({
            type: GET_MATERIAL_OPTIONS,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getWarehouseOptions = (filters = {}) => async (dispatch) => {
    try {
        let queryParams = '';
        if (filters) {
            Object.keys(filters).map((key) => queryParams += `&${key}=${filters[key]}`);
        }
        const res = await axios.get(`/warehouses?is_all=true&type=product${queryParams}`);

        dispatch({
            type: GET_WAREHOUSE_OPTIONS,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const addProduction = (formData, filters) => async (dispatch) => {

    dispatch({ type: SET_LOADING, payload: true });
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        await axios.post('/productions/', formData, config);
        dispatch(setAlert('Mahsulot ishlab chiqarish bajarildi !', 'success'));
        dispatch(getProductions(filters.page || 1, filters));
        dispatch({ type: SET_LOADING, payload: false });

        return true;
    } catch (err) {
        dispatch({ type: SET_PRODUCTION_ERROR, payload: err.response.data });
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        return false;
    }
};

export const deleteProduction = (deleteProductionID, filters = {}) => async (dispatch) => {


    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        await axios.delete(`/productions/${deleteProductionID}`, config);

        dispatch({
            type: REMOVE_DELETE_PRODUCT_PRODUCTION
        });
        dispatch(setAlert("Mahsulot ishlab chiqarish o'chirildi!", 'success'));

        dispatch(getProductions(1, filters));

        dispatch(clearErrors())

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        dispatch(setAlert("Mahsulot ishlab chiqarish o'chirilmadi!", 'danger'));
    }
};

export const updateProduction = (updateProductionID, updateformData, filters = {}) => async (dispatch) => {
    dispatch(clearAlerts());
    dispatch({ type: SET_LOADING, payload: true });

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        await axios.put(`/productions/${updateProductionID}`, updateformData, config);
        dispatch(getProductions(filters.page, filters));
        dispatch(setAlert('Mahsulot ishlab chiqarish tahrirlandi !', 'success'));
        dispatch({ type: SET_LOADING, payload: true });
        return true;
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        dispatch({ type: PRODUCT_ERROR, payload: err.response.data });
        return false;
    }
};

export const setDeleteProductionId = (productionID) => async (dispatch) => {
    try {
        if (productionID !== null) {
            dispatch({
                type: SET_DELETE_PRODUCTION_ID,
                payload: productionID
            });

        } else {
            dispatch({
                type: REMOVE_DELETE_PRODUCTION
            });
        }

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const setUpdateProductionId = (productID) => async (dispatch) => {

    try {
        if (productID !== null) {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            const res = await axios.get(`/productions/${productID}`, config);

            dispatch({
                type: SET_UPDATE_PRODUCTION_ID,
                payload: res.data
            });
        } else dispatch({ type: REMOVE_UPDATE_PRODUCT });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const setUpdateProductProductionId = (product_production_ID, productionItems) => async (dispatch) => {

    try {
        if (product_production_ID !== null) {

            let update_product_production = productionItems.find((item) => item.id === product_production_ID);

            dispatch({
                type: SET_UPDATE_PRODUCT_PRODUCTION_ID,
                payload: update_product_production
            });
        } else dispatch({ type: REMOVE_UPDATE_PRODUCT_PRODUCTION });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const removeDeleteProductProduction = () => async (dispatch) => {

    try {
        dispatch({ type: REMOVE_DELETE_PRODUCT_PRODUCTION, payload: null });
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS,
    });
};
