import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import SpecialSaleUpdateForm from './product-sales/SpecialSaleUpdateForm';
import SimpleSaleUpdateForm from './product-sales/SimpleSaleUpdateForm';

const ProductSaleUpdateForm = ({ handleClose }) => {
    const { update_sale } = useSelector(state => state.sale);
    const [isSpecialSale, setIsSpecialSale] = useState(false);

    useEffect(() => {
        if (update_sale) {
            setIsSpecialSale(update_sale.is_special_sale || false);
        }
    }, [update_sale]);

    return (
        <div>
            <div className="mb-4">
                <Form.Check
                    inline
                    type="radio"
                    name="saleSubType"
                    id="simpleSale"
                    label="Oddiy sotuv"
                    checked={!isSpecialSale}
                    onChange={() => setIsSpecialSale(false)}
                />
                <Form.Check
                    inline
                    type="radio"
                    name="saleSubType"
                    id="specialSale"
                    label="Maxsus sotuv"
                    checked={isSpecialSale}
                    onChange={() => setIsSpecialSale(true)}
                />
            </div>
            {isSpecialSale ? (
                <SpecialSaleUpdateForm handleClose={handleClose} />
            ) : (
                <SimpleSaleUpdateForm handleClose={handleClose} />
            )}
        </div>
    );
};

export default ProductSaleUpdateForm;
