import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';

const SpecialMaterialSaleCreateForm = ({ show, handleClose, supplier, onSuccess }) => {
    const [materials, setMaterials] = useState([]);
    const [firms, setFirms] = useState([]);
    const [formData, setFormData] = useState({
        material_id: '',
        quantity: '',
        price: '',
        firm_id: ''
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchFirms();
    }, []);

    useEffect(() => {
        if (formData.firm_id) {
            fetchMaterials(formData.firm_id);
        } else {
            setMaterials([]);
        }
    }, [formData.firm_id]);

    const fetchFirms = async () => {
        try {
            const response = await axios.get('/firms');
            setFirms(response.data.data);
        } catch (error) {
            toast.error('Firmalarni yuklashda xatolik yuz berdi');
        }
    };

    const fetchMaterials = async (firmId) => {
        try {
            const response = await axios.get(`/firms/${firmId}/materials`);
            
            setMaterials(response.data.data);
        } catch (error) {
            toast.error('Materiallarni yuklashda xatolik yuz berdi');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value,
            // Reset material selection when firm changes
            ...(name === 'firm_id' ? { material_id: '' } : {})
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const { firm_id, ...submitData } = formData; // Remove firm_id from submission
            await axios.post(`/suppliers/${supplier.id}/special-material-sales`, submitData);
            toast.success('Maxsus material chiqim muvaffaqiyatli qo\'shildi');
            handleClose();
            onSuccess();
            setFormData({
                material_id: '',
                quantity: '',
                price: '',
                firm_id: ''
            });
        } catch (error) {
            toast.error('Xatolik yuz berdi');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Yangi maxsus material chiqim qo'shish</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit}>
                <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Label>Firma</Form.Label>
                        <Form.Select
                            name="firm_id"
                            value={formData.firm_id}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Firmani tanlang</option>
                            {firms.map(firm => (
                                <option key={firm.id} value={firm.id}>
                                    {firm.name}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Material</Form.Label>
                        <Form.Select
                            name="material_id"
                            value={formData.material_id}
                            onChange={handleChange}
                            required
                            disabled={!formData.firm_id}
                        >
                            <option value="">Materialni tanlang</option>
                            {materials.map(material => (
                                <option key={material.id} value={material.id}>
                                    {material.name}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Miqdori</Form.Label>
                        <Form.Control
                            type="number"
                            name="quantity"
                            value={formData.quantity}
                            onChange={handleChange}
                            required
                            min="0"
                            step="0.01"
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Narxi</Form.Label>
                        <Form.Control
                            type="number"
                            name="price"
                            value={formData.price}
                            onChange={handleChange}
                            required
                            min="0"
                            step="0.01"
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Bekor qilish
                    </Button>
                    <Button variant="primary" type="submit" disabled={loading}>
                        {loading ? 'Saqlanmoqda...' : 'Saqlash'}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default SpecialMaterialSaleCreateForm;
