import './wdyr';
import Axios from "axios";
import React, {useEffect, Fragment} from 'react';
import {Route, Switch, withRouter, Redirect} from 'react-router-dom';
import Routes from './components/routing/Routes';
import getApiUrl from './config/api';

// Components
import Sidebar from './components/layout/Sidebar';
import Landing from './components/layout/Landing';
import Navbar from './components/layout/Navbar';
import Login from './components/auth/Login';
import PrivateRoute from './components/routing/PrivateRoute';

import {loadUser} from './actions/auth';
import {toggleSidebar} from './actions/sidebar';
import setAuthToken from './utils/setAuthToken';

import './app.css';
import './custom.css';
import './styles/overrides.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.min.css';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Alert from './components/layout/Alert';
import PropTypes from "prop-types";
import {connect} from "react-redux";

// Set default axios configuration
Axios.defaults.baseURL = getApiUrl();
Axios.defaults.headers.common['Content-Type'] = 'application/json';
Axios.defaults.headers.common['Accept'] = 'application/json';

// Add response interceptor for better error handling
Axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response) {
            // Log the error but don't expose it to the user
            console.error('API Error:', error.response);
            
            // Handle 401 unauthorized
            if (error.response.status === 401) {
                localStorage.removeItem('token');
                setAuthToken(null);
            }
        }
        return Promise.reject(error);
    }
);

if (localStorage.token) {
    setAuthToken(localStorage.token);
}

const App = ({ loadUser, toggleSidebar, auth: { isAuthenticated }, location }) => {
    useEffect(() => {
        loadUser();
    }, [loadUser]);

    const getPageClass = () => {
        const path = location.pathname;
        // Remove leading and trailing slashes and replace remaining slashes with dashes
        const cleanPath = path.replace(/^\/+|\/+$/g, '').replace(/\//g, '-');
        return `wrapper page-${cleanPath || 'home'}`;
    };

    return (
        <Fragment>
            <div className={getPageClass()}>
                <Sidebar/>
                {isAuthenticated && <Navbar toggleSideBar={toggleSidebar}/>}

                <div className="main mt-5">
                    <Switch>
                        <Route exact path="/" component={Landing}/>
                        <Route exact path="/login">
                            {isAuthenticated ? <Redirect to="/dashboard" /> : <Login />}
                        </Route>
                        <PrivateRoute component={Routes}/>
                    </Switch>
                </div>
            </div>
            <Alert/>
            <ToastContainer autoClose={5000} limit={1}/>
        </Fragment>
    );
};

App.propTypes = {
    loadUser: PropTypes.func.isRequired,
    toggleSidebar: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { loadUser, toggleSidebar })(withRouter(App));
