import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Button from 'react-bootstrap/Button';
import Select from 'react-select'
import {
    addSale,
    clearErrors,
    getClientOptions,
    getProductOptions,
} from "../../../actions/sale";
import {Accordion} from "react-bootstrap";
import {ArrowLeft, X} from "react-feather";
import {Multiselect} from "multiselect-react-dropdown";
import {getSelectedOption} from "../../../utils/hooks/getParams";
import moment from "moment";
import {getWarehouseOptions} from "../../../actions/product";
import {getFirmOptions} from "../../../actions/user";
import {useFormErrors} from "../../../utils/hooks/useFormErrors";
import "./SimpleSaleCreateForm.css";

const SimpleSaleCreateForm = ({onClose}) => {
    const {sale, user, product} = useSelector(state => state);
    const dispatch = useDispatch();
    const {
        client_options,
        product_options,
        sales,
        error,
        loading,
        show
    } = sale;
    const { firm_options } = user;
    const { warehouse_options } = product;

    const defaultValues = {
        is_special_sale: false,
        firm_id: '',
        client_id: '',
        warehouse_id: '',
        date: moment().format('YYYY-MM-DD'),
        transport_number: '',
        driver_name: '',
        description: '',
        items: []
    };

    const [errors, inputs, setInputs, handleError, resetForm] = useFormErrors(
        error, 
        loading, 
        () => dispatch(clearErrors()), 
        defaultValues
    );

    useEffect(() => {
        dispatch(getClientOptions())
        dispatch(getFirmOptions());
    }, []);

    useEffect(() => {
        if (show) {
            dispatch(getProductOptions({is_for_sale: true}));
        }
    }, [show]);

    const formRef = useRef();

    const [firmOptions, setFirmOptions] = useState([]);
    const [warehouseOptions, setWarehouseOptions] = useState([]);
    const [productOptions, setProductOptions] = useState(product_options);
    const [selectedDynamicProductOptions, setSelectedDynamicProductOptions] = useState([]);
    const [selectedProductOptions, setSelectedProductOptions] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);

    const handleChange = e => {
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}));
    };

    useEffect(() => {
        clearErrors();
        setInputs(defaultValues);
    }, [sales]);

    useEffect(() => {
        let items = [];
        firm_options.forEach((item) => items.push({
            label: `${item.name}`,
            value: item.id
        }));
        setFirmOptions(items);
    }, [firm_options]);

    useEffect(() => {
        let options = [];
        warehouse_options?.forEach((item) => options.push({
            label: item.name,
            value: item.id
        }));
        setWarehouseOptions(options);
    }, [warehouse_options]);

    const handleCloseForm = () => {
        resetForm();
        onClose();
        setProductOptions([]);
        setSelectedProductOptions([]);
    };

    useEffect(() => {
        product_options?.map(item => {
            item.price = 0;
            item.quantity = 0;
            return item
        });
        setProductOptions(product_options);
    }, [product_options]);

    useEffect(() => {
        let options = [];
        client_options?.forEach((item) => options.push({
            label: item.name,
            value: item.id
        }));
        setClientOptions(options);
    }, [client_options]);

    const onSelectProduct = (data) => {
        setSelectedDynamicProductOptions(data);
    };

    const onRemoveProduct = (data) => {
        setSelectedDynamicProductOptions(data)
    };

    const onClickMoveSelectedMaterials = () => {
        if (selectedDynamicProductOptions.length > 0) {
            setSelectedProductOptions([...selectedProductOptions, ...selectedDynamicProductOptions]);
            let ids = selectedDynamicProductOptions.map(({id}) => id);
            const filteredMaterials = productOptions.filter((item) => !ids.includes(item.id));
            setProductOptions(filteredMaterials);
            setSelectedDynamicProductOptions([]);
            const inputProducts = [];
            selectedProductOptions.map(item => inputProducts.push({
                id: item.id,
                quantity: item?.quantity || 0,
                net_price: item?.net_price || 0,
            }));
            inputs.items = [...inputs.items, ...inputProducts];
            setInputs(inputs);
        }
    };

    const handleSelect = (data, type) => {
        setInputs(prevState => ({...prevState, [type.name]: data.value}))
        if (type.name === 'firm_id') {
            dispatch(getWarehouseOptions({firm_id: data.value}));
            dispatch(getProductOptions({is_for_sale: true, firm_id: data.value}));
        }
    };

    const onChangeInput = (e) => {
        let product_id = parseInt(e.target.dataset.id);
        const cleanItems = [];
        const items = selectedProductOptions.map((item) => {
            if (item.id === product_id) {
                if (e.target.getAttribute('name') === 'quantity') {
                    if (e.target.value !== '') {
                        if (Number(e.target.value) > 0) {
                            if (Number(e.target.value) <= item?.in_stock && item?.in_stock !== 0) {
                                item[e.target.getAttribute('name')] = Number(e.target.value);
                            }
                        } else {
                            item[e.target.getAttribute('name')] = 1;
                        }
                    } else {
                        item[e.target.getAttribute('name')] = '';
                    }
                } else if (e.target.getAttribute('name') === 'price') {
                    item[e.target.getAttribute('name')] = Number(e.target.value) || 0;
                } else {
                    item[e.target.getAttribute('name')] = e.target.value;
                }
            }
            cleanItems.push({
                id: item.id,
                price: item?.price || 0,
                quantity: item?.quantity || 0,
            });
            return item;
        });
        setSelectedProductOptions(items);
        inputs.items = cleanItems;
        setInputs(inputs);
    };

    const onRemoveSelectedMaterial = (id) => {
        const items = selectedProductOptions.filter((item) => item.id !== id);
        setSelectedProductOptions(items);
        const selectedItem = selectedProductOptions.find((item) => item.id === id);
        setProductOptions([...productOptions, selectedItem]);
        const cleanItems = [];
        items.map(item => cleanItems.push({
            id: item.id,
            price: item?.price || 0,
            quantity: item?.quantity,
        }));
        inputs.items = cleanItems;
        setInputs(inputs);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!inputs.firm_id) {
            handleError({ firm_id: ["Firma tanlash majburiy"] });
            return;
        }

        if (!inputs.client_id) {
            handleError({ client_id: ["Mijoz tanlash majburiy"] });
            return;
        }

        if (!inputs.warehouse_id) {
            handleError({ warehouse_id: ["Ombor tanlash majburiy"] });
            return;
        }

        if (!inputs.items.length) {
            handleError({ items: ["Mahsulotlar tanlash majburiy"] });
            return;
        }

        const data = {
            is_special_sale: false,
            client_id: parseInt(inputs.client_id),
            firm_id: parseInt(inputs.firm_id),
            warehouse_id: parseInt(inputs.warehouse_id),
            date: inputs.date,
            transport_number: inputs.transport_number,
            driver_name: inputs.driver_name,
            description: inputs.description,
            items: inputs.items.map(item => ({
                sellable_type: 'App\\Models\\Product',
                sellable_id: parseInt(item.id),
                quantity: parseFloat(item.quantity),
                price: parseFloat(item.price)
            }))
        };
        
        const response = await dispatch(addSale(data));
        
        if (response === true) {
            handleCloseForm();
            return;
        }
        
        // If there's an error, don't close the modal
        handleError(error);
    };

    return (
        <Fragment>
            <style>{`
                .sales_table td {
                    min-width: auto !important;
                }
            `}</style>
            <form onSubmit={handleSubmit} ref={formRef}>
                <div className="row g-3">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Firma</label>
                            <Select
                                value={getSelectedOption(inputs.firm_id, firmOptions)}
                                name="firm_id"
                                onChange={(data) => handleSelect(data, {name: 'firm_id'})}
                                options={firmOptions}
                                placeholder="Firmani tanlang"
                                className={errors.firm_id ? 'is-invalid' : ''}
                            />
                            {errors.firm_id && <div className="invalid-feedback">{errors.firm_id}</div>}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Ombor</label>
                            <Select
                                value={getSelectedOption(inputs.warehouse_id, warehouseOptions)}
                                name="warehouse_id"
                                onChange={(data) => handleSelect(data, {name: 'warehouse_id'})}
                                options={warehouseOptions}
                                placeholder="Omborni tanlang"
                                className={errors.warehouse_id ? 'is-invalid' : ''}
                            />
                            {errors.warehouse_id && <div className="invalid-feedback">{errors.warehouse_id}</div>}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Mijoz</label>
                            <Select
                                value={getSelectedOption(inputs.client_id, clientOptions)}
                                name="client_id"
                                onChange={(data) => handleSelect(data, {name: 'client_id'})}
                                options={clientOptions}
                                placeholder="Mijozni tanlang"
                                className={errors.client_id ? 'is-invalid' : ''}
                            />
                            {errors.client_id && <div className="invalid-feedback">{errors.client_id}</div>}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="date">Sana</label>
                            <input
                                type="date"
                                name="date"
                                className="form-control"
                                value={inputs?.date || ''}
                                max={moment().format('YYYY-MM-DD')}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>

                <div className="mt-4">
                    <div className="mb-3">
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <div className="d-flex align-items-center">
                                        <span className="me-2">Mahsulotlar</span>
                                        {selectedProductOptions.length > 0 && (
                                            <div className="badge bg-primary">{selectedProductOptions.length}</div>
                                        )}
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <div className="row g-3">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <Multiselect
                                                    options={productOptions}
                                                    selectedValues={selectedDynamicProductOptions}
                                                    onSelect={onSelectProduct}
                                                    onRemove={onRemoveProduct}
                                                    displayValue="name"
                                                    placeholder="Mahsulotlarni tanlang"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="d-flex justify-content-end">
                                                <Button
                                                    variant="primary"
                                                    onClick={onClickMoveSelectedMaterials}
                                                    disabled={selectedDynamicProductOptions.length === 0}
                                                >
                                                    <ArrowLeft size={18}/> Qo'shish
                                                </Button>
                                            </div>
                                        </div>
                                    </div>

                                    {selectedProductOptions.length > 0 && (
                                        <div className="mt-4">
                                            <div className="table-responsive">
                                                <table 
                                                    className="table table-bordered table-hover sales_table" 
                                                >
                                                    <thead>
                                                    <tr>
                                                        <th>Mahsulot nomi</th>
                                                        <th>Qoldiq</th>
                                                        <th>Tan narxi</th>
                                                        <th className="auto">Miqdori</th>
                                                        <th>Sotuv narxi</th>
                                                        <th className="auto">Jami</th>
                                                        <th></th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {selectedProductOptions.map((product) => (
                                                        <tr key={product.id}>
                                                            <td>{product.name}</td>
                                                            <td>{product.in_stock || 0}</td>
                                                            <td>{Math.floor(product.net_price).toLocaleString('uz-UZ') || 0}</td>
                                                            <td className="auto w-5">
                                                                <input
                                                                    type="string"
                                                                    className="form-control form-control-sm"
                                                                    name="quantity"
                                                                    data-id={product.id}
                                                                    value={product?.quantity || ''}
                                                                    onChange={onChangeInput}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    className="form-control form-control-sm"
                                                                    name="price"
                                                                    data-id={product.id}
                                                                    value={product?.price || ''}
                                                                    onChange={onChangeInput}
                                                                />
                                                            </td>
                                                            <td>
                                                                {product?.quantity && product?.price
                                                                    ? Math.floor(product.quantity * product.price).toLocaleString('uz-UZ')
                                                                    : '0'}
                                                            </td>
                                                            <td>
                                                                <Button
                                                                    variant="danger"
                                                                    size="sm"
                                                                    onClick={() => onRemoveSelectedMaterial(product.id)}
                                                                >
                                                                    <X size={14}/>
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )}
                                    {errors.items && (
                                        <div className="text-danger mt-2">
                                            {errors.items}
                                        </div>
                                    )}
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>

                    <div className="row g-3 mb-2">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="transport_number">Transport raqami</label>
                                <input
                                    type="text"
                                    name="transport_number"
                                    className="form-control"
                                    value={inputs?.transport_number || ''}
                                    onChange={handleChange}
                                    placeholder="Transport raqamini kiriting"
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="driver_name">Haydovchi</label>
                                <input
                                    type="text"
                                    name="driver_name"
                                    className="form-control"
                                    value={inputs?.driver_name || ''}
                                    onChange={handleChange}
                                    placeholder="Haydovchi ismini kiriting"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="description">Izoh</label>
                        <textarea
                            name="description"
                            className="form-control"
                            value={inputs?.description || ''}
                            placeholder="Sotuv uchun izoh"
                            onChange={handleChange}
                            rows="3"
                        />
                        <div className="error">
                            {errors?.description}
                        </div>
                    </div>
                </div>

                <div className="d-flex gap-2">
                    <Button className="w-50" variant="secondary" type="button" onClick={handleCloseForm}>Bekor
                        qilish</Button>
                    <Button className="w-50" variant="primary" type="submit">
                        Saqlash
                        {loading &&
                            <div className="spinner-border spinner-border-sm text-light" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        }
                    </Button>
                </div>
            </form>
        </Fragment>
    );
};

export default SimpleSaleCreateForm;
