import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getMonthlySales } from '../../actions/dashboard';
import Chart from 'chart.js/auto';

const months = [
    "Yanvar",
    "Fevral",
    "Mart",
    "Aprel",
    "May",
    "Iyun",
    "Iyul",
    "Avgust",
    "Sentabr",
    "Oktabr",
    "Noyabr",
    "Dekabr"
];

const MonthlySalesChart = ({
    dashboard: { monthlySales, loading },
    getMonthlySales,
    selectedFirm,
    selectedMonth
}) => {
    const chartRef = React.useRef(null);
    const chartInstance = React.useRef(null);

    useEffect(() => {
        const params = {};
        if (selectedFirm) {
            params.firm_id = selectedFirm;
        }
        if (selectedMonth?.year && selectedMonth?.month) {
            params.year = selectedMonth.year;
            params.month = selectedMonth.month;
        }
        getMonthlySales(params);
    }, [getMonthlySales, selectedFirm, selectedMonth]);

    useEffect(() => {
        if (loading.monthlySales || !monthlySales) return;

        const ctx = chartRef.current.getContext('2d');

        // Destroy existing chart if it exists
        if (chartInstance.current) {
            chartInstance.current.destroy();
        }

        // Create labels for days of the month
        const daysInMonth = monthlySales.meta.days;
        const labels = Array.from({ length: daysInMonth }, (_, i) => `${i + 1}-kun`);

        chartInstance.current = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: labels,
                datasets: [{
                    label: `${monthlySales.meta.year}-yil ${months[monthlySales.meta.month - 1]} oyi Sotuvi`,
                    data: monthlySales.data.slice(0, daysInMonth),
                    backgroundColor: '#47bac1',
                    borderRadius: 4
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: true,
                        position: 'top'
                    },
                    tooltip: {
                        callbacks: {
                            label: (context) => {
                                const value = context.raw.toLocaleString('uz-UZ');
                                return `${value} UZS`;
                            }
                        }
                    }
                },
                scales: {
                    y: {
                        beginAtZero: true,
                        ticks: {
                            callback: (value) => {
                                return value.toLocaleString('uz-UZ') + ' UZS';
                            }
                        }
                    },
                    x: {
                        ticks: {
                            maxRotation: 45,
                            minRotation: 45
                        }
                    }
                }
            }
        });

        // Cleanup
        return () => {
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }
        };
    }, [monthlySales, loading.monthlySales]);

    if (loading.monthlySales) {
        return <div>Loading...</div>;
    }

    return (
        <div className="card flex-fill w-100">
            <div className="card-header">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5 className="card-title mb-0">Oyning umumiy sotuvi</h5>
                    {monthlySales?.meta?.total_sales !== undefined && (
                        <div className="h5 mb-0">
                            {monthlySales.meta.total_sales.toLocaleString('uz-UZ')} UZS
                        </div>
                    )}
                </div>
            </div>
            <div className="card-body d-flex w-100">
                <div className="align-self-center chart chart-lg" style={{ width: '100%', height: '300px' }}>
                    <canvas ref={chartRef} />
                </div>
            </div>
        </div>
    );
};

MonthlySalesChart.propTypes = {
    dashboard: PropTypes.shape({
        monthlySales: PropTypes.shape({
            data: PropTypes.array,
            meta: PropTypes.shape({
                month: PropTypes.number,
                year: PropTypes.number,
                days: PropTypes.number,
                total_sales: PropTypes.number
            })
        }),
        loading: PropTypes.shape({
            monthlySales: PropTypes.bool.isRequired
        }).isRequired
    }).isRequired,
    getMonthlySales: PropTypes.func.isRequired,
    selectedFirm: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.oneOf([null])
    ]),
    selectedMonth: PropTypes.shape({
        year: PropTypes.number,
        month: PropTypes.number
    })
};

const mapStateToProps = state => ({
    dashboard: state.dashboard
});

export default connect(mapStateToProps, { getMonthlySales })(MonthlySalesChart);
