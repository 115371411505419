import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {setDeleteProductionId, setUpdateProductionId} from "../../actions/production";
import {Link} from 'react-router-dom';
import MoneyFormat from "../layout/MoneyFormat";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faEye } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import moment from "moment";

const ProductionItem = ({
                         auth,
                        setDeleteProductionId,
                        setUpdateProductionId,
                         handleShow,
                         handleClose,
                            production: item,
                         index
                     }) => {

    const {role_name} = auth?.user || {};


    const setDeleteProduction = (id) => {
        setDeleteProductionId(id);
    };

    const setUpdateProduction = (id) => {
        setUpdateProductionId(id);
        if (id){
            handleShow();
        }else{
            handleClose();
        }
    };

    return (
        <Fragment>
            <tr>
                <th scope="row">{index + 1}</th>
                <td>
                    <Link to={`/products/${item.product_id}`}>
                        {item?.product?.name}
                    </Link>
                </td>
                <td>
                    <Link to={`/firms/${item.warehouse?.firm_id}`}>
                        {item.warehouse?.firm?.name}
                    </Link>
                </td>
                <td>
                    <Link to={`/warehouses/${item.warehouse?.id}`}>
                        {item.warehouse?.name}
                    </Link>
                </td>
                <td>{item?.quantity}</td>
                <td>{item?.unit?.name}</td>
                <td><MoneyFormat value={item?.net_price} decimal={2}/></td>
                <td>{item.date}</td>
                <td>{moment(item.created_at).format("DD.MM.YYYY")}</td>
                <td>
                    <div className="d-flex gap-2">
                        <Button
                            variant="outline-info"
                            size="sm"
                            className="d-flex align-items-center justify-content-center"
                            style={{ width: '32px', height: '32px', padding: 0 }}
                            as={Link}
                            to={`/productions/${item?.id}`}
                        >
                            <FontAwesomeIcon icon={faEye} />
                        </Button>
                        {
                            role_name === 'superadmin' || role_name === 'director' || role_name === 'manager' ? (
                                <Button
                                    variant="outline-primary"
                                    size="sm"
                                    className="d-flex align-items-center justify-content-center"
                                    style={{ width: '32px', height: '32px', padding: 0 }}
                                    onClick={() => setUpdateProduction(item?.id)}
                                >
                                    <FontAwesomeIcon icon={faEdit} />
                                </Button>
                            ) : ''
                        }
                        {
                            role_name === 'superadmin' || role_name === 'director' ? (
                                <Button
                                    variant="outline-danger"
                                    size="sm"
                                    className="d-flex align-items-center justify-content-center"
                                    style={{ width: '32px', height: '32px', padding: 0 }}
                                    onClick={() => setDeleteProduction(item?.id)}
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                </Button>
                            ) : ''
                        }
                    </div>
                </td>
            </tr>
        </Fragment>
    );
};

ProductionItem.defaultProps = {
    showActions: true,
};

ProductionItem.propTypes = {
    production: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    setDeleteProductionId: PropTypes.func.isRequired,
    setUpdateProductionId: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, {setDeleteProductionId, setUpdateProductionId})(
    ProductionItem
);
