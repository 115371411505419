import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Button from 'react-bootstrap/Button';
import Select from 'react-select'
import {
    addSale,
    clearErrors,
    getClientOptions,
    getProductOptions, getSpecialClientMaterialTransactions,
} from "../../../actions/sale";
import {Accordion} from "react-bootstrap";
import {ArrowLeft, X} from "react-feather";
import {Multiselect} from "multiselect-react-dropdown";
import {getSelectedOption} from "../../../utils/hooks/getParams";
import moment from "moment";
import {getFirmOptions} from "../../../actions/user";
import {getWarehouseOptions} from "../../../actions/product";
import {Input} from "reactstrap";
import { setAlert } from '../../../actions/alert';

const SpecialSaleCreateForm = ({onClose}) => {
    const {sale, user, product} = useSelector(state => state);
    const dispatch = useDispatch();
    const {
        client_options,
        product_options,
        special_client_materials,
        sales,
        show,
        error
    } = sale;
    const { firm_options } = user;
    const { warehouse_options } = product;

    useEffect(() => {
        dispatch(getClientOptions({with_supplier: true}))
        dispatch(getFirmOptions());
    }, []);

    useEffect(() => {
        if (show) {
            dispatch(getProductOptions({is_for_sale: true}));
        }
    }, [show])

    const formRef = useRef();
    const defaultValues = {
        is_special_sale: true,
        client_id: '',
        firm_id: '',
        warehouse_id: '',
        date: '',
        comment: '',
        transport_number: '',
        driver_name: '',
        items: [],
        materials_from_supplier: [],
    };

    const [selectedSpecialClient, setSelectedSpecialClient] = useState({});
    const [availableSupplierMaterials, setAvailableSupplierMaterials] = useState([])

    useEffect(() => {
        if (special_client_materials) {
            const items = [];
            special_client_materials?.forEach((item) => {
                    const itemData = {
                        checked: false,
                        material_id: item.material_id,
                        name: item.material_name,
                        balance: item.balance,
                        multiplier: item.multiplier,
                        unit_symbol: item.unit_symbol,
                    };
                    return items.push(itemData)
                }
            );
            setAvailableSupplierMaterials(items);
        }
    }, [special_client_materials]);

    const [firmOptions, setFirmOptions] = useState([]);
    const [warehouseOptions, setWarehouseOptions] = useState([]);
    const [productOptions, setProductOptions] = useState(product_options);
    const [selectedDynamicProductOptions, setSelectedDynamicProductOptions] = useState([]);
    const [selectedProductOptions, setSelectedProductOptions] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);
    const [inputs, setInputs] = useState(defaultValues);
    const [formErrors, setFormErrors] = useState({});

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    useEffect(() => {
        if (error) {
            setShowAlert(true);
            setAlertMessage(error);
        }
    }, [error]);

    const handleChange = e => {
        setFormErrors({});
        e.target.oldValue = e.target.value;
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}));
    };

    useEffect(() => {
        clearErrors();
        setFormErrors({});
        setInputs(defaultValues);
    }, [sales]);

    useEffect(() => {
        let items = [];
        firm_options.forEach((item) => items.push({
            label: `${item.name}`,
            value: item.id
        }));
        setFirmOptions(items);
    }, [firm_options]);

    useEffect(() => {
        let options = [];
        warehouse_options?.forEach((item) => options.push({
            label: item.name,
            value: item.id
        }));
        setWarehouseOptions(options);
    }, [warehouse_options]);

    const handleCloseForm = () => {
        clearErrors();
        setInputs(defaultValues);
        setFormErrors({});
        onClose();
        setProductOptions([]);
        setSelectedProductOptions([]);
    };

    useEffect(() => {
        product_options?.map(item => {
            item.net_price = item.price;
            item.price = 0;
            item.quantity = 0;
            return item
        });
        setProductOptions(product_options);
    }, [product_options]);

    useEffect(() => {
        let options = [];
        client_options?.forEach((item) => options.push({
            label: item.name,
            value: item.id
        }));
        setClientOptions(options);
    }, [client_options]);

    const onSelectProduct = (data) => {
        setSelectedDynamicProductOptions(data);
    };

    const onRemoveProduct = (data) => {
        setSelectedDynamicProductOptions(data)
    };

    const onClickMoveSelectedMaterials = () => {
        if (selectedDynamicProductOptions.length > 0) {
            setSelectedProductOptions([...selectedProductOptions, ...selectedDynamicProductOptions]);
            let ids = selectedDynamicProductOptions.map(({id}) => id);
            const filteredMaterials = productOptions.filter((item) => !ids.includes(item.id));
            setProductOptions(filteredMaterials);
            setSelectedDynamicProductOptions([]);
            const inputItems = [];
            selectedProductOptions.map(item => inputItems.push({
                id: item.id,
                quantity: item?.quantity || 0,
                price: item?.price || 0,
            }));
            inputs.items = [...(inputs.items || []), ...inputItems];
            setInputs(inputs);
        }
    };

    const handleSelect = (data, type) => {
        setFormErrors({});
        if (type.name === 'firm_id') {
            setWarehouseOptions([])
            dispatch(getWarehouseOptions({firm_id: data.value}));
        }
        if (type.name === 'client_id') {
            const specialClient = client_options?.find(item => item.id === data.value);
            dispatch(getSpecialClientMaterialTransactions(specialClient.supplier.id));
            setSelectedSpecialClient(specialClient);
        }
        if (type.name === 'warehouse_id') {
            dispatch(getProductOptions({warehouse_id: data.value}));
        }
        setInputs(prevState => ({...prevState, [type.name]: data.value}))
    };

    const handleSupplierMaterialSelection = (id) => {
        setAvailableSupplierMaterials(prevAvailableMaterials => {
            return prevAvailableMaterials.map(material => {
                if (material.material_id === id) {
                    return {
                        ...material,
                        checked: !material.checked
                    };
                }
                return material;
            });
        });
    };


    const onChangeInput = (e) => {
        let product_id = parseInt(e.target.dataset.id);
        const cleanItems = [];
        const items = selectedProductOptions.map((item) => {
            if (item.id === product_id) {
                if (e.target.getAttribute('name') === 'quantity') {
                    if (e.target.value !== '') {
                        if (Number(e.target.value) > 0) {
                            if (Number(e.target.value) <= item?.in_stock && item?.in_stock !== 0) {
                                item[e.target.getAttribute('name')] = e.target.value;
                            }
                        } else {
                            item[e.target.getAttribute('name')] = 1;
                        }
                    } else {
                        item[e.target.getAttribute('name')] = e.target.value;
                    }
                } else {
                    item[e.target.getAttribute('name')] = e.target.value;
                }
            }
            cleanItems.push({
                id: item.id,
                price: item?.price || 0,
                quantity: item?.quantity,
            });
            return item;
        });
        setSelectedProductOptions(items);
        inputs.items = cleanItems;
        setInputs(inputs);
    };

    const onRemoveSelectedMaterial = (id) => {
        const items = selectedProductOptions.filter((item) => item.id !== id);
        setSelectedProductOptions(items);
        const selectedItem = selectedProductOptions.find((item) => item.id === id);
        setProductOptions([...productOptions, selectedItem]);
        const cleanItems = [];
        items.map(item => cleanItems.push({
            id: item.id,
            price: item?.price || 0,
            quantity: item?.quantity,
        }));
        inputs.items = cleanItems;
        setInputs(inputs);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormErrors({});

        if (!inputs.firm_id) {
            setFormErrors(prev => ({ ...prev, firm_id: "Firma tanlash majburiy" }));
            return;
        }

        if (!inputs.client_id) {
            setFormErrors(prev => ({ ...prev, client_id: "Mijoz tanlash majburiy" }));
            return;
        }

        if (!inputs.warehouse_id) {
            setFormErrors(prev => ({ ...prev, warehouse_id: "Ombor tanlash majburiy" }));
            return;
        }

        if (!inputs.date) {
            setFormErrors(prev => ({ ...prev, date: "Sanani tanlash majburiy" }));
            return;
        }

        if (!inputs.transport_number) {
            setFormErrors(prev => ({ ...prev, transport_number: "Transport raqamini kiritish majburiy" }));
            return;
        }

        if (!inputs.driver_name) {
            setFormErrors(prev => ({ ...prev, driver_name: "Haydovchi ismini kiritish majburiy" }));
            return;
        }

        if (!inputs.items || !inputs.items.length) {
            dispatch(setAlert('Mahsulotlarni tanlang', 'danger'));
            return;
        }

        // Validate each product has quantity and price
        const invalidItems = inputs.items.filter(item => 
            !item.quantity || item.quantity <= 0 || 
            !item.price || item.price <= 0
        );
        if (invalidItems.length > 0) {
            dispatch(setAlert('Barcha mahsulotlar narxi va miqdori to\'g\'ri kiritilishi kerak', 'danger'));
            return;
        }
        
        const formData = {
            client_id: parseInt(inputs.client_id),
            firm_id: parseInt(inputs.firm_id),
            warehouse_id: parseInt(inputs.warehouse_id),
            transport_number: inputs.transport_number,
            driver_name: inputs.driver_name,
            is_special_sale: true,
            date: moment(inputs.date).format('YYYY-MM-DD'),
            comment: inputs.comment,
            items: (inputs.items || []).map(item => ({
                sellable_type: 'App\\Models\\Product',
                sellable_id: item.id,
                quantity: parseFloat(item.quantity),
                price: parseFloat(item.price)
            })),
            materials_from_supplier: availableSupplierMaterials
                .filter(item => item.checked)
                .map(item => ({
                    material_id: item.material_id,
                }))
        };

        const response = await dispatch(addSale(formData));
        
        if (response !== true) {
            return;
        }
        onClose();
    };

    return (
        <Fragment>
            <form ref={formRef} onSubmit={handleSubmit}>
                <div className="row g-3">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Firma</label>
                            <Select
                                value={getSelectedOption(inputs.firm_id, firmOptions)}
                                name="firm_id"
                                onChange={(data) => handleSelect(data, {name: 'firm_id'})}
                                options={firmOptions}
                                placeholder="Firmani tanlang"
                                className={formErrors.firm_id ? 'is-invalid' : ''}
                            />
                            {formErrors.firm_id && <div className="invalid-feedback">{formErrors.firm_id}</div>}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Ombor</label>
                            <Select
                                value={getSelectedOption(inputs.warehouse_id, warehouseOptions)}
                                name="warehouse_id"
                                onChange={(data) => handleSelect(data, {name: 'warehouse_id'})}
                                options={warehouseOptions}
                                placeholder="Omborni tanlang"
                                className={formErrors.warehouse_id ? 'is-invalid' : ''}
                            />
                            {formErrors.warehouse_id && <div className="invalid-feedback">{formErrors.warehouse_id}</div>}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Mijoz</label>
                            <Select
                                value={getSelectedOption(inputs.client_id, clientOptions)}
                                name="client_id"
                                onChange={(data) => handleSelect(data, {name: 'client_id'})}
                                options={clientOptions}
                                placeholder="Mijozni tanlang"
                                className={formErrors.client_id ? 'is-invalid' : ''}
                            />
                            {formErrors.client_id && <div className="invalid-feedback">{formErrors.client_id}</div>}
                            {selectedSpecialClient?.balance && (
                                <div className="mt-1">
                                    <small className="text-muted">
                                        Balans: {selectedSpecialClient.balance}
                                    </small>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="date">Sana</label>
                            <input
                                type="date"
                                name="date"
                                className={`form-control ${formErrors.date ? 'is-invalid' : ''}`}
                                value={inputs?.date || ''}
                                max={moment().format('YYYY-MM-DD')}
                                onChange={handleChange}
                            />
                            {formErrors.date && <div className="invalid-feedback">{formErrors.date}</div>}
                        </div>
                    </div>
                </div>

                {selectedSpecialClient?.supplier && (
                    <>
                        <div className="mb-3">
                            <label htmlFor="warehouse_id" className="form-label text-black bold">
                                Balansidagi materiallar
                            </label>
                            <div className="material_data_table_wrapper">
                                {availableSupplierMaterials?.length ? (
                                    <table className={'table table-striped table-responsive'}>
                                        <tbody>
                                        <tr>
                                            {availableSupplierMaterials.map((item, key) => (
                                                <td key={key} className={"w-25"}>
                                                    <div className={'form-group'}

                                                    >
                                                        <Input className={'form-check-input me-2'}
                                                               type={'checkbox'}
                                                               value={item.checked}
                                                               id={"supplier_material_" + item.material_id}
                                                               onClick={() => handleSupplierMaterialSelection(item.material_id)}
                                                               />
                                                        <label className={'form-check-label'}
                                                               htmlFor={"supplier_material_" + item.material_id}>
                                                            {item.name}
                                                        </label>
                                                    </div>
                                                </td>
                                            ))}
                                        </tr>
                                        <tr>
                                            {availableSupplierMaterials.map((item, key) => (
                                                <td key={key}>
                                                    {item.balance.toFixed(3)} {item.unit_symbol}
                                                </td>
                                            ))}
                                        </tr>
                                        </tbody>
                                    </table>
                                ) : (
                                    <div className={'text-muted'}>
                                        Balansida material mavjud emas
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                )}
                <div className="mt-4">
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                <div className="d-flex align-items-center">
                                    <span className="me-2">Mahsulotlar</span>
                                    {selectedProductOptions.length > 0 && (
                                        <div className="badge bg-primary">{selectedProductOptions.length}</div>
                                    )}
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="row g-3">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <Multiselect
                                                options={productOptions}
                                                selectedValues={selectedDynamicProductOptions}
                                                onSelect={onSelectProduct}
                                                onRemove={onRemoveProduct}
                                                displayValue="name"
                                                placeholder="Mahsulotlarni tanlang"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="d-flex justify-content-end">
                                            <Button
                                                variant="primary"
                                                onClick={onClickMoveSelectedMaterials}
                                                disabled={selectedDynamicProductOptions.length === 0}
                                            >
                                                <ArrowLeft size={18}/> Qo'shish
                                            </Button>
                                        </div>
                                    </div>
                                </div>

                                {selectedProductOptions.length > 0 && (
                                    <div className="mt-4">
                                        <div className="table-responsive">
                                            <table className="table table-bordered">
                                                <thead>
                                                <tr>
                                                    <th>Nomi</th>
                                                    <th>Omborda</th>
                                                    <th>Miqdori</th>
                                                    <th>Sotuv Narxi</th>
                                                    <th>Sof narxi</th>
                                                    <th>Jami</th>
                                                    <th>Amallar</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {selectedProductOptions.map((product, key) => (
                                                    <tr key={key}>
                                                        <td>{product.name}</td>
                                                        <td>{product.in_stock || 0}</td>
                                                        <td>
                                                            <input
                                                                type="string"
                                                                className={`form-control form-control-sm ${formErrors.items && formErrors.items[product.id] && formErrors.items[product.id].quantity ? 'is-invalid' : ''}`}
                                                                name="quantity"
                                                                data-id={product.id}
                                                                value={product?.quantity || ''}
                                                                onChange={onChangeInput}
                                                            />
                                                            {formErrors.items && formErrors.items[product.id] && formErrors.items[product.id].quantity && <div className="invalid-feedback">{formErrors.items[product.id].quantity}</div>}
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="number"
                                                                className={`form-control form-control-sm ${formErrors.items && formErrors.items[product.id] && formErrors.items[product.id].price ? 'is-invalid' : ''}`}
                                                                value={product?.price || ''}
                                                                name="price"
                                                                data-id={product.id}
                                                                onChange={onChangeInput}
                                                            />
                                                            {formErrors.items && formErrors.items[product.id] && formErrors.items[product.id].price && <div className="invalid-feedback">{formErrors.items[product.id].price}</div>}
                                                        </td>
                                                        <td>{product?.in_stock > 0 ? product?.net_price?.toLocaleString() : 0} so'm</td>
                                                        <td>
                                                            {product?.quantity && product?.price
                                                                ? (product.quantity * product.price).toLocaleString()
                                                                : 0} so'm
                                                        </td>
                                                        <td>
                                                            <button
                                                                type="button"
                                                                className="btn btn-danger btn-sm"
                                                                onClick={() => onRemoveSelectedMaterial(product.id)}
                                                            >
                                                                <X size={14}/>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>


                <div className="row g-3 mt-2 mb-2">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="transport_number">Transport raqami</label>
                            <input
                                type="text"
                                name="transport_number"
                                className={`form-control ${formErrors.transport_number ? 'is-invalid' : ''}`}
                                value={inputs?.transport_number || ''}
                                onChange={handleChange}
                                placeholder="Transport raqamini kiriting"
                            />
                            {formErrors.transport_number && <div className="invalid-feedback">{formErrors.transport_number}</div>}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="driver_name">Haydovchi</label>
                            <input
                                type="text"
                                name="driver_name"
                                className={`form-control ${formErrors.driver_name ? 'is-invalid' : ''}`}
                                value={inputs?.driver_name || ''}
                                onChange={handleChange}
                                placeholder="Haydovchi ismini kiriting"
                            />
                            {formErrors.driver_name && <div className="invalid-feedback">{formErrors.driver_name}</div>}
                        </div>
                    </div>
                </div>

                <div className="mb-3">
                    <label htmlFor="description">Izoh</label>
                    <textarea
                        name="description"
                        className="form-control"
                        value={inputs?.description || ''}
                        placeholder="Sotuv uchun izoh"
                        onChange={handleChange}
                        rows="3"
                    />
                    <div className="error">
                        {formErrors?.description}
                    </div>
                </div>

                <div className="mt-4 d-flex justify-content-end">
                    <Button variant="secondary" onClick={handleCloseForm} className="me-2">
                        Bekor qilish
                    </Button>
                    <Button variant="primary" type="submit">
                        Saqlash
                    </Button>
                </div>
            </form>
        </Fragment>
    );
};

export default SpecialSaleCreateForm;
