import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Space, message } from 'antd';
import axios from 'axios';

const { Option } = Select;
const { TextArea } = Input;

const PageForm = ({ initialValues, onSave, onCancel }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [availablePermissions, setAvailablePermissions] = useState([]);

    useEffect(() => {
        fetchAvailablePermissions();
        if (initialValues) {
            form.setFieldsValue({
                ...initialValues,
                permissions: initialValues.permissions?.map(p => p.id) || []
            });
        }
    }, [initialValues, form]);

    const fetchAvailablePermissions = async () => {
        try {
            const response = await axios.get('/permissions');
            setAvailablePermissions(response.data.data || []);
        } catch (error) {
            message.error('Failed to fetch permissions');
        }
    };

    const handleSubmit = async (values) => {
        setLoading(true);
        try {
            await onSave(values);
            form.resetFields();
        } catch (error) {
            console.error('Error saving page:', error);
        }
        setLoading(false);
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
        >
            <Form.Item
                name="name"
                label="Page Name"
                rules={[
                    { required: true, message: 'Please input page name!' },
                    {
                        pattern: /^[a-z0-9-_]+$/,
                        message: 'Page name should contain only lowercase letters, numbers, hyphens and underscores!'
                    }
                ]}
                help="Use lowercase letters, numbers, hyphens and underscores (e.g., 'user-management', 'reports_section')"
            >
                <Input placeholder="e.g., user-management" />
            </Form.Item>

            <Form.Item
                name="display_name"
                label="Display Name"
                rules={[{ required: true, message: 'Please input display name!' }]}
                help="This is how the page name will be displayed in the UI"
            >
                <Input placeholder="e.g., User Management" />
            </Form.Item>

            <Form.Item
                name="description"
                label="Description"
                help="Brief description of what this page is for"
            >
                <TextArea 
                    placeholder="e.g., Manage system users and their roles" 
                    rows={3}
                />
            </Form.Item>

            <Form.Item
                name="permissions"
                label="Associated Permissions"
                help="Select permissions that belong to this page"
            >
                <Select
                    mode="multiple"
                    placeholder="Select permissions"
                    style={{ width: '100%' }}
                    optionFilterProp="children"
                    showSearch
                >
                    {availablePermissions.map(permission => (
                        <Option key={permission.id} value={permission.id}>
                            {permission.name}
                        </Option>
                    ))}
                </Select>
            </Form.Item>

            <Form.Item>
                <Space>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        {initialValues ? 'Update' : 'Create'}
                    </Button>
                    <Button onClick={onCancel}>Cancel</Button>
                </Space>
            </Form.Item>
        </Form>
    );
};

export default PageForm;
