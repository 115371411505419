import React from "react";
import moment from "moment";

export default function useDates(yearMonth = null) {
  const today = moment();
  let selectedDate = yearMonth ? moment(yearMonth, 'YYYY-MM') : moment();
  let firstDay = selectedDate.clone().startOf('month');
  let endDay = selectedDate.clone().endOf('month');
  
  // If selected month is current month or future month, use today as the end date
  if (selectedDate.isSameOrAfter(today, 'month')) {
    endDay = today;
  }
  
  let currentDay = firstDay.clone();
  const result = [];

  while (currentDay.isSameOrBefore(endDay)) {
    result.push(currentDay.format('YYYY-MM-DD'));
    currentDay.add(1, 'day');
  }

  const [dates, setDates] = React.useState(result);

  function updateDates(newYearAndMonth) {
    const today = moment();
    let selectedDate = moment(newYearAndMonth, 'YYYY-MM');
    let firstDay = selectedDate.clone().startOf('month');
    let endDay = selectedDate.clone().endOf('month');
    
    // If selected month is current month or future month, use today as the end date
    if (selectedDate.isSameOrAfter(today, 'month')) {
      endDay = today;
    }
    
    let currentDay = firstDay.clone();
    const newDates = [];

    while (currentDay.isSameOrBefore(endDay)) {
      newDates.push(currentDay.format('YYYY-MM-DD'));
      currentDay.add(1, 'day');
    }
    
    setDates(newDates);
  }

  return {dates, updateDates};
}