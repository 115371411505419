import React, { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import { formatDate } from '../../../utils/dateHelper';
import SpecialMaterialSaleCreateForm from '../forms/SpecialMaterialSaleCreateForm';
import SpecialMaterialSaleUpdateForm from '../forms/SpecialMaterialSaleUpdateForm';
import DeleteConfirmationModal from '../../common/DeleteConfirmationModal';

const SupplierSpecialMaterialSales = ({ supplier }) => {
    const [specialMaterialSales, setSpecialMaterialSales] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [showUpdateForm, setShowUpdateForm] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedSale, setSelectedSale] = useState(null);
    const [deleteLoading, setDeleteLoading] = useState(false);

    useEffect(() => {
        if (supplier?.id) {
            fetchSpecialMaterialSales();
        }
    }, [supplier]);

    const fetchSpecialMaterialSales = async () => {
        try {
            const response = await axios.get(`/suppliers/${supplier.id}/special-material-sales`);
            setSpecialMaterialSales(response.data.data);
            setLoading(false);
        } catch (error) {
            toast.error('Maxsus material chiqimlarni yuklashda xatolik yuz berdi');
            setLoading(false);
        }
    };

    const handleShowCreateForm = () => setShowCreateForm(true);
    const handleCloseCreateForm = () => setShowCreateForm(false);

    const handleShowUpdateForm = (sale) => {
        setSelectedSale(sale);
        setShowUpdateForm(true);
    };
    const handleCloseUpdateForm = () => {
        setSelectedSale(null);
        setShowUpdateForm(false);
    };

    const handleShowDeleteModal = (sale) => {
        setSelectedSale(sale);
        setShowDeleteModal(true);
    };
    const handleCloseDeleteModal = () => {
        setSelectedSale(null);
        setShowDeleteModal(false);
    };

    const handleDelete = async () => {
        setDeleteLoading(true);
        try {
            await axios.delete(`/suppliers/${supplier.id}/special-material-sales/${selectedSale.id}`);
            toast.success('Maxsus material chiqim muvaffaqiyatli o\'chirildi');
            handleCloseDeleteModal();
            fetchSpecialMaterialSales();
        } catch (error) {
            toast.error('O\'chirishda xatolik yuz berdi');
        } finally {
            setDeleteLoading(false);
        }
    };

    if (loading) {
        return <div>Yuklanmoqda...</div>;
    }

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h4>Maxsus material chiqimlar</h4>
                <Button variant="primary" onClick={handleShowCreateForm}>
                    + Yangi maxsus material chiqim
                </Button>
            </div>

            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Firma</th>
                        <th>Material</th>
                        <th>Miqdori</th>
                        <th>Narxi</th>
                        <th>Sana</th>
                        <th>Amallar</th>
                    </tr>
                </thead>
                <tbody>
                    {specialMaterialSales.map((sale, index) => (
                        <tr key={sale.id}>
                            <td>{index + 1}</td>
                            <td>{sale.material?.warehouse?.firm?.name}</td>
                            <td>{sale.material?.name}</td>
                            <td>{sale.quantity}</td>
                            <td>{sale.price}</td>
                            <td>{formatDate(sale.created_at)}</td>
                            <td>
                                <Button 
                                    variant="warning" 
                                    size="sm" 
                                    className="me-2"
                                    onClick={() => handleShowUpdateForm(sale)}
                                >
                                    O'zgartirish
                                </Button>
                                <Button 
                                    variant="danger" 
                                    size="sm"
                                    onClick={() => handleShowDeleteModal(sale)}
                                >
                                    O'chirish
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <SpecialMaterialSaleCreateForm
                show={showCreateForm}
                handleClose={handleCloseCreateForm}
                supplier={supplier}
                onSuccess={fetchSpecialMaterialSales}
            />

            <SpecialMaterialSaleUpdateForm
                show={showUpdateForm}
                handleClose={handleCloseUpdateForm}
                supplier={supplier}
                sale={selectedSale}
                onSuccess={fetchSpecialMaterialSales}
            />

            <DeleteConfirmationModal
                show={showDeleteModal}
                handleClose={handleCloseDeleteModal}
                handleConfirm={handleDelete}
                loading={deleteLoading}
                title="Maxsus material chiqimni o'chirish"
                message={`Siz rostdan ham ushbu maxsus material chiqimni o'chirmoqchimisiz?${
                    selectedSale 
                        ? `\nMaterial: ${selectedSale.material?.name}` 
                        : ''
                }`}
            />
        </div>
    );
};

export default SupplierSpecialMaterialSales;
