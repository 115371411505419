import React, {useEffect, useState} from "react";
import CurrencyFormat from "react-currency-format";
import { getSupplierTransactions } from "../../../actions/suppliers_transaction";
import {useDispatch, useSelector} from "react-redux";
import TransactionDeleteModal from "./TransactionDeleteModal";

const SupplierTranscations = ({supplier}) => {
    const dispatch = useDispatch();
    const supplier_transactions = useSelector(state => state.suppliers_transaction.supplier_transactions);
    const [deleteModal, setDeleteModal] = useState({ show: false, transaction: null });

    const {id} = supplier;
    useEffect(() => {
        if (id) {
            dispatch(getSupplierTransactions(id, {}));
        }
    }, [dispatch, id]);

    const [from_date, setFromDate] = useState('');
    const [to_date, setToDate] = useState('');

    const onChangeFromDate = (event) => {
        setFromDate(state => event.target.value);
        const params = {};
        params.to_date = to_date;
        params.from_date = event.target.value;
        dispatch(getSupplierTransactions(supplier.id, params))
    };

    const onChangeToDate = (event) => {
        setToDate(state => event.target.value);
        const params = {};
        params.to_date = event.target.value;
        params.from_date = from_date;
        dispatch(getSupplierTransactions(supplier.id, params))
    };

    const handleShowDeleteModal = (transaction) => {
        setDeleteModal({ show: true, transaction });
    };

    const handleCloseDeleteModal = () => {
        setDeleteModal({ show: false, transaction: null });
    };

    const getCurrentFilters = () => ({
        to_date: to_date,
        from_date: from_date
    });

    return (
        <div className="w-100 table-responsive px-2">
            <div className="mb-2 align-items-center d-flex justify-content-between">
                <div className="mb-3">
                    <label htmlFor="date1" className="mb-2">dan</label>
                    <input onChange={onChangeFromDate} className="form-control"
                           id="date1" type="date"/>
                </div>

                <div className="mb-3">
                    <label htmlFor="date2" className="mb-2">gacha</label>
                    <input onChange={onChangeToDate} className="form-control"
                           id="date2" type="date"/>
                </div>
            </div>

            <table className="table custom-table table-hover">
                <thead className="table-dark">
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Olingan summa</th>
                    <th scope="col">To'langan summa</th>
                    <th scope="col">Sana</th>
                    <th scope="col">Amallar</th>
                </tr>
                </thead>
                <tbody>
                {
                    supplier_transactions?.map((item, index) => (
                        <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <th>
                                <CurrencyFormat value={item.received_amount}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                suffix={"  so'm"}/>
                            </th>
                            <th>
                                <CurrencyFormat value={item.paid_amount}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                suffix={"  so'm"}/>
                            </th>
                            <th>{item.date}</th>
                            <th>
                                <button 
                                    onClick={() => handleShowDeleteModal(item)}
                                    className="btn btn-danger btn-sm">
                                    O'chirish
                                </button>
                            </th>
                        </tr>
                    ))
                }
                </tbody>
            </table>

            <TransactionDeleteModal
                show={deleteModal.show}
                onHide={handleCloseDeleteModal}
                transaction={deleteModal.transaction}
                supplierId={supplier.id}
                filters={getCurrentFilters()}
            />
        </div>
    )
}

export default SupplierTranscations;