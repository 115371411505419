import React from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import "./Sidebar.css";

const Sidebar = ({ auth: { user, permissions }, sidebar: { menu, isShown, isExpanded } }) => {
    const location = useLocation();
    
    if (!isShown) return null;

    const hasPermission = (permission) => {
        if (user?.role_name === 'superadmin') return true;
        
        // Check if the user's role has this permission
        const userRole = permissions?.roles?.find(role => role.name === user?.role_name);
        return userRole?.permissions?.some(p => p.name === permission) || false;
    };

    const getRequiredPermissionForRoute = (route) => {
        // Remove leading slash
        const path = route.replace('/', '');

        // Special cases
        const specialRoutes = {
            'dashboard': 'view dashboard',
            'roles': 'view roles',
            'permissions': 'view permissions',
            'pages': 'view pages',
            'firms': 'view firms',
            'employees': 'view employees',
            'employee-calculations': 'view employee calculations',
            'warehouses': 'view warehouses',
            'spare-parts': 'view spare-parts',
            'transports': 'view transports',
            'expense-templates': 'view expense templates',
            'units': 'view units',
            'productions': 'view productions',
            'expenses': 'view expenses',
            'supplier-material-calculations': 'view supplier material calculations',
            'product-material-calculations': 'view product material calculations',
            'material-calculations': 'view material calculations',
            'product-calculations': 'view product calculations',
            'supplier-calculations': 'view supplier calculations',
            'client-calculations': 'view client calculations'
        };

        // Return special permission if route exists in mapping
        if (specialRoutes[path]) {
            return specialRoutes[path];
        }

        // Default case: view + route name
        return `view ${path}`;
    };

    const getMenuItems = () => {
        return menu
            .filter(item => {
                // Always show sections if the user has the role
                if (item.is_section) {
                    return item.roles?.includes(user?.role_name) && item.is_visible;
                }

                // For regular menu items, check both role and permission
                const hasRole = item.roles?.includes(user?.role_name);
                const requiredPermission = getRequiredPermissionForRoute(item.route.replace('/', ''));
                
                return hasRole && item.is_visible && hasPermission(requiredPermission);
            })
            .map(item => {
                if (item.is_section) {
                    return {
                        type: 'group',
                        label: item.name,
                        key: item.name
                    };
                }
                
                return {
                    key: item.route,
                    icon: item.icon_tag,
                    label: <Link to={item.route}>{item.name}</Link>
                };
            })
            .filter((item, index, array) => {
                // Remove empty groups
                if (item.type === 'group') {
                    // Find the next group or end of array
                    const nextGroupIndex = array.findIndex((nextItem, i) => 
                        i > index && nextItem.type === 'group'
                    );
                    const end = nextGroupIndex === -1 ? array.length : nextGroupIndex;
                    // Check if there are any items between this group and the next
                    return array.slice(index + 1, end).some(i => i.type !== 'group');
                }
                return true;
            });
    };

    // Get the current selected key based on location
    const getSelectedKey = () => {
        const pathname = location.pathname;
        // If we're at root, select dashboard
        if (pathname === '/') return '/dashboard';
        // Otherwise use the current path
        return pathname;
    };

    return (
        <nav id="sidebar" data-include="sidebar"
             className={`sidebar js-sidebar ${!isExpanded ? 'sidebar-hidden' : ''} `}>
            <div className="sidebar-content">
                <div className="sidebar-brand">
                    <span className="align-middle">Boshqaruv menyu</span>
                </div>

                <Menu
                    mode="inline"
                    theme="dark"
                    selectedKeys={[getSelectedKey()]}
                    items={getMenuItems()}
                />
            </div>
        </nav>
    );
};

const mapStateToProps = state => ({
    auth: state.auth,
    sidebar: state.sidebar
});

export default connect(mapStateToProps)(Sidebar);
