import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Space, message, Checkbox, Card, Row, Col } from 'antd';
import axios from 'axios';

const RoleForm = ({ initialValues, onSave, onCancel }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [availablePermissions, setAvailablePermissions] = useState([]);
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        fetchAvailablePermissions();
    }, []);

    useEffect(() => {
        if (initialValues) {
            form.setFieldsValue({
                name: initialValues.name,
                title: initialValues.title
            });
            setSelectedPermissions(initialValues.permissions?.map(p => p.id) || []);
        } else {
            form.resetFields();
            setSelectedPermissions([]);
        }
        setErrors({});
    }, [initialValues, form]);

    const fetchAvailablePermissions = async () => {
        try {
            const response = await axios.get('/roles/available-permissions');
            setAvailablePermissions(response.data.data.pages || []);
        } catch (error) {
            message.error('Failed to fetch available permissions');
        }
    };

    const handlePermissionChange = (permissionId, checked) => {
        setSelectedPermissions(prev => {
            if (checked) {
                return [...prev, permissionId];
            } else {
                return prev.filter(id => id !== permissionId);
            }
        });
    };

    const handleGroupChange = (permissions, checked) => {
        setSelectedPermissions(prev => {
            if (checked) {
                const newPermissions = [...prev];
                permissions.forEach(permission => {
                    if (!newPermissions.includes(permission.id)) {
                        newPermissions.push(permission.id);
                    }
                });
                return newPermissions;
            } else {
                return prev.filter(id => !permissions.some(p => p.id === id));
            }
        });
    };

    const handleSubmit = async (values) => {
        setLoading(true);
        setErrors({});
        try {
            await onSave({
                ...values,
                guard_name: 'api',
                permissions: selectedPermissions
            });
            form.resetFields();
            setSelectedPermissions([]);
        } catch (error) {
            if (error.response?.data?.errors) {
                const apiErrors = error.response.data.errors;
                setErrors(apiErrors);
                form.setFields(
                    Object.keys(apiErrors).map(field => ({
                        name: field,
                        errors: apiErrors[field]
                    }))
                );
            }
            console.error('Error saving role:', error);
        }
        setLoading(false);
    };

    const isGroupChecked = (permissions) => {
        return permissions.every(permission => 
            selectedPermissions.includes(permission.id)
        );
    };

    const isGroupIndeterminate = (permissions) => {
        const selectedCount = permissions.filter(permission => 
            selectedPermissions.includes(permission.id)
        ).length;
        return selectedCount > 0 && selectedCount < permissions.length;
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
        >
            <Form.Item
                name="name"
                label="Role Name"
                rules={[{ required: true, message: 'Please input role name!' }]}
                validateStatus={errors.name ? 'error' : ''}
                help={errors.name?.[0]}
            >
                <Input 
                    placeholder="e.g., admin, editor" 
                    disabled={!!initialValues}
                />
            </Form.Item>

            <Form.Item
                name="title"
                label="Display Title"
                rules={[{ required: true, message: 'Please input role title!' }]}
                validateStatus={errors.title ? 'error' : ''}
                help={errors.title?.[0]}
            >
                <Input placeholder="e.g., Administrator, Editor" />
            </Form.Item>

            <Form.Item
                label="Permissions"
                validateStatus={errors.permissions ? 'error' : ''}
                help={errors.permissions?.[0] || "Select permissions for this role"}
            >
                <Row gutter={[16, 16]}>
                    {availablePermissions.map(page => (
                        <Col span={24} key={page.id}>
                            <Card 
                                title={
                                    <Checkbox
                                        indeterminate={isGroupIndeterminate(page.permissions)}
                                        checked={isGroupChecked(page.permissions)}
                                        onChange={(e) => handleGroupChange(page.permissions, e.target.checked)}
                                    >
                                        {page.display_name}
                                    </Checkbox>
                                }
                                size="small"
                            >
                                <Row>
                                    {page.permissions.map(permission => (
                                        <Col span={12} key={permission.id}>
                                            <Checkbox
                                                checked={selectedPermissions.includes(permission.id)}
                                                onChange={(e) => handlePermissionChange(permission.id, e.target.checked)}
                                            >
                                                {permission.name}
                                            </Checkbox>
                                        </Col>
                                    ))}
                                </Row>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Form.Item>

            <Form.Item>
                <Space>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        {initialValues ? 'Update' : 'Create'}
                    </Button>
                    <Button onClick={onCancel}>Cancel</Button>
                </Space>
            </Form.Item>
        </Form>
    );
};

export default RoleForm;
