import moment from "moment";
import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {downloadProductReport} from "../../actions/products_transaction";
import {getParams} from "../../utils/hooks/getParams";
import DatePicker from "react-datepicker";
import {getFirmOptions} from "../../actions/user";
import {setAlert} from "../../actions/alert";

const ProductReport = () => {
    const dispatch = useDispatch();
    const params = getParams();
    const [type, setType] = useState('');
    const [fromDate, setFromDate] = useState(moment().format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
    const [month, setMonth] = useState(moment().format('YYYY-MM'));
    const [year, setYear] = useState(new Date().getFullYear());
    const [selectedYear, setSelectedYear] = useState(new Date());
    const [selectedFirm, setSelectedFirm] = useState(params.firm_id || '');
    const firms = useSelector(state => state.user.firm_options);
    const [fileFormat, setFileFormat] = useState('pdf');

    useEffect(() => {
        dispatch(getFirmOptions());
    }, [dispatch]);

    const handleYear = (date) => {
        setYear(date.getFullYear())
        setSelectedYear(date)
    }

    const onDownload = async (e) => {
        e.preventDefault();
        if (!selectedFirm) {
            dispatch(setAlert("Firma tanlash majburiy!", 'danger'));
            return;
        }
        const params = getParams();
        let data = {};
        switch (type) {
            case 'range':
                data = {period_type: 'range', from_date: fromDate, to_date: toDate, format: fileFormat};
                break;
            case 'monthly':
                data = {period_type: 'monthly', month: month, format: fileFormat};
                break;
            case 'yearly':
                data = {period_type: 'yearly', year: year.toString(), format: fileFormat};
                break;
        }
        if (selectedFirm) {
            data.firm_id = selectedFirm;
        }
        data = {...params, ...data};
        const link = await dispatch(downloadProductReport(data));

        if (link !== false && link !== undefined)
            window.open(link);
    }
    return (
        <form className="row g-3">
            <div className="col-auto">
                <label htmlFor="firm" className="visually-hidden">Firma</label>
                <select
                    className={'form-control form-select'}
                    value={selectedFirm}
                    onChange={e => setSelectedFirm(e.target.value)}
                >
                    <option value="">Firma tanlang</option>
                    {firms && firms.map(firm => (
                        <option key={firm.id} value={firm.id}>
                            {firm.name}
                        </option>
                    ))}
                </select>
            </div>

            <div className="col-auto">
                <label htmlFor="to_date" className="visually-hidden">Hisobot turi</label>
                <select className={'form-control form-select'}
                        value={type}
                        onChange={e => setType(e.target.value)}
                >
                    <option value="">Hisobot turi</option>
                    <option value="range">Dan-Gacha</option>
                    <option value="monthly">Oylik</option>
                    <option value="yearly">Yillik</option>
                </select>
            </div>

            {
                'range' === type && (
                    <>
                        <div className="col-auto">
                            <label htmlFor="from_date" className="visually-hidden">Dan</label>
                            <input type="date" className="form-control" id="from_date"
                                   onChange={e => setFromDate(e.target.value)}
                                   max={moment(new Date()).format('YYYY-MM-DD')}
                                   value={fromDate}
                            />
                        </div>
                        <div className="col-auto">
                            <label htmlFor="to_date" className="visually-hidden">Gacha</label>
                            <input type="date" className="form-control" id="to_date"
                                   onChange={e => setToDate(e.target.value)}
                                   max={moment(new Date()).format('YYYY-MM-DD')}
                                   value={toDate}
                            />
                        </div>
                    </>
                )
            }
            {
                'monthly' === type && (
                    <>
                        <div className="col-auto">
                            <label htmlFor="month" className="visually-hidden">Dan</label>
                            <input type="month" className="form-control" id="month"
                                   onChange={e => setMonth(e.target.value)}
                                   max={moment(new Date()).format('YYYY-MM')}
                                   value={month}
                            />
                        </div>
                    </>
                )
            }
            {
                'yearly' === type && (
                    <>
                        <div className="col-auto">
                            <label htmlFor="year" className="visually-hidden">Dan</label>
                            <DatePicker
                                className={'form-control'}
                                type="year"
                                selected={selectedYear}
                                onChange={(date) => handleYear(date)}
                                dateFormat="yyyy"
                                maxDate={moment(new Date()).format('YYYY-MM-DD')}
                                showYearPicker
                                showYearDropdown={true}
                                showFourColumnMonthYearPicker={true}
                            />
                        </div>
                    </>
                )
            }

            {
                type && (
                    <div className="col-auto">
                        <label htmlFor="fileFormat" className="visually-hidden">Fayl formati</label>
                        <select
                            className={'form-control form-select'}
                            value={fileFormat}
                            onChange={e => setFileFormat(e.target.value)}
                        >
                            <option value="pdf">PDF</option>
                            <option value="excel">Excel</option>
                        </select>
                    </div>
                )
            }

            {
                type && (
                    <div className="col-auto">
                        <button onClick={onDownload} className="btn btn-primary">
                            Yuklab olish
                        </button>
                    </div>
                )
            }
        </form>
    )
}

export default ProductReport;