import {Link} from "react-router-dom";
import React, { useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {formatMoney} from "../../utils/hooks/getParams";
import {getEmployeeTransactions} from "../../actions/employees_transaction";
import EmployeeDataCell from "./EmployeeDataCell";

const EmployeeDataRow = ({index, item, resetAllRows, dates}) => {
    const dispatch = useDispatch();

    const {
        auth
    } = useSelector(state => state);
    const {role_name} = auth?.user || {};

    const [itemData, setItemData] = useState({
        transactions: []
    });
    const [rowData, setRowData] = useState([]);

    useEffect(() => {
        setItemData(item);
    }, [item])

    useEffect(() => {
        setRowData(itemData.transactions ?? []);
    }, [itemData]);

    const refreshRow = (id) => {
        dispatch(getEmployeeTransactions(id));
    }

    const getCellData = (date) => {
        const foundDateItem = rowData.find(item => item.date === date);
        if (foundDateItem){
            return {
                earning_amount: foundDateItem.earning_amount,
                payment_amount: foundDateItem.payment_amount,
                date: foundDateItem.date,
            }
        }
        return {
            earning_amount: 0,
            payment_amount: 0,
            date,
        }
    }

    const [dateData, setDateData] = useState();

    return (
        <tr>
            <th className="sticky-col">{index + 1}</th>
            <th className="sticky-col2">
                <Link to={`employees/${itemData.id}`}>
                    {itemData.first_name} {itemData.last_name}
                </Link>
            </th>

            {
                dates?.map((date, subindex) => (
                    <EmployeeDataCell
                        key={subindex}
                        object_id={item.id}
                        date={date}
                        index={index}
                        cellData={getCellData(date)}
                        rowData={rowData}
                        refreshRow={refreshRow}
                    />))
            }
            <th style={{textAlign: "center", minWidth: "100px", right: "300px"}}>
                {formatMoney(itemData.prev_month_left_amount)}
            </th>
            <th style={{textAlign: "center", minWidth: "100px", right: "200px"}}>
                {formatMoney(itemData.transactions_sum_earning_amount, ',')}
            </th>
            <th style={{textAlign: "center", minWidth: "100px", right: "100px"}}>
                {formatMoney(itemData.transactions_sum_payment_amount, ',')}
            </th>
            <th style={{textAlign: "center", minWidth: "100px", right: "0px"}}>
                {formatMoney(itemData.current_month_left_amount)}
            </th>
        </tr>
    )
}

export default EmployeeDataRow;