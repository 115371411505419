import React, {useEffect, useState} from "react";
import CurrencyFormat from "react-currency-format";
import moment from "moment/moment";
import { Trash2} from "react-feather";
import { getSupplierMaterialImports, setDeleteMaterialImport} from "../../../actions/supplier";
import {useDispatch} from "react-redux";
import SupplierMaterialImportDeleteForm from "../SupplierMaterialImportDeleteForm";

const SupplierMaterialImports = ({supplier, supplier_material_imports}) => {

    const dispatch = useDispatch();

    const {id} = supplier;
    useEffect(() => {
        if (id) {
            dispatch(getSupplierMaterialImports(id, {}));
        }
    }, [getSupplierMaterialImports, id]);


    const [from_date, setFromDate] = useState('');
    const [to_date, setToDate] = useState('');

    const onChangeFromDate = (event) => {
        setFromDate(() => event.target.value);
        const params = {};
        params.to_date = to_date;
        params.from_date = event.target.value;
        dispatch(getSupplierMaterialImports(supplier.id, params))
    };

    const onChangeToDate = (event) => {
        setToDate(() => event.target.value);
        const params = {};
        params.to_date = event.target.value;
        params.from_date = from_date;
        dispatch(getSupplierMaterialImports(supplier.id, params))
    };

    const onDeleteMaterialImport = (import_id) => {
        dispatch(setDeleteMaterialImport(import_id));
    };
    return (
        <div className="w-100 table-responsive px-2">

            <div className="row mb-3">
                <div className="col-md-6"></div>
                <div className="col-md-6">
                    <div className="crud_actions text-end">
                        {/*<button className="btn btn-primary"*/}
                        {/*        onClick={() => handleShowMaterial()}>*/}
                        {/*    Material import*/}
                        {/*    <Plus/>*/}
                        {/*</button>*/}
                    </div>
                </div>
            </div>


            <div
                className="mb-2 align-items-center d-flex justify-content-between">

                <div className="mb-3">
                    <label htmlFor="date1" className="mb-2">dan</label>
                    <input onChange={onChangeFromDate}
                           className="form-control"
                           id="date1" type="date"/>
                </div>

                <div className="mb-3">
                    <label htmlFor="date2" className="mb-2">gacha</label>
                    <input onChange={onChangeToDate}
                           className="form-control"
                           id="date2" type="date"/>
                </div>
            </div>


            <table className="table custom-table table-hover">
                <thead className="table-dark">
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Material nomi</th>
                    <th scope="col">Maxsus Import?</th>
                    <th scope="col">Miqdori</th>
                    <th scope="col">Narxi</th>
                    <th scope="col">Summa</th>
                    <th scope="col">Sana</th>
                    <th scope="col">Amallar</th>
                </tr>
                </thead>
                <tbody>
                {
                    supplier_material_imports?.map((item, index) => (
                        <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <th>{item.material?.name}</th>
                            <td>{item.is_for_special_sale ? 'Ha' : 'Yo\'q'}</td>
                            <th>
                                {item.quantity}
                            </th>
                            <td>
                                <CurrencyFormat value={item.price}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                suffix={"  so'm"}/>
                            </td>
                            <td>
                                <CurrencyFormat
                                    value={item.quantity * item.price}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    suffix={"  so'm"}/>

                            </td>
                            <td>{moment(item.date).format('YYYY-MM-DD')}</td>
                            <td>
                                <div className="actions">
                                    <Trash2
                                        onClick={() => onDeleteMaterialImport(item.id)}
                                    />
                                </div>
                            </td>


                        </tr>
                    ))
                }
                {
                    <tr key={'total'}>
                        <th scope="row">#</th>
                        <th>*</th>
                        <th>*</th>
                        <th>
                            {supplier_material_imports.reduce((acc, item) => (acc + parseFloat(item.quantity)), 0)}
                        </th>
                        <td>
                            *
                        </td>
                        <td>
                            <CurrencyFormat
                                value={supplier_material_imports.reduce((acc, item) => (acc + parseFloat(item.quantity) * parseInt(item.price)), 0)}
                                displayType={'text'}
                                thousandSeparator={true}
                                suffix={"  so'm"}/>

                        </td>
                        <td>*</td>

                        <td>
                            *
                        </td>


                    </tr>
                }
                </tbody>
            </table>
            <SupplierMaterialImportDeleteForm supplier_id={id}/>
        </div>
    )
}

export default SupplierMaterialImports;