import axios from 'axios';
import {clearAlerts, setAlert} from './alert';
import setAuthToken from '../utils/setAuthToken';
import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    CLEAR_PROFILE,
    UNSET_LOADING,
    SET_LOADING,
    USER_PERMISSIONS_LOADED,
    USER_PERMISSIONS_ERROR
} from './types';
import { history } from '../utils/history';

// Handle unauthorized responses
const handleUnauthorized = (dispatch) => {
    dispatch({ type: AUTH_ERROR });
    dispatch({ type: UNSET_LOADING });
    localStorage.removeItem('token');
    history.push('/login');
};

// Load User
export const loadUser = () => async (dispatch) => {
    const token = localStorage.getItem('token');
    if (token) {
        setAuthToken(token);
    }

    try {
        const res = await axios.get('/auth/user');
        dispatch({
            type: USER_LOADED,
            payload: res.data.data,
        });
        // Don't dispatch UNSET_LOADING here since we're loading permissions next
        dispatch(loadUserPermissions());
    } catch (err) {
        if (err.response?.status === 401) {
            handleUnauthorized(dispatch);
        } else {
            dispatch({ type: AUTH_ERROR });
            dispatch({ type: UNSET_LOADING });
        }
    }
};

// Load User Permissions
export const loadUserPermissions = () => async (dispatch) => {
    const token = localStorage.getItem('token');
    if (token) {
        setAuthToken(token);
    }

    try {
        const res = await axios.get('/auth/permissions');
        dispatch({
            type: USER_PERMISSIONS_LOADED,
            payload: res.data.data
        });
        dispatch({ type: UNSET_LOADING }); // Move UNSET_LOADING here after all data is loaded
    } catch (err) {
        if (err.response?.status === 401) {
            handleUnauthorized(dispatch);
        } else {
            dispatch({ type: USER_PERMISSIONS_ERROR });
            dispatch({ type: UNSET_LOADING });
        }
    }
};

export const register = ({name, email, password, password_confirmation}) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    const body = JSON.stringify({name, email, password, password_confirmation});

    try {
        const res = await axios.post('/auth/register', body, config);

        dispatch({
            type: REGISTER_SUCCESS,
            payload: res.data,
        });

        dispatch(loadUser());
    } catch (err) {
        if (err.response?.status === 401) {
            handleUnauthorized(dispatch);
        } else {
            dispatch(setAlert(err?.response?.data?.message, 'danger'));
            dispatch(logout());
            const errors = err.response.data.errors;

            if (errors) {
                // errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
                for (const prop in errors) {
                    dispatch(setAlert(errors[prop][0], 'danger'));
                }
            } else {
                dispatch(setAlert(`You're offline!`, 'danger'));
            }

            dispatch({
                type: REGISTER_FAIL,
            });
        }
    }
};

export const login = (email, password) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': "application/json"
        },
    };

    const body = JSON.stringify({email, password});

    try {
        const res = await axios.post('/auth/login/', body, config);
        const token = res.data.token || res.data.data.token;
        
        // Set token in localStorage and axios headers
        localStorage.setItem('token', token);
        setAuthToken(token);

        dispatch({
            type: LOGIN_SUCCESS,
            payload: { token }
        });

        dispatch(loadUser());
    } catch (err) {
        if (err.response?.status === 401) {
            handleUnauthorized(dispatch);
        } else {
            dispatch(setAlert(err?.response?.data?.message, 'danger'));
            dispatch({
                type: LOGIN_FAIL,
            });
        }
    }
};

export const logout = () => (dispatch) => {
    dispatch({
        type: CLEAR_PROFILE,
    });
    dispatch({
        type: LOGOUT,
    });
};

export const updateProfile = (updateData) => async (dispatch) => {
    dispatch(clearAlerts());
    dispatch({type: SET_LOADING, payload: true});

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.patch('/auth/profile', updateData, config);

        dispatch(setAlert("Akkaunt tahrirlandi!", 'success'));

        dispatch(loadUser());
        dispatch({type: SET_LOADING, payload: false});

    } catch (err) {
        if (err.response?.status === 401) {
            handleUnauthorized(dispatch);
        } else {
            dispatch(setAlert(err?.response?.data?.message, 'danger'));
        }
    }
};
