import React, {useEffect} from "react";
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import Select from "react-select";
import {getFirmOptions} from "../../actions/user";

const DashboardFirmFilter = ({ selectedFirm, setSelectedFirm }) => {
    const dispatch = useDispatch();
    const {firm_options} = useSelector(state => state.user);

    useEffect(() => {
        dispatch(getFirmOptions());
    }, [dispatch]);

    const firmItems = firm_options ? [
        {
            label: 'Barcha firmalar',
            value: ''
        },
        ...firm_options.map(item => ({
            label: item.name,
            value: item.id
        }))
    ] : [];

    const handleSelect = (selectedOption) => {
        setSelectedFirm(selectedOption?.value || null);
    };

    const currentValue = firmItems.find(item => item.value === selectedFirm) || null;

    const customStyles = {
        control: (provided) => ({
            ...provided,
            borderRadius: '0.2rem',
            borderColor: '#ced4da',
            minHeight: '38px',
            boxShadow: 'none',
            '&:hover': {
                borderColor: '#80bdff'
            }
        }),
        menuPortal: provided => ({...provided, zIndex: 9999}),
        menu: provided => ({...provided, zIndex: 9999})
    };

    return (
        <Select
            value={currentValue}
            onChange={handleSelect}
            options={firmItems}
            isClearable={true}
            styles={customStyles}
            placeholder="Firma tanlang"
        />
    );
};

DashboardFirmFilter.propTypes = {
    selectedFirm: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.oneOf([null])
    ]),
    setSelectedFirm: PropTypes.func.isRequired
};

export default DashboardFirmFilter;
