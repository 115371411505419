import React from 'react';
import Modal from 'react-bootstrap/Modal'
import { useDispatch, useSelector } from "react-redux";
import { deleteMaterial, setDeleteMaterialId } from "../../actions/material";
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const MaterialDeleteForm = () => {
    const dispatch = useDispatch();
    const { page } = useParams();
    const { delete_material } = useSelector(state => state.material);

    const closeModal = () => {
        // removing materialt from redux state by sending null setDeleteMaterialId
        dispatch(setDeleteMaterialId(null))
    };

    const onConfirm = () => {
        dispatch(deleteMaterial(delete_material.id, page))
    };

    let condition = false;
    if (delete_material !== null)
        condition = true;

    return (
        <div>
            <Modal show={condition} onHide={closeModal}>
                <Modal.Header className="bg-danger" closeButton>
                    <Modal.Title className="text-white">Materialni o'chirish</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Siz haqiqatdan ham ushbu materialni o'chirmoqchimisiz ?
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={closeModal} className="btn btn-secondary">YO'Q</button>
                    <button onClick={onConfirm} className="btn btn-primary">HA</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default MaterialDeleteForm;