import {Link} from "react-router-dom";
import React, { useEffect, useState} from "react";
import {formatMoney} from "../../utils/hooks/getParams";
import SupplierMaterialDataCell from "./SupplierMaterialDataCell";

const SupplierMaterialDataRow = ({index, item, dates}) => {

    const [itemData, setItemData] = useState({
        material_transactions: []
    });
    const [rowData, setRowData] = useState([]);

    useEffect(() => {
        setItemData(item);
    }, [item])

    useEffect(() => {
        if (item.hasOwnProperty('material_transactions')) {
            setRowData(itemData.material_transactions);
        }
    }, [itemData]);

    const getCellData = (date) => {
        const foundDateItem = rowData.find(item => item.date === date);
        if (foundDateItem){
            return {
                received_amount: foundDateItem.received_amount,
                received_sum: foundDateItem.received_sum,
                date: foundDateItem.date,
            }
        }
        return {
            received_amount: 0,
            received_sum: 0,
            date,
        }
    }

    return (
        <tr>
            <th className="sticky-col">{index + 1}</th>
            <th className="sticky-col2">
                <Link to={`suppliers/${item.id}`}>
                    {item.name}
                </Link>
            </th>
            {
                dates.map((date, subindex) => (
                    <SupplierMaterialDataCell
                        key={subindex}
                        date={date}
                        index={index}
                        cellData={getCellData(date)}
                        rowData={rowData}
                    />

                ))
            }

            <th style={{textAlign: "center", minWidth: "100px", right: "300px"}}
                title={formatMoney(itemData.total_current_month_amount, ',')}
            >
                {formatMoney(Number(itemData.total_current_month_amount || 0).toFixed(2), ',')}
            </th>
            <th style={{textAlign: "center", minWidth: "100px", right: "200px"}}
                title={formatMoney(itemData.total_current_month_sum, ',')}
            >
                {formatMoney(Number(itemData.total_current_month_sum || 0), ',')}
            </th>
            <th style={{textAlign: "center", minWidth: "100px", right: "100px"}}
                title={formatMoney(itemData.total_received_amount, ',')}
            >
                {formatMoney(Number(itemData.total_received_amount || 0).toFixed(2), ',')}
            </th>

            <th style={{textAlign: "center", minWidth: "100px", right: "0px"}}
                title={formatMoney(itemData.total_received_sum, ',')}
            >
                {formatMoney(itemData.total_received_sum, ',')}
            </th>
        </tr>
    )
}

export default SupplierMaterialDataRow;