import React, {useEffect, useRef, useState} from 'react';
import {Redirect} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {loadUser, updateProfile} from '../../actions/auth';
import {showNavbar} from '../../actions/navbar';
import {showSidebar} from '../../actions/sidebar';
import avatar from '../../img/avatar.jpg'
import Button from "react-bootstrap/Button";
import {getFirmOptions} from "../../actions/user";
import ButtonLoader from "../layout/ButtonLoader";

const Profile = () => {
    const dispatch = useDispatch();
    const {auth} = useSelector(state => state);
    const {user: update_user, error, loading, isAuthenticated, permissions} = auth;
    
    const defaultValues = {
        name: '',
        email: '',
        telegram_id: '',
        password: ''
    };
    
    const [inputs, setInputs] = useState(defaultValues);
    const [errors, setErrors] = useState({});
    const formRef = useRef();

    const canManageTelegram = () => {
        return update_user?.role_name === 'superadmin' || 
               update_user?.role_name === 'director' || 
               update_user?.roles?.some(role => ['superadmin', 'director'].includes(role.name));
    };

    useEffect(() => {
        dispatch(loadUser());
        dispatch(showSidebar());
        dispatch(showNavbar());
        getFirmOptions();
    }, [dispatch]);

    useEffect(() => {
        if (update_user) {
            setInputs({
                name: update_user.name,
                email: update_user.email,
                telegram_id: update_user.telegram_id,
                password: update_user.password
            });
        }
    }, [update_user]);

    useEffect(() => {
        if (error && loading === false) {
            const errorsItems = error?.errors;
            let errorObject = {};
            if (error && error?.errors) {
                Object.keys(error?.errors).forEach((item, index) => errorObject[item] = errorsItems[item][0]);
            }
            setErrors(errorObject);
        }
    }, [error, loading]);

    if (!isAuthenticated) {
        return <Redirect to="/login"/>;
    }

    const handleChange = e => {
        setErrors({});
        e.target.oldValue = e.target.value;
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(updateProfile(inputs));
    };

    const resetForm = (event) => {
        event.preventDefault();
        dispatch(loadUser());
    };

    return (
        <div className="container-fluid p-0">

            <div className="row">
                <div className="col-md-2 col-xl-3">
                    <div className="card mb-3">
                        <div className="card-header">
                            <h5 className="card-title text-center mb-0">Akkaunt ma'umotlari</h5>
                        </div>
                        <div className="card-body text-center">
                            <img src={avatar} alt="Christina Mason" className="img-fluid rounded-circle mb-2"
                                 width="128" height="128"/>
                            <h5 className="card-title mb-0">{update_user?.name}</h5>
                            <div className="text-muted mb-2">{update_user?.email}</div>

                        </div>

                        <hr className="my-0"/>
                    </div>
                </div>

                <div className="col-md-10 col-xl-9">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title mb-0">Akkauntni tahrirlash</h5>
                        </div>
                        <div className="card-body h-100">


                            <form onSubmit={handleSubmit} ref={formRef}>


                                <div className="mb-3">
                                    <label htmlFor="name">Ism</label>
                                    <input
                                        name="name"
                                        className={'form-control'}
                                        type="text"
                                        value={inputs?.name || ''}
                                        autoComplete={'off'}
                                        placeholder={'kiriting'}
                                        onChange={handleChange}
                                        onKeyUp={e => e.target.oldValue = e.target.value}
                                    />
                                    <div className="error">
                                        {errors?.name}
                                    </div>
                                </div>
                                {canManageTelegram() && (
                                    <div className="mb-3">
                                        <label htmlFor="telegram_id">Telegram</label>
                                        <input
                                            name="telegram_id"
                                            className={'form-control'}
                                            type="text"
                                            value={inputs?.telegram_id || ''}
                                            autoComplete={'off'}
                                            placeholder={'TG_ID YOKI USERNAME'}
                                            onChange={handleChange}
                                            onKeyUp={e => e.target.oldValue = e.target.value}
                                        />

                                        <p className={'text text-warning'}>
                                            <a target={"_blank"} rel="noreferrer" href={"https://t.me/@ToirjonUZBot"}> @ToirjonUZBot</a> ga start bosgan
                                            bo'lishi kerak.
                                        </p>
                                        <div className="error">
                                            {errors?.telegram_id}
                                        </div>
                                    </div>
                                )}
                                <div className="mb-3">
                                    <label htmlFor="email">Email</label>
                                    <input
                                        name="email"
                                        className={'form-control'}
                                        disabled={true}
                                        type="email"
                                        value={inputs?.email || ''}
                                        placeholder={'kiriting'}
                                        autoComplete={'off'}
                                        onChange={handleChange}
                                        onKeyUp={e => e.target.oldValue = e.target.value}
                                    />

                                    <div className="error">
                                        {errors?.email}
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="password">Parol</label>
                                    <input
                                        name="password"
                                        className={'form-control'}
                                        type="password"
                                        autoComplete={'off'}
                                        value={inputs?.password || ''}
                                        placeholder={'kiriting'}
                                        onChange={handleChange}
                                        onKeyUp={e => e.target.oldValue = e.target.value}
                                    />

                                    <div className="error">
                                        {errors?.password}
                                    </div>
                                </div>

                                <div className="d-flex gap-2">
                                    <Button className="w-50" variant="secondary" type="button" onClick={resetForm}>
                                        Bekor qilish
                                    </Button>
                                    <Button className="w-50" variant="primary" type="submit">Saqlash
                                        {loading && <ButtonLoader/>}
                                    </Button>
                                </div>

                            </form>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};


export default Profile;
