import { useState, useEffect, useCallback } from 'react';

/**
 * Custom hook for handling form errors
 * @param {Object} error - Error object from API response
 * @param {boolean} loading - Loading state
 * @param {Function} clearErrors - Function to clear errors
 * @param {Object} initialValues - Initial form values
 * @returns {[Object, Object, Function, Function]} - [errors, inputs, setInputs, handleError, resetForm]
 */
export const useFormErrors = (error, loading, clearErrors, initialValues = {}) => {
    const [errors, setErrors] = useState({});
    const [inputs, setInputs] = useState(initialValues);

    const resetForm = useCallback(() => {
        setErrors({});
        setInputs(initialValues);
        clearErrors?.();
    }, [clearErrors, initialValues]);

    const handleError = useCallback((errorData) => {
        if (!errorData) {
            setErrors({});
            return {};
        }

        // Handle string error messages
        if (typeof errorData === 'string') {
            setErrors({ general: errorData });
            return { general: errorData };
        }

        // Handle error object with errors field
        const errorsItems = errorData.errors;
        let errorObject = {};
        
        if (errorsItems && typeof errorsItems === 'object') {
            Object.entries(errorsItems).forEach(([key, value]) => {
                errorObject[key] = Array.isArray(value) ? value[0] : 
                    typeof value === 'string' ? value : 'Invalid value';
            });
        }

        // If no field errors but has message, use it as general error
        if (Object.keys(errorObject).length === 0 && errorData.message) {
            errorObject.general = errorData.message;
        }
        
        setErrors(errorObject);
        return errorObject;
    }, []);

    useEffect(() => {
        let mounted = true;
        
        if (!loading && error) {
            if (mounted) {
                handleError(error);
            }
        }

        return () => {
            mounted = false;
        };
    }, [error, loading, handleError]);

    return [errors, inputs, setInputs, handleError, resetForm];
};
