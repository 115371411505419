import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getTopProductsChart } from '../../actions/dashboard';
import Chart from 'chart.js/auto';

const TopProductsChart = ({
    dashboard: { topProductsChart, loading },
    getTopProductsChart
}) => {
    const chartRef = React.useRef(null);
    const chartInstance = React.useRef(null);

    useEffect(() => {
        getTopProductsChart();
    }, [getTopProductsChart]);

    useEffect(() => {
        if (loading.topProductsChart || !topProductsChart) return;

        const ctx = chartRef.current.getContext('2d');

        // Destroy existing chart if it exists
        if (chartInstance.current) {
            chartInstance.current.destroy();
        }

        chartInstance.current = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: topProductsChart.products.map(p => p.name),
                datasets: [{
                    label: 'Sotilgan mahsulotlar',
                    data: topProductsChart.products.map(p => p.quantity),
                    backgroundColor: '#3B7DDD',
                    borderRadius: 4
                }]
            },
            options: {
                indexAxis: 'y',
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: true,
                        position: 'top'
                    },
                    tooltip: {
                        callbacks: {
                            label: (context) => {
                                const product = topProductsChart.products[context.dataIndex];
                                return `${product.quantity} ${product.unit} - ${product.total_price.toLocaleString('uz-UZ')} UZS`;
                            }
                        }
                    }
                },
                scales: {
                    x: {
                        beginAtZero: true,
                        ticks: {
                            callback: (value) => {
                                return value + ' dona';
                            }
                        }
                    }
                }
            }
        });

        // Cleanup
        return () => {
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }
        };
    }, [topProductsChart, loading.topProductsChart]);

    if (loading.topProductsChart) {
        return <div>Loading...</div>;
    }

    return (
        <div className="card flex-fill w-100">
            <div className="card-header">
                <h5 className="card-title mb-0">Top 5 Sotilgan Mahsulotlar</h5>
            </div>
            <div className="card-body d-flex w-100">
                <div className="align-self-center chart chart-lg" style={{ width: '100%', height: '300px' }}>
                    <canvas ref={chartRef} />
                </div>
            </div>
        </div>
    );
};

TopProductsChart.propTypes = {
    dashboard: PropTypes.shape({
        topProductsChart: PropTypes.shape({
            products: PropTypes.arrayOf(PropTypes.shape({
                name: PropTypes.string.isRequired,
                quantity: PropTypes.number.isRequired,
                unit: PropTypes.string.isRequired,
                total_price: PropTypes.number.isRequired
            })).isRequired
        }),
        loading: PropTypes.shape({
            topProductsChart: PropTypes.bool.isRequired
        }).isRequired
    }).isRequired,
    getTopProductsChart: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    dashboard: state.dashboard
});

export default connect(mapStateToProps, { getTopProductsChart })(TopProductsChart);
