import {
    GET_DASHBOARD_STATISTICS,
    GET_DASHBOARD_STATISTICS_ERROR,
    DASHBOARD_LOADING,
    GET_MONTHLY_EXPENSES,
    GET_MONTHLY_EXPENSES_ERROR,
    MONTHLY_EXPENSES_LOADING,
    GET_MONTHLY_SALES,
    GET_MONTHLY_SALES_ERROR,
    MONTHLY_SALES_LOADING,
    GET_CLIENT_TRANSACTIONS_CHART,
    GET_CLIENT_TRANSACTIONS_CHART_ERROR,
    CLIENT_TRANSACTIONS_CHART_LOADING,
    GET_SUPPLIER_TRANSACTIONS_CHART,
    GET_SUPPLIER_TRANSACTIONS_CHART_ERROR,
    SUPPLIER_TRANSACTIONS_CHART_LOADING,
    GET_TOP_PRODUCTS_CHART,
    GET_TOP_PRODUCTS_CHART_ERROR,
    TOP_PRODUCTS_CHART_LOADING,
    GET_TOP_MATERIALS_CHART,
    GET_TOP_MATERIALS_CHART_ERROR,
    TOP_MATERIALS_CHART_LOADING,
    SET_GENERAL_STATISTICS_MONTH,
    GET_GENERAL_CLIENT_TRANSACTIONS,
    GET_GENERAL_CLIENT_TRANSACTIONS_ERROR,
    GENERAL_CLIENT_TRANSACTIONS_LOADING,
    GET_GENERAL_SUPPLIER_TRANSACTIONS,
    GET_GENERAL_SUPPLIER_TRANSACTIONS_ERROR,
    GENERAL_SUPPLIER_TRANSACTIONS_LOADING
} from '../actions/types';

const initialState = {
    statistics: null,
    monthlyExpenses: null,
    monthlySales: null,
    clientTransactionsChart: null,
    supplierTransactionsChart: null,
    topProductsChart: null,
    topMaterialsChart: null,
    generalStatistics: {
        month: {
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1
        },
        clientTransactions: {
            credit: [],
            debit: [],
            meta: {
                month: new Date().getMonth() + 1,
                year: new Date().getFullYear(),
                days: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate(),
                total_credit: 0,
                total_debit: 0,
                firm_id: null
            }
        },
        supplierTransactions: {
            credit: [],
            debit: [],
            meta: {
                month: new Date().getMonth() + 1,
                year: new Date().getFullYear(),
                days: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate(),
                total_credit: 0,
                total_debit: 0,
                firm_id: null
            }
        },
        loading: {
            clientTransactions: false,
            supplierTransactions: false
        }
    },
    loading: {
        statistics: false,
        monthlyExpenses: false,
        monthlySales: false,
        clientTransactionsChart: false,
        supplierTransactionsChart: false,
        topProductsChart: false,
        topMaterialsChart: false
    },
    error: {}
};

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DASHBOARD_LOADING:
            return {
                ...state,
                loading: { ...state.loading, statistics: true }
            };
        case GET_DASHBOARD_STATISTICS:
            return {
                ...state,
                statistics: payload,
                loading: { ...state.loading, statistics: false }
            };
        case GET_DASHBOARD_STATISTICS_ERROR:
            return {
                ...state,
                error: payload,
                loading: { ...state.loading, statistics: false }
            };

        // General Statistics Cases
        case SET_GENERAL_STATISTICS_MONTH:
            return {
                ...state,
                generalStatistics: {
                    ...state.generalStatistics,
                    month: payload
                }
            };
        case GENERAL_CLIENT_TRANSACTIONS_LOADING:
            return {
                ...state,
                generalStatistics: {
                    ...state.generalStatistics,
                    loading: {
                        ...state.generalStatistics.loading,
                        clientTransactions: true
                    }
                }
            };
        case GET_GENERAL_CLIENT_TRANSACTIONS:
            return {
                ...state,
                generalStatistics: {
                    ...state.generalStatistics,
                    clientTransactions: payload,
                    loading: {
                        ...state.generalStatistics.loading,
                        clientTransactions: false
                    }
                }
            };
        case GET_GENERAL_CLIENT_TRANSACTIONS_ERROR:
            return {
                ...state,
                generalStatistics: {
                    ...state.generalStatistics,
                    error: { ...state.generalStatistics.error, clientTransactions: payload },
                    loading: {
                        ...state.generalStatistics.loading,
                        clientTransactions: false
                    }
                }
            };
        case GENERAL_SUPPLIER_TRANSACTIONS_LOADING:
            return {
                ...state,
                generalStatistics: {
                    ...state.generalStatistics,
                    loading: {
                        ...state.generalStatistics.loading,
                        supplierTransactions: true
                    }
                }
            };
        case GET_GENERAL_SUPPLIER_TRANSACTIONS:
            return {
                ...state,
                generalStatistics: {
                    ...state.generalStatistics,
                    supplierTransactions: payload,
                    loading: {
                        ...state.generalStatistics.loading,
                        supplierTransactions: false
                    }
                }
            };
        case GET_GENERAL_SUPPLIER_TRANSACTIONS_ERROR:
            return {
                ...state,
                generalStatistics: {
                    ...state.generalStatistics,
                    error: { ...state.generalStatistics.error, supplierTransactions: payload },
                    loading: {
                        ...state.generalStatistics.loading,
                        supplierTransactions: false
                    }
                }
            };

        case MONTHLY_EXPENSES_LOADING:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    monthlyExpenses: true
                }
            };
        case GET_MONTHLY_EXPENSES:
            return {
                ...state,
                monthlyExpenses: {
                    ...payload,
                    meta: {
                        ...payload.meta,
                        month: parseInt(payload.meta.month),
                        year: parseInt(payload.meta.year)
                    }
                },
                loading: {
                    ...state.loading,
                    monthlyExpenses: false
                }
            };
        case GET_MONTHLY_EXPENSES_ERROR:
            return {
                ...state,
                error: payload,
                loading: {
                    ...state.loading,
                    monthlyExpenses: false
                }
            };

        case MONTHLY_SALES_LOADING:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    monthlySales: true
                }
            };
        case GET_MONTHLY_SALES:
            return {
                ...state,
                monthlySales: {
                    ...payload,
                    meta: {
                        ...payload.meta,
                        month: parseInt(payload.meta.month),
                        year: parseInt(payload.meta.year)
                    }
                },
                loading: {
                    ...state.loading,
                    monthlySales: false
                }
            };
        case GET_MONTHLY_SALES_ERROR:
            return {
                ...state,
                error: payload,
                loading: {
                    ...state.loading,
                    monthlySales: false
                }
            };

        case CLIENT_TRANSACTIONS_CHART_LOADING:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    clientTransactionsChart: true
                }
            };
        case GET_CLIENT_TRANSACTIONS_CHART:
            return {
                ...state,
                clientTransactionsChart: {
                    ...payload,
                    meta: {
                        ...payload.meta,
                        month: parseInt(payload.meta.month),
                        year: parseInt(payload.meta.year)
                    }
                },
                loading: {
                    ...state.loading,
                    clientTransactionsChart: false
                }
            };
        case GET_CLIENT_TRANSACTIONS_CHART_ERROR:
            return {
                ...state,
                error: payload,
                loading: {
                    ...state.loading,
                    clientTransactionsChart: false
                }
            };

        case SUPPLIER_TRANSACTIONS_CHART_LOADING:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    supplierTransactionsChart: true
                }
            };
        case GET_SUPPLIER_TRANSACTIONS_CHART:
            return {
                ...state,
                supplierTransactionsChart: {
                    ...payload,
                    meta: {
                        ...payload.meta,
                        month: parseInt(payload.meta.month),
                        year: parseInt(payload.meta.year)
                    }
                },
                loading: {
                    ...state.loading,
                    supplierTransactionsChart: false
                }
            };
        case GET_SUPPLIER_TRANSACTIONS_CHART_ERROR:
            return {
                ...state,
                error: payload,
                loading: {
                    ...state.loading,
                    supplierTransactionsChart: false
                }
            };

        case TOP_PRODUCTS_CHART_LOADING:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    topProductsChart: true
                }
            };
        case GET_TOP_PRODUCTS_CHART:
            return {
                ...state,
                topProductsChart: {
                    ...payload,
                    meta: {
                        ...payload.meta,
                        month: parseInt(payload.meta.month),
                        year: parseInt(payload.meta.year)
                    }
                },
                loading: {
                    ...state.loading,
                    topProductsChart: false
                }
            };
        case GET_TOP_PRODUCTS_CHART_ERROR:
            return {
                ...state,
                error: payload,
                loading: {
                    ...state.loading,
                    topProductsChart: false
                }
            };

        case TOP_MATERIALS_CHART_LOADING:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    topMaterialsChart: true
                }
            };
        case GET_TOP_MATERIALS_CHART:
            return {
                ...state,
                topMaterialsChart: {
                    ...payload,
                    meta: {
                        ...payload.meta,
                        month: parseInt(payload.meta.month),
                        year: parseInt(payload.meta.year)
                    }
                },
                loading: {
                    ...state.loading,
                    topMaterialsChart: false
                }
            };
        case GET_TOP_MATERIALS_CHART_ERROR:
            return {
                ...state,
                error: payload,
                loading: {
                    ...state.loading,
                    topMaterialsChart: false
                }
            };

        default:
            return state;
    }
}
