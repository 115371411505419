import moment from "moment";
import { useState} from "react";
import {useDispatch} from "react-redux";
import {downloadSingleClientProductsReport, downloadSingleClientTransactionReport} from "../../actions/client";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ButtonLoader from "../layout/ButtonLoader";


const SingleClientReport = ({id}) => {
    const dispatch = useDispatch();
    const [reportType, setReportType] = useState('transactions');
    const [periodType, setPeriodType] = useState('');
    const [fromDate, setFromDate] = useState(moment().format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
    const [month, setMonth] = useState('');

    const [year, setYear] = useState(new Date().getFullYear());
    const [selectedYear, setSelectedYear] = useState(new Date());
    const handleYear = (date) => {
        setYear(date.getFullYear())
        setSelectedYear(date)
    }

    const [fileFormat, setFileFormat] = useState('pdf');
    const [loading, setLoading] = useState(false);

    const onDownload = async (e) => {
        e.preventDefault();
        
        if (reportType === 'products' && !periodType) {
            alert("Iltimos, muddat turini tanlang");
            return;
        }
        
        setLoading(true);
        try {
            if (reportType === 'products') {
                let data = {};
                switch (periodType) {
                    case 'range':
                        data = {period_type: 'range', from_date: fromDate, to_date: toDate, file_format: fileFormat, report_type: reportType};
                        break;
                    case 'monthly':
                        data = {period_type: 'monthly', month: month, file_format: fileFormat, report_type: reportType};
                        break;
                    case 'yearly':
                        data = {period_type: 'yearly', year: year, file_format: fileFormat, report_type: reportType};
                        break;
                }
                
                const link = await dispatch(downloadSingleClientProductsReport(id, data));
                if (link !== false && link !== undefined)
                    window.open(link);
            }
            if (reportType === 'transactions') {
                const data = {file_format: fileFormat};
                const link = await dispatch(downloadSingleClientTransactionReport(id, data));
                if (link !== false && link !== undefined)
                    window.open(link);
            }
        } finally {
            setLoading(false);
        }
    }
    return (
        <form className="row g-3">

            <div className="col-auto">
                <label htmlFor="to_date" className="visually-hidden">HISOBOT TURI</label>
                <select className={'form-control form-select'}
                        value={reportType}
                        onChange={e => setReportType(e.target.value)}
                >
                    <option value="">HISOBOT TURI</option>
                    <option value="transactions">Transaksiya</option>
                    <option value="products">Mahsulotlar</option>
                </select>
            </div>

            {
                reportType === 'products' && (

                    <>

                        <div className="col-auto">
                            <label htmlFor="to_date" className="visually-hidden">MUDDAT TURI</label>
                            <select className={'form-control form-select'}
                                    value={periodType}
                                    onChange={e => setPeriodType(e.target.value)}
                            >
                                <option value="">Muddat turi</option>
                                <option value="range">Dan-Gacha</option>
                                <option value="monthly">Oylik</option>
                                <option value="yearly">Yillik</option>
                            </select>
                        </div>

                        <>

                            {
                                'range' === periodType && (
                                    <>

                                        <div className="col-auto">
                                            <label htmlFor="from_date" className="visually-hidden">DAN</label>
                                            <input type="date" className="form-control" id="from_date"
                                                   onChange={e => setFromDate(e.target.value)}
                                                   max={moment(new Date()).format('YYYY-MM-DD')}
                                                   value={fromDate}
                                            />
                                        </div>
                                        <div className="col-auto">
                                            <label htmlFor="to_date" className="visually-hidden">GACHA</label>
                                            <input type="date" className="form-control" id="to_date"
                                                   onChange={e => setToDate(e.target.value)}
                                                   max={moment(new Date()).format('YYYY-MM-DD')}
                                                   value={toDate}
                                            />
                                        </div>
                                    </>
                                )
                            }
                            {
                                'monthly' === periodType && (
                                    <>

                                        <div className="col-auto">
                                            <label htmlFor="month" className="visually-hidden">OY</label>
                                            <input type="month" className="form-control" id="month"
                                                   onChange={e => setMonth(e.target.value)}
                                                   max={moment(new Date()).format('YYYY-MM')}
                                                   value={month}
                                            />
                                        </div>
                                    </>
                                )
                            }
                            {
                                'yearly' === periodType && (
                                    <>

                                        <div className="col-auto">
                                            <label htmlFor="year" className="visually-hidden">YIL</label>
                                            <DatePicker
                                                className={'form-control'}
                                                type="year"
                                                selected={selectedYear}
                                                onChange={(date) => handleYear(date)}
                                                dateFormat="yyyy"
                                                maxDate={moment(new Date()).format('YYYY-MM-DD')}
                                                showYearPicker
                                                showYearDropdown={true}
                                                showFourColumnMonthYearPicker={true}
                                            />
                                        </div>
                                    </>
                                )
                            }
                        </>

                    </>

                )
            }


            <>

                <div className="col-auto">
                    <label htmlFor="to_date" className="visually-hidden">FAYL FORMATI</label>
                    <select className={'form-control form-select'}
                            value={fileFormat}
                            onChange={e => setFileFormat(e.target.value)}
                    >
                        <option value="">FAYL FORMATI</option>
                        <option value="pdf">PDF</option>
                        <option value="xlsx">XLSX</option>
                    </select>
                </div>

            </>

            <div className="col-auto">
                <button type="button" className="btn btn-primary mb-3" onClick={onDownload} disabled={loading}>
                    {loading ? <ButtonLoader /> : 'Yuklash'}
                </button>
            </div>
        </form>
    )
}

export default SingleClientReport;