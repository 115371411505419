import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {setDeleteProductId, setUpdateProductId} from "../../actions/product";
import {Link} from 'react-router-dom'
import MoneyFormat from "../layout/MoneyFormat";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faEye } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';

const ProductItem = ({
                         auth,
                         setDeleteProductId,
                         setUpdateProductId,
                         handleShow,
                         handleClose,
                         product: item,
                         index
                     }) => {

    const {role_name} = auth?.user || {};

    const setDeleteProduct = (id) => {
        setDeleteProductId(id);
    };

    const setUpdateProduct = (id) => {
        setUpdateProductId(id);
        if (id){
            handleShow();
        }else{
            handleClose();
        }
    };

    return (
        <Fragment>
            <tr>
                <th scope="row">{index + 1}</th>
                <td>{item?.name}</td>
                <td>
                    <Link to={`/firms/${item.firm?.id}`}>
                        {item.firm?.name}
                    </Link>
                </td>
                <td>
                    <Link to={`/warehouses/${item.warehouse?.id}`}>
                        {item.warehouse?.name}
                    </Link>
                </td>
                <td>{item?.in_stock}</td>
                <td>{item?.unit?.name}</td>
                <td><MoneyFormat value={item?.price} decimal={2}/></td>
                <td>
                    <div className="d-flex gap-2">
                        <Link to={`products/${item?.id}`}>
                            <Button
                                variant="outline-secondary"
                                size="sm"
                                className="d-flex align-items-center justify-content-center"
                                style={{ width: '32px', height: '32px', padding: 0 }}
                            >
                                <FontAwesomeIcon icon={faEye} />
                            </Button>
                        </Link>
                        {
                            role_name === 'superadmin' || role_name === 'director' || role_name === 'manager' ? (
                                <Button
                                    variant="outline-primary"
                                    size="sm"
                                    className="d-flex align-items-center justify-content-center"
                                    style={{ width: '32px', height: '32px', padding: 0 }}
                                    onClick={() => setUpdateProduct(item?.id)}
                                >
                                    <FontAwesomeIcon icon={faEdit} />
                                </Button>
                            ) : ''
                        }
                        {
                            role_name === 'superadmin' || role_name === 'director' ? (
                                <Button
                                    variant="outline-danger"
                                    size="sm"
                                    className="d-flex align-items-center justify-content-center"
                                    style={{ width: '32px', height: '32px', padding: 0 }}
                                    onClick={() => setDeleteProduct(item?.id)}
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                </Button>
                            ) : ''
                        }
                    </div>
                </td>
            </tr>
        </Fragment>
    );
};

ProductItem.defaultProps = {
    showActions: true,
};

ProductItem.propTypes = {
    product: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {setDeleteProductId, setUpdateProductId})(
    ProductItem
);
