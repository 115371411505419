import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { X, ArrowLeft } from 'react-feather';
import { Multiselect } from 'multiselect-react-dropdown';
import {
    clearErrors,
    getClientOptions,
    getProductOptions,
    updateSale
} from '../../../actions/sale';
import { getFirmOptions } from '../../../actions/user';
import { getWarehouseOptions } from '../../../actions/product';
import { setAlert } from '../../../actions/alert';

const SimpleSaleUpdateForm = ({ handleClose }) => {
    const dispatch = useDispatch();
    const { update_sale } = useSelector((state) => state.sale);
    const [selectedDynamicProductOptions, setSelectedDynamicProductOptions] = useState([]);
    const [selectedProductOptions, setSelectedProductOptions] = useState([]);
    const [productOptions, setProductOptions] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);
    const [warehouseOptions, setWarehouseOptions] = useState([]);
    const [firmOptions, setFirmOptions] = useState([]);
    const [formErrors, setFormErrors] = useState({});
    const formRef = useRef(null);

    const defaultValues = {
        is_special_sale: false,
        client_id: update_sale?.client_id || '',
        firm_id: update_sale?.firm_id || '',
        warehouse_id: update_sale?.warehouse_id || '',
        date: update_sale?.date ? moment(update_sale.date, 'DD.MM.YYYY').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
        transport_number: update_sale?.transport_number || '',
        driver_name: update_sale?.driver_name || '',
        description: update_sale?.description || '',
        products: update_sale?.items?.map(item => ({
            id: item.sellable_id,
            quantity: item.quantity || 0,
            price: item.price || 0,
        })) || []
    };

    const [inputs, setInputs] = useState(defaultValues);

    // Update inputs when update_sale changes
    useEffect(() => {
        if (update_sale) {
            setInputs({
                is_special_sale: false,
                client_id: update_sale.client_id || '',
                firm_id: update_sale.firm_id || '',
                warehouse_id: update_sale.warehouse_id || '',
                date: update_sale.date ? moment(update_sale.date, 'DD.MM.YYYY').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
                transport_number: update_sale.transport_number || '',
                driver_name: update_sale.driver_name || '',
                description: update_sale.description || '',
                products: update_sale.items?.map(item => ({
                    id: item.sellable_id,
                    quantity: item.quantity || 0,
                    price: item.price || 0,
                })) || []
            });

            if (update_sale.items?.length > 0) {
                const mappedItems = update_sale.items.map(item => ({
                    ...item,
                    id: item.sellable_id,
                    name: item.name,
                    quantity: item.quantity,
                    price: item.price,
                    in_stock: item.in_stock,
                    unit: item.unit
                }));
                setSelectedProductOptions(mappedItems);
            }
        }
    }, [update_sale]);

    const handleError = (error) => {
        if (error?.response?.data?.errors) {
            setFormErrors(error.response.data.errors);
        }
    };

    const resetForm = () => {
        setInputs(defaultValues);
        setFormErrors({});
    };

    // Initial data fetching
    useEffect(() => {
        const fetchInitialData = async () => {
            await Promise.all([
                dispatch(getClientOptions()),
                dispatch(getFirmOptions())
            ]);
            
            if (update_sale?.firm_id) {
                await Promise.all([
                    dispatch(getWarehouseOptions({ firm_id: update_sale.firm_id })),
                    dispatch(getProductOptions({ is_for_sale: true, firm_id: update_sale.firm_id }))
                ]);
            }
        };

        fetchInitialData();

        return () => {
            dispatch(clearErrors());
            resetForm();
        };
    }, [dispatch, update_sale?.firm_id]);

    // Handle firm selection effects
    useEffect(() => {
        if (inputs.firm_id && inputs.firm_id !== update_sale?.firm_id) {
            dispatch(getWarehouseOptions({ firm_id: inputs.firm_id }));
            dispatch(getProductOptions({ is_for_sale: true, firm_id: inputs.firm_id }));
        }
    }, [inputs.firm_id, update_sale?.firm_id, dispatch]);

    // Handle API errors
    useEffect(() => {
        if (formErrors) {
            setFormErrors(formErrors);
        }
    }, [formErrors]);

    // Update options from redux state
    const { firm_options, client_options, warehouse_options, product_options } = useSelector(state => ({
        firm_options: state.user?.firm_options || [],
        client_options: state.sale?.client_options || [],
        warehouse_options: state.product?.warehouse_options || [],
        product_options: state.sale?.product_options || []
    }));

    useEffect(() => {
        if (firm_options?.length) {
            const items = firm_options.map((item) => ({
                label: item.name,
                value: item.id,
                ...item
            }));
            setFirmOptions(items);
        }
    }, [firm_options]);

    useEffect(() => {
        if (warehouse_options?.length) {
            const options = warehouse_options.map((item) => ({
                label: item.name,
                value: item.id,
                ...item
            }));
            setWarehouseOptions(options);
        }
    }, [warehouse_options]);

    useEffect(() => {
        if (client_options?.length) {
            const options = client_options.map((item) => ({
                label: item.name,
                value: item.id,
                ...item
            }));
            setClientOptions(options);
        }
    }, [client_options]);

    useEffect(() => {
        if (product_options?.length) {
            const options = product_options.map(item => ({
                ...item,
                label: item.name,
                value: item.id,
                price: item.price || 0,
                quantity: item.quantity || 0
            }));
            setProductOptions(options);
        }
    }, [product_options]);

    const onSelectProduct = (data) => {
        setSelectedDynamicProductOptions(data);
    };

    const onRemoveProduct = (data) => {
        setSelectedDynamicProductOptions(data);
    };

    const onClickMoveSelectedMaterials = () => {
        if (selectedDynamicProductOptions.length > 0) {
            setSelectedProductOptions([...selectedProductOptions, ...selectedDynamicProductOptions]);
            let ids = selectedDynamicProductOptions.map(({ id }) => id);
            const filteredMaterials = productOptions.filter((item) => !ids.includes(item.id));
            setProductOptions(filteredMaterials);
            setSelectedDynamicProductOptions([]);
            const inputProducts = [];
            selectedProductOptions.map(item => inputProducts.push({
                id: item.id,
                quantity: item?.quantity || 0,
                price: item?.price || 0,
            }));
            inputs.products = [...inputs.products, ...inputProducts];
            setInputs(inputs);
        }
    };

    const handleSelect = (option, { name }) => {
        setInputs(prev => ({
            ...prev,
            [name]: option ? option.value : ''
        }));
    };

    const getSelectedOption = (value, options) => {
        if (!options?.length || !value) return null;
        return options.find(option => option.value === value) || null;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputs(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const onChangeInput = (e) => {
        let product_id = parseInt(e.target.dataset.id);
        const cleanItems = [];
        const items = selectedProductOptions.map((item) => {
            if (item.id === product_id) {
                if (e.target.getAttribute('name') === 'quantity') {
                    if (e.target.value !== '') {
                        if (Number(e.target.value) > 0) {
                            if (Number(e.target.value) <= item?.in_stock && item?.in_stock !== 0) {
                                item[e.target.getAttribute('name')] = Number(e.target.value);
                            }
                        } else {
                            item[e.target.getAttribute('name')] = 1;
                        }
                    } else {
                        item[e.target.getAttribute('name')] = '';
                    }
                } else if (e.target.getAttribute('name') === 'price') {
                    item[e.target.getAttribute('name')] = Number(e.target.value) || 0;
                } else {
                    item[e.target.getAttribute('name')] = e.target.value;
                }
            }
            cleanItems.push({
                id: item.id,
                price: item?.price || 0,
                quantity: item?.quantity || 0,
            });
            return item;
        });
        setSelectedProductOptions(items);
        inputs.products = cleanItems;
        setInputs(inputs);
    };

    const onRemoveSelectedMaterial = (id) => {
        const items = selectedProductOptions.filter((item) => item.id !== id);
        setSelectedProductOptions(items);
        const selectedItem = selectedProductOptions.find((item) => item.id === id);
        setProductOptions([...productOptions, selectedItem]);
        const cleanItems = [];
        items.map(item => cleanItems.push({
            id: item.id,
            price: item?.price || 0,
            quantity: item?.quantity || 0,
        }));
        inputs.products = cleanItems;
        setInputs(inputs);
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        setFormErrors({});

        if (!inputs.firm_id) {
            setFormErrors(prev => ({ ...prev, firm_id: "Firma tanlash majburiy" }));
            return;
        }

        if (!inputs.client_id) {
            setFormErrors(prev => ({ ...prev, client_id: "Mijoz tanlash majburiy" }));
            return;
        }

        if (!inputs.warehouse_id) {
            setFormErrors(prev => ({ ...prev, warehouse_id: "Ombor tanlash majburiy" }));
            return;
        }

        if (!selectedProductOptions.length) {
            dispatch(setAlert('Mahsulotlarni tanlang', 'danger'));
            return;
        }

        const formData = {
            is_special_sale: false,
            client_id: parseInt(inputs.client_id),
            firm_id: parseInt(inputs.firm_id),
            warehouse_id: parseInt(inputs.warehouse_id),
            date: inputs.date,
            transport_number: inputs.transport_number,
            driver_name: inputs.driver_name,
            description: inputs.description,
            items: selectedProductOptions.map(item => ({
                sellable_type: 'App\\Models\\Product',
                sellable_id: parseInt(item.id),
                quantity: parseFloat(item.quantity),
                price: parseFloat(item.price)
            }))
        };

        try {
            dispatch(updateSale(update_sale.id, formData));
            handleClose();
        } catch (error) {
            console.error('Error updating sale:', error);
        }
    };

    return (
        <form ref={formRef} onSubmit={onSubmit}>
            <div className="row g-3">
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Firma</label>
                        <Select
                            value={getSelectedOption(inputs.firm_id, firmOptions)}
                            name="firm_id"
                            onChange={(data) => handleSelect(data, {name: 'firm_id'})}
                            options={firmOptions}
                            placeholder="Firmani tanlang"
                            className={formErrors.firm_id ? 'is-invalid' : ''}
                        />
                        {formErrors.firm_id && <div className="invalid-feedback">{formErrors.firm_id}</div>}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Ombor</label>
                        <Select
                            value={getSelectedOption(inputs.warehouse_id, warehouseOptions)}
                            name="warehouse_id"
                            onChange={(data) => handleSelect(data, {name: 'warehouse_id'})}
                            options={warehouseOptions}
                            placeholder="Omborni tanlang"
                            className={formErrors.warehouse_id ? 'is-invalid' : ''}
                        />
                        {formErrors.warehouse_id && <div className="invalid-feedback">{formErrors.warehouse_id}</div>}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Mijoz</label>
                        <Select
                            value={getSelectedOption(inputs.client_id, clientOptions)}
                            name="client_id"
                            onChange={(data) => handleSelect(data, {name: 'client_id'})}
                            options={clientOptions}
                            placeholder="Mijozni tanlang"
                            className={formErrors.client_id ? 'is-invalid' : ''}
                        />
                        {formErrors.client_id && <div className="invalid-feedback">{formErrors.client_id}</div>}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="date">Sana</label>
                        <input
                            type="date"
                            name="date"
                            className="form-control"
                            max={moment().format('YYYY-MM-DD')}
                            value={inputs?.date || ''}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>

            <div className="mt-4">
                <div className="mb-3">
                    <h6>Yangi mahsulot qo'shish</h6>
                    <Multiselect
                        options={productOptions}
                        selectedValues={selectedDynamicProductOptions}
                        onSelect={onSelectProduct}
                        onRemove={onRemoveProduct}
                        displayValue="name"
                        placeholder="Mahsulotlarni tanlang"
                    />
                    <Button
                        variant="secondary"
                        className="mt-2"
                        onClick={onClickMoveSelectedMaterials}
                        disabled={!selectedDynamicProductOptions.length}
                    >
                        <ArrowLeft size={14} className="me-1"/>
                        Tanlangan mahsulotlarni qo'shish
                    </Button>
                </div>

                {selectedProductOptions.length > 0 && (
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Mahsulot</th>
                                    <th>Qoldiq</th>
                                    <th>Miqdori</th>
                                    <th>Narxi</th>
                                    <th>Jami</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedProductOptions.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.name}</td>
                                        <td>{item.in_stock} {item.unit?.symbol}</td>
                                        <td>
                                            <input
                                                type="string"
                                                className="form-control"
                                                name="quantity"
                                                data-id={item.id}
                                                value={item.quantity}
                                                onChange={onChangeInput}
                                                min="1"
                                                max={item.in_stock}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                className="form-control"
                                                name="price"
                                                data-id={item.id}
                                                value={item.price}
                                                onChange={onChangeInput}
                                                min="0"
                                            />
                                        </td>
                                        <td>{(item.quantity * item.price).toLocaleString()}</td>
                                        <td>
                                            <Button
                                                variant="link"
                                                className="text-danger p-0"
                                                onClick={() => onRemoveSelectedMaterial(item.id)}
                                            >
                                                <X size={18}/>
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>

            <div className="row g-3">
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="transport_number">Transport raqami</label>
                        <input
                            type="text"
                            name="transport_number"
                            className="form-control"
                            value={inputs?.transport_number || ''}
                            onChange={handleChange}
                            placeholder="Transport raqamini kiriting"
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="driver_name">Haydovchi</label>
                        <input
                            type="text"
                            name="driver_name"
                            className="form-control"
                            value={inputs?.driver_name || ''}
                            onChange={handleChange}
                            placeholder="Haydovchi ismini kiriting"
                        />
                    </div>
                </div>
            </div>

            <div className="mb-3">
                <label htmlFor="description">Izoh</label>
                <textarea
                    name="description"
                    className="form-control"
                    value={inputs?.description || ''}
                    placeholder="Sotuv uchun izoh"
                    onChange={handleChange}
                    rows="3"
                />
                <div className="error">
                    {formErrors?.description}
                </div>
            </div>

            <div className="mt-3 d-flex gap-2">
                <Button type="submit" variant="primary">Saqlash</Button>
                <Button variant="secondary" onClick={handleClose}>Bekor qilish</Button>
            </div>
        </form>
    );
};

export default SimpleSaleUpdateForm;
