import React, {Fragment, useEffect, useState} from "react";
import {getParams} from "../../utils/hooks/getParams";
import {useDispatch} from "react-redux";
import Select from 'react-select';

const FilterBySaleType = (props) => {
    const dispatch = useDispatch();
    const {getPageItems} = props;

    const saleTypeOptions = [
        { value: 'product', label: 'Mahsulot' },
        { value: 'material', label: 'Material' }
    ];

    const [selectedSaleType, setSelectedSaleType] = useState(() => {
        const currentType = getParams().type;
        return currentType 
            ? saleTypeOptions.find(option => option.value === currentType) 
            : null;
    });

    const [isFirst, setIsFirst] = useState(true);

    useEffect(() => {
        const params = getParams();
        if (!isFirst) {
            dispatch(getPageItems(1, params))
        }
        setIsFirst(false);
    }, [selectedSaleType]);

    const handleChange = (selectedOption) => {
        setSelectedSaleType(selectedOption);

        const url = new URL(window.location);
        
        if (selectedOption) {
            url.searchParams.set('page', '1');
            url.searchParams.set('type', selectedOption.value);
        } else {
            url.searchParams.delete('type');
        }
        
        window.history.pushState({}, '', url);
    };

    const customStyles = {
        menuPortal: provided => ({...provided, zIndex: 9999}),
        menu: provided => ({...provided, zIndex: 9999})
    };

    return (
        <Fragment>
            <Select
                value={selectedSaleType}
                onChange={handleChange}
                options={saleTypeOptions}
                isClearable
                placeholder="Sotuv turi"
                styles={customStyles}
                menuPortalTarget={document.body}
            />
        </Fragment>
    )
}

export default FilterBySaleType;
