import React from 'react';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error('Error caught by boundary:', error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <tr>
                    <td colSpan="100%" className="text-center text-muted py-3">
                        <div>Something went wrong loading this row.</div>
                        <button 
                            onClick={() => this.setState({ hasError: false })}
                            className="btn btn-link"
                        >
                            Try again
                        </button>
                    </td>
                </tr>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
