import React, { useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import ProductSaleCreateForm from './product-sales/ProductSaleCreateForm';
import MaterialSaleCreateForm from './material-sales/MaterialSaleCreateForm';

const SaleCreateForm = ({ show, handleClose }) => {
    const [selectedType, setSelectedType] = useState('product');

    const handleTypeSelect = (type) => {
        setSelectedType(type);
    };

    if (!show) return null;

    return (
        <Modal show={show} onHide={handleClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>
                    {selectedType === 'product' ? 'Yangi mahsulot sotuvi' : 'Yangi material sotuvi'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mb-4 d-flex justify-content-center">
                    <Form.Check
                        inline
                        type="radio"
                        name="saleType"
                        id="productSale"
                        label="Mahsulot sotuvi"
                        checked={selectedType === 'product'}
                        onChange={() => handleTypeSelect('product')}
                    />
                    <Form.Check
                        inline
                        type="radio"
                        name="saleType"
                        id="materialSale"
                        label="Material sotuvi"
                        checked={selectedType === 'material'}
                        onChange={() => handleTypeSelect('material')}
                    />
                </div>
                {selectedType === 'product' ? (
                    <ProductSaleCreateForm onClose={handleClose} />
                ) : (
                    <MaterialSaleCreateForm onClose={handleClose} />
                )}
            </Modal.Body>
        </Modal>
    );
};

export default SaleCreateForm;
