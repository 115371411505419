import React from 'react';
import { connect } from 'react-redux';
import ForbiddenPage from '../layout/ForbiddenPage';

const PermissionGuard = ({ children, requiredPermission, auth: { user, permissions } }) => {
    
    // Allow superadmin access to all pages
    if (user?.role_name === 'superadmin' || user?.roles?.some(role => role.name === 'superadmin')) {
        return children;
    }

    // For non-superadmin users, check specific permissions
    if (!permissions.all_permissions.includes(requiredPermission)) {
        return <ForbiddenPage />;
    }

    return children;
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(PermissionGuard);
